import React, { Component } from 'react'
import API from '../../axios';
import Header from '../../Header';
import Footer from '../../Footer';
import MenuDashboard from '../MenuDashboard';
import ItemsNotifications from './ItemsNotifications';

const columns = [
    {
        "key": 1,
        "name": "PRÉSENTATION"
    },
    {
        "key": 2,
        "name": "SERVICES CONCERNÉS"
    },
    {
        "key": 3,
        "name": "DATE DÉBUT"
    },
    {
        "key": 4,
        "name": "DATE FIN"
    }
];

export default class Notification extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            correctMessageDelete: false,
            errorMessageDelete: false,
            correctMessageUpdateRole: false,
            errorMessageUpdateRole: false,
            correctMessageCreateUser:false,
            errorMessageCreateUser:false,
            correctMessageUpdateUser:false,
            errorMessageUpdateUser:false,
        }
    }

    componentDidMount(){
        API({
            method: 'get', url: '/notification/list',
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            let data = res.data.data;
            let dataNotification = [];
            data.map((register) => {

                if(register.enabled !== 0){
                    let dateDebut = new Date(register.dateDebut);
                    let dateEnd = new Date(register.dateEnd);
                    let formatYear = dateDebut.toLocaleDateString('fr-FR', { year: 'numeric' });
                    let formatMonth = dateDebut.toLocaleDateString('fr-FR', { month: '2-digit' });
                    let formatDay = dateDebut.toLocaleDateString('fr-FR', { day: '2-digit' });
                    let formatYearEnd = dateEnd.toLocaleDateString('fr-FR', { year: 'numeric' });
                    let formatMonthEnd = dateEnd.toLocaleDateString('fr-FR', { month: '2-digit' });
                    let formatDayEnd = dateEnd.toLocaleDateString('fr-FR', { day: '2-digit' });
                    let formatted_date = formatYear + "-" + formatMonth + "-" + formatDay;
                    let formatted_date2 = formatYearEnd + "-" + formatMonthEnd + "-" + formatDayEnd;
                    
                    return dataNotification.push({
                        id: register.id,
                        presentation: register.presentation,                        
                        service:register.service,
                        dateDebut: formatted_date,
                        dateFin: formatted_date2,
                        type: register.type,
                        page: register.landingPage,
                        publishing: register.publishing,
                        id_service: register.id_service,
                    })
                    
                }                   
            })
            this.setState({data: dataNotification})
        }).catch((error) => {
            console.log(error)
        });

        if(sessionStorage.getItem("messageDelete") !== null){
            if(sessionStorage.getItem("messageDelete") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageDelete:true})
                 this.setState({errorMessageDelete:false})
            }else{
                 this.setState({correctMessageDelete:false})
                 this.setState({errorMessageDelete:true})
            } 
         }
         if(sessionStorage.getItem("messageCreation") !== null){
            if(sessionStorage.getItem("messageCreation") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageCreateUser:true})
                 this.setState({errorMessageCreateUser:false})
            }else{
                 this.setState({correctMessageCreateUser:false})
                 this.setState({errorMessageCreateUser:true})
            } 
         }
         if(sessionStorage.getItem("messageUpdateUser") !== null){
            if(sessionStorage.getItem("messageUpdateUser") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageUpdateUser:true})
                 this.setState({errorMessageUpdateUser:false})
            }else{
                 this.setState({correctMessageUpdateUser:false})
                 this.setState({errorMessageUpdateUser:true})
            } 
         }
         setTimeout(function () { sessionStorage.clear() }, 5000);
    }

    render() {
        return (
            <div className="container-main-dashboard">
                <Header/>
                        <div style={this.state.correctMessageDelete?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageDelete")}</div>
                        <div style={this.state.errorMessageDelete?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageDelete")}</div>
                        <div style={this.state.correctMessageUpdateRole?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("updateRole")}</div>
                        <div style={this.state.errorMessageUpdateRole?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("updateRole")}</div>
                        <div style={this.state.correctMessageCreateUser?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageCreation")}</div>
                        <div style={this.state.errorMessageCreateUser?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageCreation")}</div>
                        <div style={this.state.correctMessageUpdateUser?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageUpdateUser")}</div>
                        <div style={this.state.errorMessageUpdateUser?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageUpdateUser")}</div>
                        <div className="container-console">
                            <div className="container-title-dashboard">
                                <h1>Console de gestion</h1>
                            </div>
                            <div className="container-table-menu-dashboard">
                                <div className="container-menu">
                                    <MenuDashboard />
                                </div>
                                <div className="line-vertical-dashboar"></div>
                                <div className="container-table container-table-service">
                                    <ItemsNotifications columns={columns} data={this.state.data}/>
                                </div>
                            </div>
                        </div>  
                <Footer/> 
            </div>
        )
    }
}