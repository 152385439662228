import React, { Component } from 'react'
import API from '../axios';


export default class ModalCreateMember extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            email: "",
            firstName: "",
            lastName: "",
            organisation: "",
            occupation: "",
            phone: "",
            member: "",
            message: "",
            messageEmail: null,  
            messageFirstName:null,
            messageLastName:null,
            messageOrganisation:null,
            messageOccupation:null,
            messagePhone:null,
            messageMember:null,
            messageComentary:null,
            disableButton:true,
            classButton:"btn-color-gray", 
            errorSaveUser:false,
            correctSaveUser:false
        }
    }
    

    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    connection = e => {
        API({
            method: 'post', url: '/user/save', data: {
                name: this.state.firstName + " " + this.state.lastName,
                organisation: this.state.organisation,
                occupation: this.state.occupation,
                email: this.state.email,
                phone: this.state.phone,
                state: 0,
                role: 3,
                newsletter: 1,
                member: this.state.member,
                message: this.state.message
            },
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            this.setState({correctSaveUser:true});
            this.setState({errorSaveUser:false});
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            Array.from(document.querySelectorAll("textarea")).forEach(
                textarea => (textarea.value = "")
            );
            this.setState({messageEmail:"", messagePassword:"",
             messagePasswordConfirmation:"",
             messageFirstName:"",
             messageLastName:"",
             messageOrganisation:"",
             messageOccupation:"",
             messagePhone:"",
             messageMember:"",
             messageComentary:""
            })
        }).catch((error) => {
            this.setState({errorSaveUser:true});
            this.setState({correctSaveUser:false});
        });
    }

    render() {
        if(!this.props.show){
            return null;
        }

        return (
            <div className="container-modal-member">
                <div className="flex-container-modal">
                    <div className="close-modal-member">
                        <img src="../../icons/close.png" className="close-icon" onClick={e => {this.onClose(e)}} alt="close" width="32"/>
                    </div>
                    <div className="container-form-member">
                        <div className="form-login">
                            <div className="container-title-services">
                                <h3>AIDEZ-NOUS À MIEUX VOUS CONNAÎTRE</h3>
                                <span className="line-horizontal-center"></span> 
                            </div>
                            <div style={this.state.correctSaveUser?{color:"green", display:"block"}:{display:"none"}}>Votre enregistrement a bien été effectué !</div>
                            <div className="inputs-member genre-input">
                                <input type="text" placeholder="M./Mme" onChange={e => {
                                    let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                    let valueOccupation = e.target.value;
                                    if(valueOccupation.match(re) !== null){
                                        this.setState({member: e.target.value, messageMember: "M./Mme correct!", disableButton:false, classButton:this.state.firstName !== "" && this.state.lastName !== "" && this.state.email !== "" && this.state.organisation !== "" && this.state.occupation !== ""  ?"btn-color-blue":"btn-color-gray"});
                                    }else{
                                        this.setState({messageMember: "M./Mme incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                    }
                                }}/>
                            </div>
                            <div className={this.state.messageMember !== "M./Mme incorrect!"?"correct":"error"}>{this.state.messageMember}</div> 
                            <div className="container-inputs-member">
                                <div className="column-inputs-member">
                                    <div className="inputs-member">
                                        <input type="text" placeholder="Nom*" onChange={e =>{
                                            let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                            let valueNom = e.target.value;
                                            if(valueNom.match(re) !== null){
                                                this.setState({lastName: e.target.value, messageLastName: "Nom correct!", disableButton:false, classButton:this.state.firstName !== "" && this.state.lastName !== "" && this.state.email !== "" && this.state.organisation !== "" && this.state.occupation !== "" ?"btn-color-blue":"btn-color-gray"});
                                            }else{
                                                this.setState({messageLastName: "Nom incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                            }
                                        }}/>
                                    </div> 
                                    <div className={this.state.messageLastName !== "Nom incorrect!"?"correct":"error"}>{this.state.messageLastName}</div> 
                                    <div className="inputs-member">
                                        <input type="text" placeholder="Organisation*" onChange={e => {
                                            let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                            let valueOrganisation = e.target.value;
                                            if(valueOrganisation.match(re) !== null){
                                                this.setState({organisation: e.target.value, messageOrganisation: "Organisation correct!", disableButton:false, classButton:this.state.firstName !== "" && this.state.lastName !== "" && this.state.email !== "" && this.state.organisation !== "" && this.state.occupation !== ""?"btn-color-blue":"btn-color-gray"});
                                            }else{
                                                this.setState({messageOrganisation: "Organisation incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                            }
                                        }}/>
                                    </div>
                                    <div className={this.state.messageOrganisation !== "Organisation incorrect!"?"correct":"error"}>{this.state.messageOrganisation}</div> 
                                    <div className="inputs-member">
                                        <input type="text" placeholder="Email*" onChange={e => {
                                            let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                                            let valueEmail = e.target.value;
                                            if(valueEmail.match(re) !== null){
                                                this.setState({email: e.target.value, messageEmail: "Email correct!", disableButton:false, classButton:this.state.firstName !== "" && this.state.lastName !== "" && this.state.email !== "" && this.state.organisation !== "" && this.state.occupation !== "" ?"btn-color-blue":"btn-color-gray"});
                                            }else{
                                                this.setState({messageEmail: "Email incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                            }
                                        }}/>
                                    </div>
                                    <div className={this.state.messageEmail !== "Email incorrect!"?"correct":"error"}>{this.state.messageEmail}</div>
                                </div>
                                <div className="column-inputs-member">
                                    <div className="inputs-member">
                                        <input type="text" placeholder="Prénom*" onChange={e => {
                                            let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                            let valuePrenom = e.target.value;
                                            if(valuePrenom.match(re) !== null){
                                                this.setState({firstName: e.target.value, messageFirstName: "Prénom correct!", disableButton:false, classButton:this.state.firstName !== "" && this.state.lastName !== "" && this.state.email !== "" && this.state.organisation !== "" && this.state.occupation !== "" ?"btn-color-blue":"btn-color-gray"});
                                            }else{
                                                this.setState({messageFirstName: "Prénom incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                            }
                                        }}/>  
                                    </div>
                                    <div className={this.state.messageFirstName !== "Prénom incorrect!"?"correct":"error"}>{this.state.messageFirstName}</div> 
                                    <div className="inputs-member">
                                        <input type="text" placeholder="Fonction*" onChange={e => {
                                            let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                            let valueOccupation = e.target.value;
                                            if(valueOccupation.match(re) !== null){
                                                this.setState({occupation: e.target.value, messageOccupation: "Fonction correcte!", disableButton:false, classButton:this.state.firstName !== "" && this.state.lastName !== "" && this.state.email !== "" && this.state.organisation !== "" && this.state.occupation !== ""  ?"btn-color-blue":"btn-color-gray"});
                                            }else{
                                                this.setState({messageOccupation: "Fonction incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                            }
                                        }}/>
                                    </div>
                                    <div className={this.state.messageOccupation !== "Fonction incorrect!"?"correct":"error"}>{this.state.messageOccupation}</div> 
                                    <div className="inputs-member">
                                        <input type="text" placeholder="Téléphone" onChange={e => {
                                            let re = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/u;
                                            let valuePhone = e.target.value;
                                            if(valuePhone.match(re) !== null){
                                                this.setState({phone: e.target.value});
                                            }
                                        }}/>
                                    </div>
                                    <div className={this.state.messagePhone !== "Téléphone incorrect!"?"correct":"error"}>{this.state.messagePhone}</div> 
                                </div>
                            </div>
                            <textarea className="input-text-area" placeholder="Commentaire (500 signes max.)" rows="4" onChange={e => {
                                this.setState({message: e.target.value});
                            }}></textarea>
                            <button className={this.state.classButton} disabled={this.state.disableButton} onClick={(e) => {this.connection(e);}} style={{marginBottom:"30px"}}>DEVENIR MEMBRE</button>
                        </div>
                    </div>    
                </div>
            </div>
        );
    }
}
