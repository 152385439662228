import React, { Component } from 'react'
import API from '../../../axios';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class EditDataService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            packs: [],
            title: '',
            data: [],
            dataInfo:"<p></p>",
            presentation: '',
            structureHtml: "",
            accessPack: true,
            easyPack: true,
            premiumPack: true,
            messageTitle: null, 
            messagePresentation:null,
            classButton:"btn-color-gray",
            route:"",
            image:""
        }
    }



    componentWillReceiveProps(nextProps) {
        if(nextProps.structureHtml !== undefined){
            let data = nextProps.packs;
            let structure = nextProps.structureHtml;
            let finalStructure = nextProps.image !== ""?structure.replace('<figure class="image"><img>', "<figure class='image'><img class='container-image-update' src="+process.env.REACT_APP_S3_HOST+nextProps.image+">"):structure;
            this.setState({
                id: nextProps.id,
                structureHtml: finalStructure,
                url: nextProps.url,
                image: nextProps.image !== null?nextProps.image:"",
                accessPack: data.includes(1),
                easyPack: data.includes(2),
                premiumPack: data.includes(3),
                disableButton:false, 
                classButton: nextProps.title !== "" && nextProps.presentation !== "" ?"btn-color-blue save":"btn-color-gray"
            });
        }
        
    }

    render() {
        let arrayUser = JSON.parse(localStorage.getItem("user"));
        let idUser = arrayUser.id;
        
        return (
            <div className="modal fade" id="editModalService" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content adjust-size-modal">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifier l'offre ou le service</h5>                            
                        </div>
                        <div className="modal-body">
                            <div className="row-add-user" style={{marginBottom:"20px"}}>
                                <div className="input-add-user container-radio-services">
                                    <span className="modal-lable">Pack </span><div><input type="checkbox" defaultChecked={!this.state.accessPack} value="1" onChange={e => {                                        
                                        this.setState({accessPack:e.target.value})
                                    }}/><span>Negociations & Accords </span></div><div><input type="checkbox"  defaultChecked={!this.state.easyPack} value="2" onChange={e => {
                                        this.setState({easyPack:e.target.value})
                                    }}/><span>Services & Prestataires</span></div><div><input type="checkbox"  defaultChecked={!this.state.premiumPack} value="3" onChange={e => {
                                        this.setState({premiumPack:e.target.value})
                                    }} /><span>Logistique & Approvisionnement</span></div></div>
                            </div>
                            <div className="row-add-user">
                                <CKEditor
                                    editor={ 
                                        ClassicEditor
                                    }
                                    data={this.state.structureHtml} 
                                    config={{
                                        ckfinder: {
                                            // Upload the images to the server using the CKFinder QuickUpload command.
                                            uploadUrl: process.env.REACT_APP_API_ENPOINT_MAIN+'/uploader'
                                        }
                                    }} 
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        if(data !== null){
                                            this.setState({structureHtml:data});
                                            this.setState({data:data, disableButton:false});
                                            let presentation = "";
                                            if(data.indexOf("<h2>") !== -1){
                                                this.setState({title: data.substring(data.indexOf("<h2>")+4, data.indexOf("</h2>"))});
                                                presentation = data.substring(data.indexOf('</h2>'), data.length);
                                            }else{
                                                if(data.indexOf("<h3>") !== -1){
                                                    this.setState({title: data.substring(data.indexOf('<h3>')+4, data.indexOf('</h3>'))});
                                                    presentation = data.substring(data.indexOf('</h3>'), data.length);
                                                }else{
                                                    if(data.indexOf("<h4>") !== -1){
                                                        this.setState({title: data.substring(data.indexOf("<h4>")+4,data.indexOf("</h4>"))});
                                                        presentation = data.substring(data.indexOf('</h4>'), data.length);  
                                                    }else{
                                                        this.setState({title: ""});
                                                        presentation = data;  
                                                    }
                                                }
                                            }
                                            let dataCorrection = presentation.replace(/&nbsp;/gi, '');
                                            let arrayP = dataCorrection.replace(/<(.|\n)*?>/gi,'');
                                            this.setState({presentation: arrayP.substring(0,150), classButton:this.state.data !== "" ?"btn-color-blue save":"btn-color-gray"});
                                        }
                                    } }
                                />
                            </div>
                            <div className="container-buttons">
                                <button type="button" className="close close-modal-add-user" data-dismiss="modal" aria-label="Close">
                                    <img src="../../icons/close.png" alt="ANNULER"/>
                                </button>
                                <button type="button" className={this.state.classButton} disabled={this.state.disableButton} onClick={e =>{
                                    let array = [];
                                    let title = this.state.title;
                                    if(parseInt(this.state.accessPack) === 1){
                                        array.push(1)
                                    }else{
                                        array.slice(array.indexOf(1),1)
                                    }
                                    if(parseInt(this.state.easyPack) === 2){
                                        array.push(2)
                                    }else{
                                        array.slice(array.indexOf(2),2)
                                    }
                                    if(parseInt(this.state.premiumPack) === 3){
                                        array.push(3)
                                    }else{
                                        array.slice(array.indexOf(3),3)
                                    }
                                    API({
                                        method: 'get', url:  "/routeImage"
                                    }).then(res => {
                                        if(res.status === 200 && res.data !== ""){
                                            API({
                                                method: 'put', url:  "/service/update", data: {
                                                    id: this.state.id,
                                                    title: title.replace(/&nbsp;/gi,''),
                                                    presentation: this.state.presentation,
                                                    image: res.data,
                                                    structureHtml: this.state.structureHtml,
                                                    packs: array.length >0?array:[]
                                                }
                                                }).then(res => {
                                                    if(res.status === 200){
                                                        sessionStorage.setItem('messageCreation',"Votre enregistrement a bien été effectué !")
                                                        setTimeout(window.location.reload(),3000)
                                                    }else{
                                                        sessionStorage.setItem('messageCreation',"opps un problème est survenu, veuillez réessayer")
                                                        setTimeout(window.location.reload(),3000)
                                                    }  
                                                    Array.from(document.querySelectorAll("input")).forEach(
                                                        input => (input.value = "")
                                                    );
                                                    Array.from(document.querySelectorAll("input")).forEach(
                                                        input => (input.checked = "")
                                                    );
                                                    this.setState({
                                                        messageTitle: "", 
                                                        messagePresentation: ""
                                                    })                              
                                                }).catch(error => {
                                                    this.setState({errorMessage:true, message:"Opps une erreur s'est produite, veuillez réessayer!"})
                                            })
                                            API({
                                                method: 'get', url:  "/clearImage"
                                            }).then(res => {
                                                console.log(res);
                                            })
                                        }else{
                                            API({
                                                method: 'put', url:  "/service/update", data: {
                                                    id: this.state.id,
                                                    title: title.replace(/&nbsp;/gi,''),
                                                    presentation: this.state.presentation,
                                                    image: this.state.route,
                                                    structureHtml: this.state.structureHtml,
                                                    packs: array.length >0?array:[]
                                                }
                                                }).then(res => {
                                                    if(res.status === 200){
                                                        sessionStorage.setItem('messageCreation',"Votre enregistrement a bien été effectué !")
                                                        setTimeout(window.location.reload(),3000)
                                                    }else{
                                                        sessionStorage.setItem('messageCreation',"opps un problème est survenu, veuillez réessayer")
                                                        setTimeout(window.location.reload(),3000)
                                                    }  
                                                    Array.from(document.querySelectorAll("input")).forEach(
                                                        input => (input.value = "")
                                                    );
                                                    Array.from(document.querySelectorAll("input")).forEach(
                                                        input => (input.checked = "")
                                                    );
                                                    this.setState({
                                                        messageTitle: "", 
                                                        messagePresentation: ""
                                                    })                              
                                                }).catch(error => {
                                                    this.setState({errorMessage:true, message:"Opps une erreur s'est produite, veuillez réessayer!"})
                                            })
                                            API({
                                                method: 'get', url:  "/clearImage"
                                            }).then(res => {
                                                console.log(res);
                                            })
                                        }
                                    })
                                }}>
                                    <img src="../../icons/save.png" alt="SAUVEGARDER" width="32px"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
