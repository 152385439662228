// Import the CSS
import React, { useState, useEffect } from 'react';
import { LocalizationProvider, DocumentLoadEvent, Viewer, ProgressBar, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { searchPlugin } from '@react-pdf-viewer/search';
import { attachmentPlugin } from '@react-pdf-viewer/attachment';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import { withRouter } from "react-router-dom";
import API from '../axios'
import Header from '../Header';
import Footer from '../Footer';


import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

import fr_FR from './french.json';


interface LocalizationExampleProps3 {
    fileUrl: string;
    fr_FR: fr_FR;
}

const renderLoader = (percentages: number) => (
    <div style={{ width: '240px' }}>
        <ProgressBar progress={Math.round(percentages)} />
    </div>
);

const onDocumentLoad = (e: DocumentLoadEvent) => {
    console.log(`Number of pages: ${e.doc.numPages}`);
};

function formatDate(date){

    let formatYear = date.toLocaleDateString('fr-FR', {year:'numeric'});
    
    let formatMonth = date.toLocaleDateString('fr-FR', {month: '2-digit'});
    
    let formatDay =  date.toLocaleDateString('fr-FR', {day: '2-digit'});
    
    return formatYear+"-"+formatMonth+"-"+formatDay;
    
}

const thumbnailPluginInstance = thumbnailPlugin();
const fullScreenPluginInstance = fullScreenPlugin();
const zoomPluginInstance = zoomPlugin();
const pageNavigationPluginInstance = pageNavigationPlugin();
const searchPluginInstance = searchPlugin();
const attachmentPluginInstance = attachmentPlugin();
const bookmarkPluginInstance = bookmarkPlugin();

const LocalizationExample3: React.FC<LocalizationExampleProps3> = (props: LocalizationExampleProps3) => {

    const [classButtonContainer, setClassButtonContainer] = useState("container-tumbnails")

    const [classButtonThumbnails, setClassButtonThumbnails] = useState("visible-option-pdf")
    const [classButtonBookMark, setClassButtonBookMark] = useState("hidden-option-pdf")
    const [classButtonAttachment, setClassButtonAttachment] = useState("hidden-option-pdf")

    const [tooltipThumbnails, setTooltipThumbnails] = useState(true);

    const [tooltipBookMark, setTooltipBookMark] = useState(true);

    const [tooltipAttachment, setTooltipAttachment] = useState(true);

    const [classButtonClose, setClassButtonClose] = useState("btn-close")

    const [imgButtonClose, setImgButtonClose] = useState("../../icons/arrow-izq.png")

    const [rotue, setRoute] = useState("");

    const [pdfs, setPdfs] = useState([]);

    const [notification, setNotification] = useState([]);

    const [classNotification, setClassNotification] = useState("notification-off");

    const [id, setId] = useState(0);

    useEffect(() => {
        const id = props.match.params.id;
        API({
            method: 'get', url: '/service/getPdfById2?id=' + id
        }).then(res => {
            setRoute(res.data.data.route);
            setId(res.data.data.id_service);
            if(res !== null){
                API({
                    method: 'get', url: '/service/getPdfById?id=' + res.data.data.id_service
                }).then(res => {
                    setPdfs(res.data.data);
                }).catch((error) => {
                    console.log(error)
                });
                API({
                    method: 'get', url: '/notification/getbyId?id=' + res.data.data.id_service
                }).then(res => {
                    var today = new Date();
                    var formatToday = formatDate(today)
                    var formatDateEnd = formatDate(new Date(res.data.data.dateEnd));
                    var formatDateDebut = formatDate(new Date(res.data.data.dateDebut));
                    if (formatToday > formatDateEnd) {
                        setNotification(res.data.data)
                        setClassNotification("notification-off")
                    } else if (formatToday < formatDateDebut) {
                        setNotification(res.data.data)
                        setClassNotification("notification-off");
                    } else {
                        if (res.data.data.publishing !== 0) {
                            setNotification(res.data.data)
                            setClassNotification("notification-on")
                        } else {
                            setNotification(res.data.data)
                            setClassNotification("notification-off")
                        }
        
                    }
                }).catch((error) => {
                    console.log(error)
                });
            }
        }).catch((error) => {
            console.log(error)
        });

        
    }, [props.match.params.id]); 
    
    const { Thumbnails } = thumbnailPluginInstance;
    const { Attachments } = attachmentPluginInstance;
    const { Bookmarks } = bookmarkPluginInstance;
    const { EnterFullScreenButton } = fullScreenPluginInstance;
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    const { CurrentPageInput, GoToNextPageButton, GoToPreviousPageButton } = pageNavigationPluginInstance;
    const { ShowSearchPopoverButton } = searchPluginInstance;

    let class_tooltip_thumbnails = tooltipThumbnails ? "hiddenTooltip" : "visibleTooltip";
    let class_tooltip_attachments = tooltipAttachment ? "hiddenTooltip" : "visibleTooltip";
    let class_tooltip_bookmarks = tooltipBookMark ? "hiddenTooltip" : "visibleTooltip";

    return (

        <LocalizationProvider localization={fr_FR}>
            {
                (_) => (
                    
                    <div>
                        <Header/>
                        <div className="container-pdfs-links">
                            {
                                pdfs.map((pdf, item) => {
                                    return(
                                        <a className="link-pdf" key={item} href={"/pdfService/"+pdf.id}>{"Voir le document "+(item+1)}</a>
                                    )
                                })
                            }
                        </div>
                        <div className="container-main-pdf" >
                        <div className="notification-container" onClick={() => {
                            API({
                                method: 'get', url: '/notification/getbyId?id=' + id
                            }).then(res => {
                                var today = new Date();
                                var formatToday = formatDate(today)
                                var formatDateEnd = formatDate(new Date(res.data.data.dateEnd));
                                var formatDateDebut = formatDate(new Date(res.data.data.dateDebut));
                                if (formatToday > formatDateEnd) {
                                    setNotification(res.data.data)
                                    setClassNotification("notification-off")
                                } else if (formatToday < formatDateDebut) {
                                    setNotification(res.data.data)
                                    setClassNotification("notification-off");
                                } else {
                                    if (res.data.data.publishing !== 0) {
                                        setNotification(res.data.data)
                                        setClassNotification("notification-on")
                                    } else {
                                        setNotification(res.data.data)
                                        setClassNotification("notification-off")
                                    }
                                }
                            }).catch((error) => {
                                console.log(error)
                            });
                        }}><div className="notification-row"></div>
                        </div>
                        <div className="container-main-notification">
                            <div className={classNotification}>
                                <div onClick={e => {
                                    setClassNotification("notification-off")
                                }} className="close-notification">X</div>
                                <div className="container-presentation-notification">{notification.presentation}</div>
                                <div className="container-button-img-notification"><img src="../../icons/service-3b-bn.png" alt="notification" /><a href={notification.landingPage}>{notification.type === "discover" ? "Découvrir" : "En savoir plus"}</a></div>
                            </div>
                        </div>
                            <div
                                className="container-toolbar"

                            >
                                <div className="title-toolbar">
                                    APERÇU DU DOCUMENT
                                            </div>
                                <div className="container-option-toolbar">
                                    <div className="pages-toolbar">
                                        <GoToPreviousPageButton />
                                        <CurrentPageInput />
                                        <GoToNextPageButton />
                                    </div>
                                    <div className="zoom-toolbar">
                                        <ZoomOutButton />
                                        <ZoomPopover />
                                        <ZoomInButton />
                                    </div>
                                    <div className="full-screen-toolbar">
                                        <ShowSearchPopoverButton /> 
                                        <EnterFullScreenButton />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="container-viewer-pdf"
                            >

                                <div
                                    className={classButtonContainer}
                                >
                                    <div className="container-buttons-pdf">
                                        <button onClick={() => {
                                            setClassButtonThumbnails("visible-option-pdf")
                                            setClassButtonBookMark("hidden-option-pdf")
                                            setClassButtonAttachment("hidden-option-pdf")
                                        }} onMouseEnter={() => setTooltipThumbnails(false)} onMouseLeave={() => setTooltipThumbnails(true )}><span className="material-icons">apps</span></button>
                                        <p className={class_tooltip_thumbnails} >
                                            Galerie 
                                                    </p>
                                        <button onClick={() => {
                                            setClassButtonThumbnails("hidden-option-pdf")
                                            setClassButtonBookMark("visible-option-pdf")
                                            setClassButtonAttachment("hidden-option-pdf")
                                        }} onMouseEnter={() => setTooltipBookMark(false)} onMouseLeave={() => setTooltipBookMark(true )}><span className="material-icons">bookmarks</span></button>
                                        <p className={class_tooltip_bookmarks} >
                                            Marqueur
                                                    </p>
                                        <button onClick={() => {
                                            setClassButtonThumbnails("hidden-option-pdf")
                                            setClassButtonBookMark("hidden-option-pdf")
                                            setClassButtonAttachment("visible-option-pdf")
                                        }} onMouseEnter={() => setTooltipAttachment(false)} onMouseLeave={() => setTooltipAttachment(true )}><span className="material-icons">attach_file</span></button>
                                        <p className={class_tooltip_attachments} >
                                            Pièce jointe
                                                    </p>
                                    </div>
                                    <div className={classButtonThumbnails}><Thumbnails/></div>
                                    <div className={classButtonBookMark}><Bookmarks/></div>
                                    <div className={classButtonAttachment}><Attachments/></div>
                                </div>
                                <div className={classButtonClose}><div className="container-img-close"><img src={imgButtonClose} alt="open close" width="48" onClick={e => {
                                    if (classButtonContainer !== "container-tumbnails" && classButtonClose !== "btn-close" && imgButtonClose !== "../../icons/arrow-izq.png") {
                                        setClassButtonContainer("container-tumbnails")
                                        setClassButtonClose("btn-close")
                                        setImgButtonClose("../../icons/arrow-izq.png")
                                    } else {
                                        setClassButtonContainer("container-tumbnails-hidden")
                                        setClassButtonClose("btn-close-change")
                                        setImgButtonClose("../../icons/arrow-der.png")
                                    }
                                }} /></div></div>
                                <div style={{ flex: 1, overflow: 'hidden' }}>
                                    <Viewer
                                        fileUrl={process.env.REACT_APP_S3_HOST + rotue}
                                        renderLoader={renderLoader}
                                        onDocumentLoad={onDocumentLoad}
                                        defaultScale={SpecialZoomLevel.PageWidth}
                                        initialPage={1}
                                        plugins={[
                                            // Register the bookmark plugin
                                            thumbnailPluginInstance,
                                            fullScreenPluginInstance,
                                            zoomPluginInstance,
                                            pageNavigationPluginInstance,
                                            searchPluginInstance,
                                            attachmentPluginInstance,
                                            bookmarkPluginInstance
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <Footer/>                 
                    </div>
                )
            }
        </LocalizationProvider>

    );
};


export default withRouter(LocalizationExample3)