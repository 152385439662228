import React, { Component } from 'react';
import API from '../../../axios';

export default class CreateDataUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            occupation: '',
            dateDebut: '',
            dateFin: '',
            time: '',
            gender: '0',
            role: '2',
            messageEmail: null,
            messageName: null,
            messageOccupation: null,
            messagePhone: null,
            messageDateDebut: null,
            messageDateFin: null,
            messageGenre: null,
            disableButton: true,
            classButton: "btn-color-gray",
        }
    }

    render() {
        return (
            <div className="modal fade" id="addUserModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content adjust-size-modal  modal-dialog-centered">
                        <div className="modal-header" style={{width: "100%"}}>
                            <h5 className="modal-title" id="exampleModalLabel">Ajouter un abonné</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row-add-user justify-content-user">
                                <div className="container-validation-sm">
                                    <div className={this.state.messageDateDebut !== "Date incorrect!" ? "correct" : "error"}>{this.state.messageDateDebut}</div>
                                </div>
                                <div className="input-add-user radio-button-user width-user">
                                    <span className="modal-lable">Civilité </span>
                                    <input type="radio" name="genre" value="0" onChange={e => {
                                        this.setState({ gender: e.target.value })
                                    }}
                                    checked={this.state.gender == "0"} />Monsieur 
                                    <input type="radio" name="genre" value="1" onChange={e => {
                                        this.setState({ gender: e.target.value })
                                    }} 
                                    checked={this.state.gender == "1"} />Madame
                                </div>
                                <div className="width-user text-right">
                                    <span className="modal-lable little-padding">Rôle d'utilisateur</span>
                                    <select name="role" value={this.state.role} onChange={e => {this.setState({role: e.target.value})}}>
                                        <option value="1" key="1">Admin</option>
                                        <option value="2" key="2">Membre</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row-add-user">
                                <div className="input-add-user">
                                    <span className="input-add-user-date">Date Début* </span><input type="date" style={{ border: "none" }} onChange={e => {
                                        let re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;
                                        let valueDate = e.target.value;
                                        if (valueDate.match(re) !== null) {
                                            if (valueDate > this.state.dateFin) {
                                                this.setState({ dateDebut: valueDate, messageDateDebut: "La date de début doit être inférieure à la date de fin!", disableButton: true, classButton: "btn-color-gray" });
                                            } else {
                                                this.setState({ dateDebut: valueDate, messageDateDebut: "Date correcte!", disableButton: false, classButton: this.state.name !== "" && this.state.email !== "" && this.state.dateDebut !== "" && this.state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray"});

                                            }
                                        } else {
                                            this.setState({ messageDateDebut: "Date incorrect!", disableButton: true, classButton: "btn-color-gray" });
                                        }
                                    }} />
                                </div>
                                <div className="container-validation-sm" style={{ width: "100%" }}>
                                    <div className={this.state.messageDateDebut !== "Date incorrect!" && this.state.messageDateDebut !== "La date de début doit être inférieure à la date de fin!" ? "correct" : "error"}>{this.state.messageDateDebut}</div>
                                </div>
                                <div className="input-add-user">
                                    <span className="input-add-user-date">Date Fin* </span><input disabled={this.state.dateDebut !== "" ? false : true} type="date" style={{ border: "none" }} onChange={e => {
                                        let re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;
                                        let valueDate = e.target.value;
                                        if (valueDate.match(re) !== null) {
                                            if (valueDate < this.state.dateDebut) {
                                                this.setState({ messageDateFin: "La date de fin doit être supérieur à la date de début!", disableButton: true, classButton: "btn-color-gray" });
                                            } else {
                                                this.setState({ dateFin: valueDate, messageDateFin: "Date correcte!", messageDateDebut: "Date correcte!", disableButton: false, classButton: this.state.name !== "" && this.state.email !== "" && this.state.dateDebut !== "" && this.state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray"});
                                            }
                                        } else {
                                            this.setState({ messageDateFin: "Date incorrect!", disableButton: true, classButton: "btn-color-gray" });
                                        }
                                    }} />
                                </div>
                                <div className="container-validation-sm" style={{ width: "100%" }}>
                                    <div className={this.state.messageDateFin !== "Date incorrect!" && this.state.messageDateFin !== "La date de fin doit être supérieur à la date de début!" ? "correct" : "error"}>{this.state.messageDateFin}</div>
                                </div>
                            </div>
                            <div className="container-validation-lg">
                                <div className={this.state.messageDateDebut !== "Date incorrect!" && this.state.messageDateDebut !== "La date de début doit être inférieure à la date de fin!" ? "correct" : "error"}>{this.state.messageDateDebut}</div>
                                <div className={this.state.messageDateFin !== "Date incorrect!" && this.state.messageDateFin !== "La date de fin doit être supérieur à la date de début!" ? "correct" : "error"}>{this.state.messageDateFin}</div>
                            </div>
                            <div className="row-add-user">
                                <div className="input-add-user nom-prenom"><span className="modal-lable">Nom Prénom* </span><input type="text" style={{ border: "none" }} onChange={e => {
                                    let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                    let valueNom = e.target.value;
                                    if (valueNom.match(re) !== null) {
                                        this.setState({ name: e.target.value, messageName: "Nom correct!", disableButton: false, classButton: this.state.name !== "" && this.state.email !== "" && this.state.dateDebut !== "" && this.state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray"});
                                    } else {
                                        this.setState({ messageName: "Nom incorrect!", disableButton: true, classButton: "btn-color-gray" });
                                    }
                                }} /></div>
                                <div className="container-validation-sm">
                                    <div className={this.state.messageName !== "Nom incorrect!" ? "correct" : "error"}>{this.state.messageName}</div>
                                </div>
                                <div className="input-add-user"><span className="modal-lable">Email* </span><input type="email" style={{ border: "none" }} onChange={e => {
                                    let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                                    let valueEmail = e.target.value;
                                    if (valueEmail.match(re) !== null) {
                                        this.setState({ email: e.target.value, messageEmail: "Email correct!", disableButton: false, classButton: this.state.name !== "" && this.state.email !== "" && this.state.dateDebut !== "" && this.state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray"});
                                    } else {
                                        this.setState({ messageEmail: "Email incorrect!", disableButton: true, classButton: "btn-color-gray" });
                                    }
                                }} /></div>
                                <div className="container-validation-sm">
                                    <div className={this.state.messageEmail !== "Email incorrect!" ? "correct" : "error"}>{this.state.messageEmail}</div>
                                </div>
                            </div>
                            <div className="container-validation-lg">
                                <div className={this.state.messageName !== "Nom incorrect!" ? "correct" : "error"}>{this.state.messageName}</div>
                                <div className={this.state.messageEmail !== "Email incorrect!" ? "correct" : "error"}>{this.state.messageEmail}</div>
                            </div>
                            <div className="row-add-user">
                                <div className="input-add-user"><span className="modal-lable">Téléphone* </span><input type="text" style={{ border: "none" }} onChange={e => {
                                    let re = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/u;
                                    let valuePhone = e.target.value;
                                    if (valuePhone.match(re) !== null) {
                                        this.setState({ phone: e.target.value, messagePhone: "Téléphone correct!", disableButton: false, classButton: this.state.name !== "" && this.state.email !== "" && this.state.dateDebut !== "" && this.state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray"});
                                    } else {
                                        this.setState({ messagePhone: "Téléphone incorrect!", disableButton: true, classButton: "btn-color-gray" });
                                    }
                                }} /></div>
                                <div className="container-validation-sm">
                                    <div className={this.state.messagePhone !== "Téléphone incorrect!" ? "correct" : "error"}>{this.state.messagePhone}</div>
                                </div>
                                <div className="input-add-user input-occupation-create-user"><span className="modal-lable">Fonction</span><input type="text" style={{ border: "none" }} onChange={e => {
                                    let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                    let valueOccupation = e.target.value;
                                    if(valueOccupation !== ""){
                                        if (valueOccupation.match(re) !== null) {
                                            this.setState({ occupation: e.target.value, messageOccupation: "Fonction correcte!", disableButton: false, classButton: this.state.name !== "" && this.state.email !== "" && this.state.dateDebut !== "" && this.state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray"});
                                        }else{
                                            this.setState({ messageOccupation: "Fonction incorrect!", disableButton: true, classButton: "btn-color-gray" });
                                        }
                                    }else {
                                        this.setState({ occupation: e.target.value, messageOccupation: "Fonction correcte!", disableButton: false, cassButton: this.state.name !== "" && this.state.email !== "" && this.state.dateDebut !== "" && this.state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray"});
                                    }
                                }} /></div>
                                <div className="container-validation-sm">
                                    <div className={this.state.messageOccupation !== "Fonction incorrect!" ? "correct" : "error"}>{this.state.messageOccupation}</div>
                                </div>
                            </div>
                            <div className="container-validation-lg">
                                <div className={this.state.messagePhone !== "Téléphone incorrect!" ? "correct" : "error"}>{this.state.messagePhone}</div>
                                <div className={this.state.messageOccupation !== "Fonction incorrect!" ? "correct" : "error"}>{this.state.messageOccupation}</div>
                            </div>
                            <div className="container-buttons">
                                <button type="button" className="close close-modal-add-user" data-dismiss="modal" aria-label="Close">
                                    <img src="../../icons/close.png" alt="ANNULER" />
                                </button>
                                <button type="button" className={this.state.classButton !== "btn-color-gray" && this.state.phone !== "" ? "btn-color-blue" : "btn-color-gray"} disabled={this.state.disableButton} onClick={e => {
                                    API({
                                        method: 'post', url: "/user/save", data: {
                                            name: this.state.name,
                                            email: this.state.email,
                                            occupation: this.state.occupation,
                                            phone: this.state.phone,
                                            state: (this.state.role == 3)? 0 : 1,
                                            role: this.state.role,
                                            newsletter: 0,
                                            gender: parseInt(this.state.gender) !== 0 ? "Madame" : "Monsieur",
                                            dateActivation: this.state.dateDebut,
                                            dateInactivation: this.state.dateFin
                                        }
                                    }).then(res => {
                                        if (res.status === 200) {
                                            sessionStorage.setItem('messageCreation', "Votre enregistrement a bien été effectué !")
                                            setTimeout(window.location.reload(), 3000)
                                        } else {
                                            sessionStorage.setItem('messageCreation', "opps un problème est survenu, veuillez réessayer")
                                            setTimeout(window.location.reload(), 3000)
                                        }
                                        Array.from(document.querySelectorAll("input")).forEach(
                                            input => (input.value = "")
                                        );
                                        this.setState({
                                            messageEmail: "",
                                            messageName: "",
                                            messageOccupation: "",
                                            messagePhone: "",
                                            messageDateDebut: "",
                                            messageDateFin: "",
                                            messageGenre: ""
                                        })
                                    }).catch(error => {
                                        this.setState({ errorMessage: true, message: "Opps une erreur s'est produite, veuillez réessayer!" })
                                    })
                                }}>
                                    <img src="../../icons/save.png" alt="SAUVEGARDER" width="32px" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
