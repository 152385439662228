import React, { Component } from 'react'
import Header from '../../Header';
import Footer from '../../Footer';
import MenuDashboard from '../MenuDashboard';
import UserList from './UserList';
import UserXlsxHandler from './UserXlsxHandler';
import { getUserList } from './userApi';

const columns = [
    {
        "key": 1,
        "name": "DATE"
    },
    {
        "key": 2,
        "name": "NOM"
    },
    {
        "key": 3,
        "name": "EMAIL"
    },
    {
        "key": 4,
        "name": "TÉLÉPHONE"
    },
    {
        "key": 5,
        "name": "POSTE ACTUEL"
    }
];

export default class User extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            correctMessageDelete: false,
            errorMessageDelete: false,
            correctMessageUpdateRole: false,
            errorMessageUpdateRole: false,
            correctMessageCreateUser:false,
            errorMessageCreateUser:false,
            correctMessageUpdateUser:false,
            errorMessageUpdateUser:false,
        }
    }

    componentDidMount(){
        getUserList().then((contacts) => {
            this.setState({data: contacts})
        })

        if(sessionStorage.getItem("messageDelete") !== null){
            if(sessionStorage.getItem("messageDelete") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageDelete:true})
                 this.setState({errorMessageDelete:false})
            }else{
                 this.setState({correctMessageDelete:false})
                 this.setState({errorMessageDelete:true})
            } 
         }
         if(sessionStorage.getItem("updateRole") !== null){
            if(sessionStorage.getItem("updateRole") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageUpdateRole:true})
                 this.setState({errorMessageUpdateRole:false})
            }else{
                 this.setState({correctMessageUpdateRole:false})
                 this.setState({errorMessageUpdateRole:true})
            } 
         }
         if(sessionStorage.getItem("messageCreation") !== null){
            if(sessionStorage.getItem("messageCreation") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageCreateUser:true})
                 this.setState({errorMessageCreateUser:false})
            }else{
                 this.setState({correctMessageCreateUser:false})
                 this.setState({errorMessageCreateUser:true})
            } 
         }
         if(sessionStorage.getItem("messageUpdateUser") !== null){
            if(sessionStorage.getItem("messageUpdateUser") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageUpdateUser:true})
                 this.setState({errorMessageUpdateUser:false})
            }else{
                 this.setState({correctMessageUpdateUser:false})
                 this.setState({errorMessageUpdateUser:true})
            } 
         }
         setTimeout(function () { sessionStorage.clear() }, 5000);
    }

    render() {
        
        return (
            <div className="container-main-dashboard">
                <Header/>
                    <div style={this.state.correctMessageDelete?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageDelete")}</div>
                    <div style={this.state.errorMessageDelete?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageDelete")}</div>
                    <div style={this.state.correctMessageUpdateRole?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("updateRole")}</div>
                    <div style={this.state.errorMessageUpdateRole?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("updateRole")}</div>
                    <div style={this.state.correctMessageCreateUser?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageCreation")}</div>
                    <div style={this.state.errorMessageCreateUser?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageCreation")}</div>
                    <div style={this.state.correctMessageUpdateUser?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageUpdateUser")}</div>
                    <div style={this.state.errorMessageUpdateUser?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageUpdateUser")}</div>
                    <div className="container-console">
                        <div className="container-title-dashboard">
                            <h1>Console de gestion</h1>
                        </div>
                        <div className="container-table-menu-dashboard">
                            <div className="container-menu">
                                <MenuDashboard />
                            </div>
                            <div className="line-vertical-dashboar"></div>
                            <div className="container-table container-table-service">
                                <UserXlsxHandler users={this.state.data}/>
                                <UserList columns={columns} data={this.state.data} />
                            </div>
                        </div>
                    </div>  
                <Footer/>     
            </div>
        )
    }
}
