import React, { Component } from 'react';
import ViewDataUser from './modals/ViewDataUser';
import CreateDataUser from './modals/CreateDataUser'
import EditDataUser from './modals/EditDataUser'
import ReactPaginate from 'react-paginate'
import { deleteUser } from './userApi';
import UserListItem from './UserListItem';
import EditUserRole from './modals/EditUserRole'

export default class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      correctMessage: false,
      errorMessage: false,
      offset: 0,
      perPage: 10,
      currentPage: 0,
      newRole: null
    }
  }

  handleCurrentId = (id) => {
    this.setState({ id: id })
  }

  handleSimulateClick = (item, value) => {
    this.setState({id: item.id, newRole: value})
    var element = document.getElementById('aTriggerModal');
    element.dispatchEvent(
      new MouseEvent(
        'click',
        { view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
      })
    )
  }

  handleUserDeletion = (userId) => {
    deleteUser(userId).then(res => {
      if (res.status === 200) {
        sessionStorage.setItem('messageDelete', "Votre enregistrement a bien été effectué !")
        setTimeout(window.location.reload(), 3000)
      } else {
        sessionStorage.setItem('messageDelete', "opps un problème est survenu, veuillez réessayer")
        setTimeout(window.location.reload(), 3000)
      }
    }).catch(error => {
      this.setState({ errorMessage: true, message: "Opps une erreur s'est produite, veuillez réessayer!" })
    })
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    });
  };

  render() {
    let data = this.props.data?.slice(this.state.offset, this.state.offset + this.state.perPage) || [];
    let currentUser = this.props.data?.find((item) => (item.id === this.state.id)) || '';

    return (
      <div className="row-table row-table-services">
        <table className="table-users">
          <thead>
            <tr>
              { //Headers
                this.props.columns.map(function (colData) {
                  return (
                    <th className="column-datatable" key={colData.key}>
                      {colData.name}
                    </th>
                  );
                })
              }
              <th className="column-datatable">RÔLE</th>
              <th className="column-datatable">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            { //Rows
              data.map((item, i) => {
                return (
                  <UserListItem
                    key={i}
                    cols={this.props.columns}
                    item={item}
                    handleCurrentId={this.handleCurrentId}
                    simulateClick={this.handleSimulateClick}
                    handleUserDeletion={this.handleUserDeletion}
                  />
                )
              })
            }
          </tbody>
        </table>
        <div className="container-pagination">
          <a href="#!" data-toggle="modal" data-target="#addUserModal"><img src="../../icons/MORE.png" alt="trash" width="32px" /></a>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(this.props.data.length / this.state.perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick.bind(this)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"} />
        </div>
        <ViewDataUser
          name={currentUser.name}
          email={currentUser.email}
          phone={currentUser.phone}
          occupation={currentUser.occupation}
          role={currentUser.role}
          gender={currentUser.gender}
          subscribed={currentUser.subscribed}
        />
        <CreateDataUser />
        <EditDataUser
          id={currentUser.id}
          name={currentUser.name}
          dateDebut={currentUser.dateActivation}
          dateFin={currentUser.dateInactivation}
          email={currentUser.email}
          phone={currentUser.phone}
          occupation={currentUser.occupation}
          gender={currentUser.gender}
          subscribed={currentUser.subscribed}
        />
        <EditUserRole
          id={this.state.id}
          role={this.state.newRole}
        />
      </div>
    );
  }
}
