import React from 'react'
import {Route, Redirect} from 'react-router-dom'

let user = JSON.parse(localStorage.getItem("user"));

export const PrivateRouteAdmin = ({component: Component, ...rest}) => (
    
    <Route 
        {...rest}
        render={props => user.role === 1? (
            <Component {...props} />    
        ):(
            <Redirect to={{
                pathname: "/",
                state: {from: props.location}
            }}
            />
            )    
        }
    />
);