import React, { Component } from 'react'

const USER_ROLES = {
    1: 'Admin',
    2: 'Membre',
    3: 'Utilisateur'
}

export default class ViewDataUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone:'',
            occupation:''
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            name: nextProps.name,
            email: nextProps.email,
            phone: nextProps.phone,
            occupation: nextProps.occupation,
            role: nextProps.role,
            gender: nextProps.gender,
            subscribed: nextProps.subscribed
        });
    }

    render() {
        return (
            <div className="modal fade" id="viewModalUser" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-dialog-centered">
                        <div className="modal-header" style={{width: "100%"}}>
                            <h5 className="modal-title" id="exampleModalLabel">{this.state.name}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className="modal-lable">Email: </span>{this.state.email}</p>
                            <p><span className="modal-lable">Téléphone: </span>{this.state.phone}</p>
                            <p><span className="modal-lable">Fonction: </span>{this.state.occupation}</p>
                            <p><span className="modal-lable">Rôle: </span>{USER_ROLES[this.state.role]}</p>
                            <p><span className="modal-lable">Gender: </span>{this.state.gender}</p>
                            <p><span className="modal-lable">Newsletter: </span>{this.state.subscribed ? 'Oui' : 'Non' }</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
