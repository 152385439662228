import React, { Component } from 'react';
import API from '../../../axios';

export default class ViewDataUpdates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            image: '',
            structureHtml: '',
            pdfs: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.structureHtml !== undefined){
            let structure = nextProps.structureHtml;
            let finalStructure = structure.replace('<figure class="image"><img>', "<figure class='image'><img src="+process.env.REACT_APP_S3_HOST+nextProps.image+" />")
            this.setState({
                structureHtml: finalStructure
            });
            API({
                method: 'get', url: '/update/getPdfById?id='+nextProps.id
            }).then(res => {
                this.setState({pdfs:res.data.data})
            }).catch((error) => {
                console.log(error)
            });
        }
    }
    render() {
        return (
            <div className="modal fade" id="viewModalUpdate" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-dialog-centered">
                        <div className="modal-header" style={{width: "100%"}}>
                            <h5 className="modal-title" id="exampleModalLabel">Prévisualiser la Updates</h5>
                        </div>
                        <div className="modal-body container-body-modal">
                            <div className="container-update-dashboard" dangerouslySetInnerHTML={{ __html: this.state.structureHtml }} />
                            <div className="view-notification">
                                <button type="button" className="close close-modal-add-user" data-dismiss="modal" aria-label="Close">
                                    <img src="../../icons/close.png" alt="ANNULER"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

