import React, { Component } from 'react'
import Header from './Header';
import Footer from './Footer';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export default class CGU extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="container-mentions-cgu-policy">
                <div className="row-mentions-cgu-policy">
                        <h3>MENTIONS LÉGALES</h3>
                        <div className="containerline-horizontal"><div className="line-horizontal"></div></div>
                        <p>Ce site Internet est édité par Kairos ID société par actions simplifiée, SAS au capital de 100.000 euros, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 832 387 773 ayant son siège social au 39, rue Esquirol 75013 Paris France.</p>
                        <p>Responsable de la publication : Rosemonde MATHIEU</p>
                        
                        <div className="container-HDS">
                            <div className="row-HDS">
                                <p>Hébergeurs des Services:</p>
                            </div>
                            <ul className="row-HDS">
                                <li className="paragraph-ovh"><span style={{fontWeight:"600", fontSize:"18px"}}>OVH SAS</span> au capital de 10 069 020 € dont le siège social est situé 2 rue Kellermann - 59100 Roubaix - France et immatriculée sous le numéro RCS Lille Métropole 424 761 419 00045</li>
                                <li>
                                    <div className="paragraph-bt">
                                        <div className="container-bt">
                                            <p><span style={{fontWeight:"600", fontSize:"18px"}}>BT Blue SAS</span>, certifié HDS, ISO 27001</p>
                                            <p>Siret: 483 400 628 00028</p>
                                            <p>APE: 6110Z</p>
                                            <p>ZI de Bellevue - Rue Blaise Pascal 35220 Châteaubourg France</p>
                                            <p>Tél : 02 52 56 00 21</p>
                                            <p>Fax : 02 99 54 06 91</p>
                                        </div>
                                        <div className="container-bt-logo">
                                            <img src="../HDS-icon-KID.png" alt="HDS icon KID" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <Accordion allowMultipleExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        1. Nous contacter
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <ul>
                                            <li>Tél. : 01 45 82 45 92 du lundi au vendredi de 9h00 à 18h00 </li>
                                            <li>E-mail : contact@kairos-id.com</li>
                                        </ul>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        2. Propriété intellectuelle
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Toutes les informations situées sur le site <a href="http://collectifpharma.fr">www.collectifpharma.fr</a> sont la propriété de la société Kairos ID SAS.
                                        </p>
                                        <p>
                                            Tous les droits de reproduction sont réservés, qu'il s'agisse de la conception du site ou de son contenu.
                                        </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        3. Droit applicable et attribution de juridiction
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Tout litige en relation avec l’utilisation du site <a href="http://collectifpharma.fr">www.collectifpharma.fr</a> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.
                                        </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>    
                    <div className="row-mentions-cgu-policy">
                        <h3>Conditions Générales d’Utilisation (CGU)</h3>
                        <div className="containerline-horizontal"><div className="line-horizontal"></div></div>
                        <p style={{ textAlign: "justify" }}>Bienvenue sur le site www.collectifpharma.fr !</p>
                        <p style={{ textAlign: "justify" }}>Merci d'avoir choisi nos services. L'utilisation de nos services implique votre acceptation des présentes conditions d'utilisation sur nos services. Nous vous invitons à les lire avec attention. Nos services sont très divers. Si vous choisissez d'utiliser ces services, vous devez lire et valider les présentes Conditions générales d'utilisation et notre Politique de Confidentialité.</p>
                        <Accordion allowMultipleExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        1. DEFINITIONS
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Au sens des conditions générales d'utilisation, les expressions ci-dessous auront la définition suivante:
                                            </p>
                                        <ul>
                                            <li><span style={{ fontWeight: "600" }}>CGU</span> désigne les présentes conditions générales d'utilisation applicables sur le site www.collectifpharma.fr. Les présentes Conditions Générales d'Utilisation ont pour objet de fixer les règles d'utilisation du site par l'Utilisateur ainsi que de régir l'affichage des services disponibles sur le site.</li>
                                            <li><span style={{ fontWeight: "600" }}>Données à Caractère Personnel</span> désigne toute information concernant une personne physique identifiée ou identifiable ; est réputée identifiable une personne qui peut être identifiée, directement ou indirectement, notamment par référence à un numéro d'identification ou à un ou plusieurs éléments spécifiques, propres à son identité physique, physiologique, psychique, économique, culturelle ou sociale.</li>
                                            <li><span style={{ fontWeight: "600" }}>Accès membre</span> désigne un compte d’accès utilisateur qui aura été préalablement enregistré par nos services pour accéder à l’ensemble de informations privées et confidentielles présentes sur notre site et à destination des utilisateurs membres autorisés uniquement.</li>
                                            <li><span style={{ fontWeight: "600" }}>Identifiant</span> désigne l'identifiant personnel associé au compte de l'Utilisateur membre ainsi que tout code confidentiel ou mot de passe délivré à l'Utilisateur par le site Internet puis modifié par l'Utilisateur et permettant à l'Utilisateur de s'identifier afin d'accéder aux services.</li>
                                            <li><span style={{ fontWeight: "600" }}>Membre</span> désigne toute personne ayant souscrit une inscription membre aux services proposés sur le site Internet www.collectifpharma.fr et disposant d’un accès personnel pour en prendre connaissance.</li>
                                            <li><span style={{ fontWeight: "600" }}>Politique de Confidentialité</span> désigne le document qui définit la manière dont le présent site recueille, utilise, conserve et transfère les données à caractère personnel et d'autres informations importantes d'un utilisateur.</li>
                                            <li><span style={{ fontWeight: "600" }}>Charte de gestion des cookies</span> désigne le document qui définit la manière dont le présent site recueille, utilise, conserve et transfère les données à caractère personnel et d'autres informations importantes d'un utilisateur recueillies à l'aide de cookies.</li>
                                            <li><span style={{ fontWeight: "600" }}>Réglementation sur les données personnelles</span> désigne ensemble le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (ci-après, « le Règlement Général sur la Protection des Données ») et la loi « Informatique et libertés » n°78-17 du 6 janvier 1978 modifiée la Loi n° 78-17 du 6 Janvier 1978 relative à l'informatique, aux fichiers et aux libertés, et modifiée par la loi n° 2004-801 du 6 août 2004.</li>
                                            <li><span style={{ fontWeight: "600" }}>Services </span> désigne l'ensemble des offres et services soit les Services Principaux et les Services complémentaires qui sont affichés ainsi que leurs tarifs correspondants sur le site Internet de manière privée et uniquement réservée aux Utilisateurs disposant d’un accès membre autorisé et valide.</li>
                                            <li><span style={{ fontWeight: "600" }}>Utilisateur </span> désigne toute personne physique majeure qui utilise les services et accède aux Services proposés par le présent site www.collectifpharma.fr.</li>
                                            <li><span style={{ fontWeight: "600" }}>Parties  </span> désigne conjointement le Site Internet et l'Utilisateur membre qui accède aux Services au moyen de son accès personnel et authentifié.</li>
                                        </ul>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        2. OBJET DES CGU
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Les présentes CGU ont pour objet de définir les conditions dans lesquelles les Utilisateurs peuvent accéder aux Services et les utiliser.
                                            </p>
                                        <p>
                                            Tout Utilisateur qui accède aux Services proposés s'engage à respecter, sans réserve, les présentes CGU. L'utilisateur s'engage à utiliser les services dans le respect des lois en vigueur, y compris les réglementations applicables concernant le contrôle des exportations et réexportations. L'Utilisateur s'engage à ne pas copier les informations figurant sur le Site et mises en ligne par le site ou un autre Utilisateur des Services, ni à en faire un usage illicite. L'utilisateur s'engage également lors de son inscription sur les Services, à fournir des informations sincères le concernant, notamment son nom d'utilisateur, et son adresse électronique. L'utilisateur s'engage par ailleurs à s'inscrire sur les Services une seule fois, à ne pas créer plus d'un Compte, sauf dans le cas où un tiers à connaissance et/ou accès à ce Compte, et uniquement après en avoir informé le site par voie électronique ou voie de formulaire de contact. Le site se donne le droit de suspendre ou cesser la fourniture des services en cas de non-respect de ces conditions et réglementations applicables ou dans le cas d'une suspicion d'une mauvaise utilisation des services.
                                            </p>
                                        <p>
                                            L'utilisation des services sur le site www.collectifpharma.fr ne confère aucun droit de propriété intellectuelle sur nos services ni sur les contenus diffusés en ligne. Un utilisateur ne peut en aucun cas utiliser un contenu obtenu par l'intermédiaire du site www.collectifpharma.fr sans l'autorisation du propriétaire dudit contenu, à moins d'y être autorisé par la loi. Ces conditions d'utilisation ne confèrent aucun droit d'utiliser une quelconque marque ou un quelconque logo présent sur le site www.collectifpharma.fr
                                            </p>
                                        <p>
                                            Les services présents sur le site www.collectifpharma.fr peuvent afficher et diffuser des contenus. Ces contenus relèvent de l'entière responsabilité de l'entité qui les a créés et rendus disponibles en ligne. À tout moment, nous pouvons être amenés à contrôler ces contenus pour nous assurer de leur conformité avec la réglementation en vigueur ou de leur adéquation avec les CGU. Nous nous réservons le droit de supprimer ou de refuser l'affichage d'un contenu qui nous semble être en violation de la loi ou de notre règlement d’utilisation. Le fait que nous nous réservions ce droit ne signifie pas que nous vérifions les contenus. Il ne peut donc être présumé que nous vérifions tous les contenus publiés et disponibles en ligne.
                                            </p>
                                        <p>
                                            Si l'Utilisateur n'est pas en accord avec tout ou partie des CGU, il lui est vivement recommandé de ne pas utiliser le Site et les Services.
                                            </p>
                                        <p>
                                            De même, les Services du site www.collectifpharma.fr ne sont pas destinés ni autorisés aux mineurs.
                                            </p>
                                        <p>
                                            Le site www.collectifpharma.fr est libre de modifier, à tout moment, les présentes CGU, afin notamment de prendre en compte toute évolution légale, réglementaire, jurisprudentielle et/ou technique.
                                            </p>
                                        <p>
                                            L'Utilisateur est expressément informé que l'unique version des CGU des Services qui fait foi est celle qui se trouve en ligne sur le site www.collectifpharma.fr en vigueur, ce qu'il reconnaît et accepte sans restriction, s'engageant à s'y référer systématiquement lors de chaque connexion et à l'inscription.
                                            </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        3. CAPACITE
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            L'Utilisation des Services est réservée aux Utilisateurs reconnus en tant que personnes physiques capables de souscrire des obligations conformément au droit français. Toute utilisation des Services au moyen de robots ou de machines est strictement interdite sauf autorisation préalable de l'administrateur des Services.
                                            </p>
                                        <p>
                                            L'Utilisateur, reconnu en tant que personne physique, peut utiliser les Services pour le compte de tiers sur lesquels il dispose de l'autorité parentale ou pour lesquels il est reconnu tuteur ou curateur conformément au droit français.
                                            </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        4. INFORMATIONS, RESPONSABILITES ET GARANTIES RELATIVES AUX MOYENS D'ACCES AUX SERVICES
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Le site www.collectifpharma.fr met en place les moyens nécessaires à la bonne marche des Services. Le site www.collectifpharma.fr prend les mesures nécessaires au maintien de la continuité et de la qualité des Services.
                                            </p>
                                        <p>
                                            L'Utilisateur reconnaît que son utilisation des Services se fait à ses risques et périls. Les Services lui sont fournis « en l'état » et sont accessibles sans aucune garantie de disponibilité et de régularité. Le site www.collectifpharma.fr.
                                            </p>
                                        <p>
                                            Le site www.collectifpharma.fr ne peut être tenu responsable des perturbations du réseau Internet dues à des cas de force majeure au sens de la jurisprudence de la Cour de Cassation et du fait des opérations de maintenance des Services planifiées par le site www.collectifpharma.fr. Le site www.collectifpharma.fr ne peut également pas être tenu responsable de l'installation et du fonctionnement des terminaux utilisés par l'Utilisateur pour accéder aux Services et non fournis par le site www.collectifpharma.fr.
                                            </p>
                                        <p>
                                            Plus généralement, le site www.collectifpharma.fr ne pourra en aucun cas être tenu pour responsable en raison d'une interruption des Services quel que soit la cause, la durée ou la fréquence de cette interruption.
                                            </p>
                                        <p>
                                            Les taux de transfert et les temps de réponse des informations circulant à partir du site www.collectifpharma.fr vers Internet ne sont pas garantis par le site.
                                            </p>
                                        <p>
                                            L'Utilisateur reconnaît que la vitesse de transmission des informations ne dépend pas des Services proposés sut le site www.collectifpharma.fr, mais des caractéristiques inhérentes aux réseaux de communications électroniques et des caractéristiques techniques de son mode de connexion (câble, ADSL, 3G, 4G etc.) et de son accès internet.
                                            </p>
                                        <p>
                                            En outre, la responsabilité du site www.collectifpharma.fr ne peut pas être recherchée pour des actes réalisés par l'Utilisateur ou un tiers utilisant les Services.
                                            </p>
                                        <p>
                                            Tout logiciel, téléchargé par l'Utilisateur, ou obtenu de toute autre manière lors de l'utilisation du Service, l'est aux risques et périls de l'Utilisateur.
                                            </p>
                                        <p>
                                            Les services proposés par le site www.collectifpharma.fr sont soumis à une obligation de moyens, dans les limites de ce qui est commercialement raisonnable. Nous espérons que l'expérience des utilisateurs en sera nettement améliorée même si les services du site www.collectifpharma.fr font l'objet d'une limitation de garantie, dans les limites permises par la loi.
                                            </p>
                                        <p>
                                            Sauf tel que précisé dans les présentes CGU, le site www.collectifpharma.fr ainsi que ses fournisseurs ou ses distributeurs ne font aucune promesse spécifique concernant les services et, par exemple, ne contractent aucun engagement concernant le contenu des services, les fonctionnalités spécifiques disponibles par le biais des services, leur fiabilité, leur disponibilité ou encore leur adéquation avec les besoins des utilisateurs. L'ensemble des services visibles sur le site www.collectifpharma.fr sont fournis en l'état.
                                            </p>
                                        <p>
                                            Si vous utilisez les services du site www.collectifpharma.fr pour le compte d'une entreprise, cette dernière doit accepter les présentes CGU. Elle doit en outre dégager de toute responsabilité le site www.collectifpharma.fr, ses sociétés affiliées, ses agents, ses mandataires et ses salariés et les garantir contre toute réclamation, poursuite ou action en justice résultant de ou liée à son utilisation des services ou faisant suite à une violation des présentes CGU, y compris toute responsabilité et charge financière résultant de réclamations, de pertes ou de dommages constatés, de poursuites engagées et de jugements prononcés, et des frais de justice et d'avocats afférents.
                                            </p>

                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        5. SITES TIERS
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Pour le cas où les Services contiendraient des liens hypertextes renvoyant vers des sites internet édités par des tiers (ci-après les « Sites Tiers ») sur lesquels www.collectifpharma.fr n'exerce aucune sorte de contrôle, www.collectifpharma.fr n'assume aucune responsabilité quant au contenu des Sites Tiers ou au contenu vers lequel les Sites Tiers peuvent renvoyer. La présence de liens hypertextes vers des Sites Tiers ne saurait signifier que www.collectifpharma.fr approuve de quelque façon que ce soit les contenus des Sites Tiers. www.collectifpharma.fr n'est responsable d'aucune modification ou mise à jour concernant les Sites Tiers. www.collectifpharma.fr n'est pas responsable de la transmission d'informations à partir des Sites Tiers, ni du mauvais fonctionnement de ceux-ci.
                                            </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        6. PROPRIETE INTELLECTUELLE
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Les Services et tous les éléments qui les composent sont, sauf mentions particulières, la propriété exclusive de www.collectifpharma.fr. Toutes les marques et tous les sites et les logos appartenant à Kairos ID ne peuvent pas être utilisés par l'Utilisateur sans le consentement préalable écrit de Kairos ID.
                                            </p>
                                        <p>
                                            En conséquence, en application des dispositions du Code de la propriété intellectuelle, des dispositions législatives et réglementaires de tous pays et des conventions internationales, toute reproduction, diffusion ou représentation, intégrale ou partielle, des Services de www.collectifpharma.fr ou d'un quelconque élément qui les compose est interdite de même que leur altération. A ce titre, il est notamment interdit à l'Utilisateur d'adapter, arranger, modifier, corriger, associer, traduire en toutes langues ou tous langages, mettre sur le marché à titre gratuit ou onéreux, commercialiser, tout ou partie des Services fournis par de www.collectifpharma.fr ou d'un quelconque élément qui les compose, quel qu'en soient le moyen et le support. Aucune stipulation des CGU ne peut être interprétée comme une cession de droits de propriété intellectuelle que ce soit tacitement ou d'une autre façon.
                                            </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        7. CONVENTION DE PREUVE
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Les messages reçus par télécopie ou par voie électronique et plus généralement les documents électroniques échangés entre le présent site www.collectifpharma.fr et l'Utilisateur sont des écrits d'origine au sens de l'article 1366 du Code civil, c'est à dire comme ayant la même valeur que celle accordée à l'original. Il convient de conserver les télécopies ou les écrits électroniques de telle manière qu'ils puissent constituer des copies fidèles et durables au sens de l'article 1379 du Code civil.
                                            </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        8. RENONCIATION
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Le fait pour l'une ou l'autre des Parties de ne pas se prévaloir d'une ou plusieurs stipulations des CGU ne pourra en aucun cas impliquer la renonciation par cette Partie à s'en prévaloir ultérieurement.
                                            </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        9. NULLITE PARTIELLE
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Dans le cas où certaines stipulations des CGU seraient inapplicables pour quelque raison que ce soit, y compris en raison d'une loi ou d'une réglementation applicable, les parties resteront liées par les autres stipulations des CGU et s'efforceront de remédier aux clauses inapplicables dans le même esprit que celui qui a présidé lors de la conclusion.
                                            </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        10. FORCE MAJEURE
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Il y a force majeure lorsqu'un événement échappant au contrôle de l'éditeur des Services, qui ne pouvait être raisonnablement prévu lors de l'acceptation des CGU et dont les effets ne peuvent être évités par des mesures appropriées, empêche l'exécution de son obligation par l'éditeur des Services.
                                            </p>
                                        <p>
                                            Le cas de force majeure suspend les obligations de la partie concernée pendant le temps où jouera la force majeure si cet événement est temporaire. Néanmoins, les Parties s'efforceront d'en minimiser dans toute la mesure du possible les conséquences.
                                            </p>
                                        <p>
                                            A défaut, si l'empêchement est définitif, les parties seront libérées de leurs obligations dans les conditions prévues aux articles 1351 et 1351-1 du Code civil.
                                            </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        11. DROIT APPLICABLE ET ATTRIBUTION DE COMPETENCE
                                        </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Les CGU sont soumises à la loi française. Toutes difficultés relatives à la validité, l'application ou à l'interprétation des CGU seront soumises, à défaut d'accord amiable, au Tribunal de Grande Instance de Paris, auquel les Parties attribuent compétence territoriale, quel que soit le lieu d'exécution ou le domicile du défendeur. Cette attribution de compétence s'applique également en cas de procédure en référé, de pluralité de défendeurs ou d'appel en garantie.
                                            </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
