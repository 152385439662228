import React, { Component } from 'react'
import Header from './Header';
import Footer from './Footer';
import API from './axios';
import { NavLink } from 'react-router-dom';

let colorBlue = {
    background: "#213564"
}

let colorWhite = {
    background: "white"
}

export default class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            urlIMagePackAccess: "../../icons/service-1b.png",
            urlIMagePackEasy: "../../icons/service-2b.png",
            urlIMagePackPremium: "../../icons/service-3.png",
            classItemPack: "item-pack",
            backgroundColorEasy: colorWhite,
            data: [],
            packs: []
        }
        this.outputEvent = this.outputEvent.bind(this);
        this.resetClassItemPackEasy = React.createRef();
        this.resetClassItemPackAccess = React.createRef();
        this.resetClassItemPackPremium = React.createRef();
    }

    outputEvent(event) {
        API.get('/service/search?keywords=%' + event.target.value + '%').then((response) => {
            this.setState({ data: response.data.data })
        });

    }

    componentDidMount() {
        if (localStorage.getItem("pack") === "easy" || localStorage.getItem("pack") == null) {
            API({
                method: 'get', url: '/service/packService?id=' + 2,
                headers: { 'Content-Type': 'application/json' }
            }).then(res => {
                this.setState({ data: res.data.data });
                localStorage.removeItem("pack");
            }).catch((error) => {
                console.log(error)
            });
            this.resetClassItemPackEasy.current.style.background = "#213564";
            this.resetClassItemPackEasy.current.style.color = "white";
            this.resetClassItemPackPremium.current.style.background = "white";
            this.resetClassItemPackPremium.current.style.color = "#213564";
            this.resetClassItemPackAccess.current.style.background = "white";
            this.resetClassItemPackAccess.current.style.color = "#213564";
            this.setState({
                urlIMagePackAccess: "../../icons/service-1b.png",
                urlIMagePackEasy: "../../icons/service-2b-bn.png",
                urlIMagePackPremium: "../../icons/service-3.png"
            })
        
        } else if (localStorage.getItem("pack") === "access") {
            API({
                method: 'get', url: '/service/packService?id=' + 1,
                headers: { 'Content-Type': 'application/json' }
            }).then(res => {
                this.setState({ data: res.data.data });
                localStorage.removeItem("pack");
            }).catch((error) => {
                console.log(error)
            });
            this.resetClassItemPackAccess.current.style.background = "#213564";
            this.resetClassItemPackAccess.current.style.color = "white";
            this.resetClassItemPackEasy.current.style.background = "white";
            this.resetClassItemPackEasy.current.style.color = "#213564";
            this.resetClassItemPackPremium.current.style.background = "white";
            this.resetClassItemPackPremium.current.style.color = "#213564";
            this.setState({
                urlIMagePackAccess: "../icons/service-1b-bn.png",
                urlIMagePackEasy: "../../icons/service-2b.png",
                urlIMagePackPremium: "../../icons/service-3.png"
            })
        } else if (localStorage.getItem("pack") === "premium") {
            API({
                method: 'get', url: '/service/packService?id=' + 3,
                headers: { 'Content-Type': 'application/json' }
            }).then(res => {
                this.setState({ data: res.data.data });
                localStorage.removeItem("pack");
            }).catch((error) => {
                console.log(error)
            });
            this.resetClassItemPackAccess.current.style.background = "white";
            this.resetClassItemPackAccess.current.style.color = "#213564";
            this.resetClassItemPackEasy.current.style.background = "white";
            this.resetClassItemPackEasy.current.style.color = "#213564";
            this.resetClassItemPackPremium.current.style.background = "#213564";
            this.resetClassItemPackPremium.current.style.color = "white";
            this.setState({
                urlIMagePackAccess: "../../icons/service-1b.png",
                urlIMagePackEasy: "../../icons/service-2b.png",
                urlIMagePackPremium: "../../icons/service-3-bn.png"
            })
        } else {
            API({
                method: 'get', url: '/service/packService?id=' + 1,
                headers: { 'Content-Type': 'application/json' }
            }).then(res => {
                this.setState({ data: res.data.data });
            }).catch((error) => {
                console.log(error)
            });
            this.resetClassItemPackAccess.current.style.background = "#213564";
            this.resetClassItemPackAccess.current.style.color = "white";
            this.resetClassItemPackEasy.current.style.background = "white";
            this.resetClassItemPackEasy.current.style.color = "#213564";
            this.resetClassItemPackPremium.current.style.background = "white";
            this.resetClassItemPackPremium.current.style.color = "#213564";
            this.setState({
                urlIMagePackAccess: "../icons/service-1b-bn.png",
                urlIMagePackEasy: "../../icons/service-2b.png",
                urlIMagePackPremium: "../../icons/service-3.png"
            })
        }
    }
        render() {
            let data = this.state.data !== null ? this.state.data : null;
            return (
                <div>
                    <Header clickHandler={this.outputEvent} />
                    <div className="container-services-list">
                        <div className="container-title-post"><h1>NOS OFFRES ET SERVICES</h1><div className="line-horizontal-post"></div></div>
                        <div className="options-services">
                            <div ref={this.resetClassItemPackEasy} onClick={e => {
                                API({
                                    method: 'get', url: '/service/packService?id=' + 2,
                                    headers: { 'Content-Type': 'application/json' }
                                }).then(res => {
                                    this.setState({ data: res.data.data });
                                }).catch((error) => {
                                    console.log(error)
                                });
                                this.resetClassItemPackEasy.current.style.background = "#213564";
                                this.resetClassItemPackEasy.current.style.color = "white";
                                this.resetClassItemPackPremium.current.style.background = "white";
                                this.resetClassItemPackPremium.current.style.color = "#213564";
                                this.resetClassItemPackAccess.current.style.background = "white";
                                this.resetClassItemPackAccess.current.style.color = "#213564";
                                this.setState({
                                    urlIMagePackEasy: "../../icons/service-2b-bn.png",
                                    urlIMagePackAccess: "../../icons/service-1b.png",
                                    urlIMagePackPremium: "../../icons/service-3.png"
                                })
                            }} >
                                <img className="img-service" src={this.state.urlIMagePackEasy} alt="SERVICES & PRESTATAIRES" width="60"/>
                                <span>SERVICES & PRESTATAIRES</span>
                            </div>
                            <div ref={this.resetClassItemPackAccess} onClick={e => {
                                API({
                                    method: 'get', url: '/service/packService?id=' + 1,
                                    headers: { 'Content-Type': 'application/json' }
                                }).then(res => {
                                    this.setState({ data: res.data.data });
                                }).catch((error) => {
                                    console.log(error)
                                });
                                this.resetClassItemPackAccess.current.style.background = "#213564";
                                this.resetClassItemPackAccess.current.style.color = "white";
                                this.resetClassItemPackEasy.current.style.background = "white";
                                this.resetClassItemPackEasy.current.style.color = "#213564";
                                this.resetClassItemPackPremium.current.style.background = "white";
                                this.resetClassItemPackPremium.current.style.color = "#213564";
                                this.setState({
                                    urlIMagePackAccess: "../icons/service-1b-bn.png",
                                    urlIMagePackEasy: "../../icons/service-2b.png",
                                    urlIMagePackPremium: "../../icons/service-3.png"
                                })
                            }}>
                                <img className="img-service" src={this.state.urlIMagePackAccess} alt="NEGOCIATIONS & ACCORDS" width="60"/>
                                <span>NEGOCIATIONS & ACCORDS</span>
                            </div>
                            <div ref={this.resetClassItemPackPremium} onClick={e => {
                                API({
                                    method: 'get', url: '/service/packService?id=' + 3,
                                    headers: { 'Content-Type': 'application/json' }
                                }).then(res => {
                                    this.setState({ data: res.data.data });
                                }).catch((error) => {
                                    console.log(error)
                                });
                                this.resetClassItemPackAccess.current.style.background = "white";
                                this.resetClassItemPackAccess.current.style.color = "#213564";
                                this.resetClassItemPackEasy.current.style.background = "white";
                                this.resetClassItemPackEasy.current.style.color = "#213564";
                                this.resetClassItemPackPremium.current.style.background = "#213564";
                                this.resetClassItemPackPremium.current.style.color = "white";
                                this.setState({
                                    urlIMagePackAccess: "../../icons/service-1b.png",
                                    urlIMagePackEasy: "../../icons/service-2b.png",
                                    urlIMagePackPremium: "../../icons/service-3-bn.png"
                                })
                            }}>
                                <img className="img-service" src={this.state.urlIMagePackPremium} alt="LOGISTIQUE & APPROVISIONNEMENT"  width="60"/>
                                <span>LOGISTIQUE & APPROVISIONNEMENT</span>
                            </div>
                        </div>
                        <div className="content-list-services">
                            {
                                data.map((e, i) => {
                                    let structure = e.structureHtml;
                                    let replaceH2Structure = "";
                                    let h3Structure = ""
                                    let title = "";
                                    let description = ""
                                    if (structure.indexOf("<h2>") !== -1) {
                                        replaceH2Structure = structure.substring(structure.indexOf("<h2>"), structure.indexOf("</h2>"));
                                        title = replaceH2Structure;
                                        description = structure.substring(structure.indexOf("</h2>") + 5, structure.length);
                                        if (description.indexOf("<h3>") !== -1) {
                                            if (description.indexOf("<h4>") !== -1) {
                                                description = structure.substring(structure.indexOf("<h3>"), structure.indexOf("</h4>") + 5);
                                            } else {
                                                description = structure.substring(structure.indexOf("<h3>"), structure.indexOf("</p>") + 4);
                                            }
                                        } else {
                                            if (description.indexOf("<h4>") !== -1) {
                                                description = structure.substring(structure.indexOf("<h4>"), structure.indexOf("</p>") + 4);
                                            } else {
                                                description = structure.substring(structure.search("<p>"), structure.search("</p>") + 4)
                                            }
                                        }
                                    } else {
                                        if (structure.indexOf("<h3>") !== -1) {
                                            h3Structure = structure.substring(structure.indexOf('<h3>'), structure.indexOf('</h3>'))
                                            title = h3Structure;
                                            description = structure.substring(structure.indexOf("</h3>") + 5, structure.length);
                                            if (description.indexOf("<h4>") !== -1) {
                                                description = structure.substring(structure.indexOf("<h4>"), structure.indexOf("</p>") + 4);
                                            } else {
                                                description = structure.substring(structure.search("<p>"), structure.search("</p>") + 4)
                                            }
                                        } else {
                                            if (description.indexOf("<h4>") !== -1) {
                                                description = structure.substring(structure.indexOf("<h4>"), structure.indexOf("</p>") + 4);
                                            } else {
                                                description = structure.substring(structure.search("<p>"), structure.search("</p>") + 4)
                                            }
                                        }
                                    }
                                    /*let date = new Date(e.date);
                                    let formatYear = date.toLocaleDateString('fr-FR', {year:'numeric'});
                                    let formatMonth = date.toLocaleDateString('fr-FR', {month: '2-digit'});
                                    let formatDay =  date.toLocaleDateString('fr-FR', {day: '2-digit'});
                                    let datePublication = formatDay+"/"+formatMonth+"/"+formatYear;*/
                                    if (e.post !== 0) {
                                        return (
                                            <div className="container-service" key={i}>
                                                <div className="img-service"><img src={process.env.REACT_APP_S3_HOST + e.image} alt="service" /></div>
                                                <div className="content-service">
                                                    <div className="container-title-likes-view">
                                                        <div className="title-service" dangerouslySetInnerHTML={{ __html: title }} />
                                                        <div className="container-view-likes">
                                                            <div className="container-view">
                                                                <img src="../icons/eye-3.png" alt="view" width="36" />
                                                                <span>{e.view}</span>
                                                            </div>
                                                            <div className="container-likes">
                                                                <img src="../icons/like.png" alt="like" onClick={l => {
                                                                    API({
                                                                        method: 'put', url: '/service/likes', data: {
                                                                            id: e.id
                                                                        }
                                                                    }).then(res => {
                                                                        if (res.status === 200) {
                                                                            sessionStorage.setItem('updateRole', "Votre enregistrement a bien été effectué !")
                                                                            setTimeout(window.location.reload(), 3000)
                                                                        } else {
                                                                            sessionStorage.setItem('updateRole', "opps un problème est survenu, veuillez réessayer!")
                                                                            setTimeout(window.location.reload(), 3000)
                                                                        }
                                                                    }).catch((error) => {
                                                                        console.log(error)
                                                                    });
                                                                }} />
                                                                <span>{e.likes}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="presentation-service">
                                                        <div dangerouslySetInnerHTML={{ __html: description }} />
                                                    </div>
                                                    <div className="button-more-service">
                                                        <button className="btn-color-blue btn-newsletter" onClick={l => {
                                                            API({
                                                                method: 'put', url: '/service/view', data: {
                                                                    id: e.id
                                                                }
                                                            }).then(res => {
                                                                if (res.status === 200) {
                                                                    sessionStorage.setItem('updateRole', "Votre enregistrement a bien été effectué !")
                                                                } else {
                                                                    sessionStorage.setItem('updateRole', "opps un problème est survenu, veuillez réessayer!")
                                                                    setTimeout(window.location.reload(), 3000)
                                                                }
                                                            }).catch((error) => {
                                                                console.log(error)
                                                            });
                                                        }}><NavLink to={"/service/" + e.id} style={{ textDecoration: "none", color: "white" }}>EN SAVOIR PLUS</NavLink></button>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            )
        }
    }