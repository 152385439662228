import React from 'react';

const UserListItem = ({ cols, item, i: key, handleCurrentId, simulateClick, handleUserDeletion }) => {
  
  return (
    <tr key={key} className="container-row-table">
      {cols.map(function (colData) {
        return (
          <td key={colData.key}>
            {Object.values(item)[colData.key]}
          </td>);
      })}
      <td>
        <a href="#!" id='aTriggerModal' data-toggle="modal" data-target="#editUserRole" style={{ display: 'none' }}>changerole</a>
        <select onChange={e => { simulateClick(item, parseInt(e.target.value)) }} value={item.role}>
          {(() => {
            if (item.role === 1 || item.role === 2) {
              return (<React.Fragment>
                <option value="1" key="1">Admin</option>
                <option value="2" key="2">Membre</option>
              </React.Fragment>)
            } else {
              return (<React.Fragment>
                <option value="3" key="3">Utilisateur</option>
                <option value="2" key="2">Membre</option>
              </React.Fragment>)
            }
          })()}

        </select>
      </td>
      <td>
        <a href="#!" className="btn-view-database" data-toggle="modal" data-target="#viewModalUser" onClick={() => handleCurrentId(item.id)}><img src="../../icons/eye-3.png" alt="eye" width="40px" /></a>
        <a href="#!" className="btn-edit-database" data-toggle="modal" data-target="#editModalUser" onClick={() => handleCurrentId(item.id)}><img src="../../icons/edit.png" alt="edit" width="24px" /></a>
        <a href="#!" className="btn-edit-database" onClick={() => { handleUserDeletion(item.id) }}><img src="../../icons/trash.png" alt="trash" width="24px" /></a>
      </td>
    </tr>
  );
}

export default UserListItem;
