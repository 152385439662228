import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import Home from './Home';
import Services from './Services';
import Contact from './Contact';
import ViewPdf from './pdf/ViewPdf';
import ViewPdfService from './pdf/ViewPdfService';
import Dashboard from './dashboard/service/Service'
import ListNewsletter from './dashboard/updates/Updates';
import ListUsers from './dashboard/user/User';
import ListNotifications from './dashboard/notifications/Notifications';
import Policy from './Policy';
import CGU from './CGU';
import Post from './Post';
import './App.css';
import Mentions from './Mentions';
import PostService from './PostService';
import ViewPdfAdmin from './pdf/ViewPdfAdmin';
import ViewPdfServiceAdmin from './pdf/ViewPdfServiceAdmin';
import ViewPdfServiceAdminDashBoard from './dashboard/pdfs/ViewPdfServiceAdminDashBoard';
import ViewPdfUpdateAdminDashBoard from './dashboard/pdfs/ViewPdfUpdateAdminDashBoard';
import { Worker } from '@react-pdf-viewer/core';
import {PrivateRouteAdmin} from './helpers/PrivateRouteAdmin'
import {PrivateRouteMember} from './helpers/PrivateRouteMember'


function App() {
  return (
    <div>
      <Router>
          <div className="App">
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <PrivateRouteMember exact path="/services" component={Services} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/policy" component={Policy} />
                <Route exact path="/cgu" component={CGU} />
                <Route exact path="/mentions" component={Mentions} />
                <Route exact path="/update/:id" component={Post}/>
                <PrivateRouteMember exact path="/service/:id" component={PostService}/>
                <PrivateRouteAdmin exact path="/dashboard" component={Dashboard} />
                <PrivateRouteAdmin exact path="/dashboard/newsletter" component={ListNewsletter} />
                <PrivateRouteAdmin exact path="/dashboard/users" component={ListUsers} />
                <PrivateRouteAdmin exact path="/dashboard/notifications" component={ListNotifications} />
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                  <PrivateRouteMember exact path="/pdf/:id" component={ViewPdf} />
                  <PrivateRouteAdmin exact path="/pdfAdmin/:id" component={ViewPdfAdmin} />
                  <PrivateRouteMember exact path="/pdfService/:id" component={ViewPdfService} />
                  <PrivateRouteAdmin exact path="/pdfServiceAdmin/:id" component={ViewPdfServiceAdmin} />
                  <PrivateRouteAdmin exact path="/pdfServiceAdminDashboard/:id" component={ViewPdfServiceAdminDashBoard} />
                  <PrivateRouteAdmin exact path="/pdfUpdateAdminDashboard/:id" component={ViewPdfUpdateAdminDashBoard} />
                </Worker>
            </Switch>
          </div>
      </Router>
      <CookieConsent
          enableDeclineButton
          flipButtons
          location="bottom"
          buttonText="OK, tout accepter"
          declineButtonText='Tout refuser'
          cookieName="user-preferences"
          style={{ background: "#2B373B" }}
          buttonStyle={{ background: '#27A746', color: 'white', fontSize: "14px" }}
          declineButtonStyle={{ background: '#DC3545', color: "white", fontSize: "14px" }}
          expires={160}
        >
          <span style={{ fontSize: "12px" }}>
            Nous utilisons des cookies pour améliorer la performance de notre site, vous permettre de partager des
            contenus ou mieux comprendre comment
            vous interagissez avec notre site. En acceptant, vous consentez à notre utilisation de ces cookies.{" "}
            <a href="/policy">Politique de confidentialité</a>
          </span>
        </CookieConsent>
    </div>
  );
}

export default App;
