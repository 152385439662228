import React, { Component } from 'react'
import Header from './Header';
import Footer from './Footer';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export default class Mentions extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="container-mentions-cgu-policy">
                    <div className="row-mentions-cgu-policy">
                        <h3>MENTIONS LÉGALES</h3>
                        <div className="containerline-horizontal"><div className="line-horizontal"></div></div>
                        <p>Ce site Internet est édité par Kairos ID société par actions simplifiée, SAS au capital de 100.000 euros, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 832 387 773 ayant son siège social au 39, rue Esquirol 75013 Paris France.</p>
                        <p>responsable de la publication : Rosemonde Mathieu</p>
                        <Accordion allowMultipleExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        1. Nous contacter
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <ul>
                                            <li>Tél. : 01 45 82 45 92 du lundi au vendredi de 9h00 à 18h00 </li>
                                            <li>E-mail : contact@kairos-id.com</li>
                                        </ul>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        2. Propriété intellectuelle 
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Toutes les informations situées sur le site <a href="http://kmrc.info">www.collectifpharma.fr</a> sont la propriété de la société Kairos ID SAS.
                                        </p> 
                                        <p>
                                            Tous les droits de reproduction sont réservés, qu'il s'agisse de la conception du site ou de son contenu.
                                        </p>                                        
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        3. Droit applicable et attribution de juridiction
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Tout litige en relation avec l’utilisation du site <a href="http://kmrc.info">www.collectifpharma.fr</a> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.
                                        </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>                            
                        </Accordion>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}
