import React, { useState } from "react";
import { read, utils, writeFile } from 'xlsx';
import { updateUserCollection } from "./userApi";
import './user.css'
import { MdFileDownload, MdFileUpload } from 'react-icons/md';
import { AiFillFileExcel } from 'react-icons/ai';
import { Spinner } from "@react-pdf-viewer/core";

const FILE_HEADERS_S1 = ['Id', 'Nom', 'Email', 'Telephone', 'Poste', 'Genre', 'Newsletters', 'Action']
const FILE_HEADERS_S2 = ['Key', 'Meaning']
const DATA_S2 = [
    { key: 'I', meaning: 'Insert' },
    { key: 'U', meaning: 'Update' },
    { key: 'R', meaning: 'Remove' },
    { key: 'Y', meaning: 'Yes' },
    { key: 'N', meaning: 'No' },
]

const UserXlsxHandler = ({ users }) => {
    const [fileProps, setFileProps] = useState({
        name: 'Importer le fichier',
        uploaded: false,
        rows: []
    })
    const [error, setError] = useState({
        column: false,
        duplicate: false
    })
    const [loading, setLoading] = useState(false);
    

    const validateFile = (rows) => {
        const fileKeys = Object.keys(rows[0])
        let validKeys = fileKeys.every(e => FILE_HEADERS_S1.includes(e)) && FILE_HEADERS_S1.every(e => fileKeys.includes(e))
        if (!validKeys) {
            setError({column: true, duplicate: false})
            return false
        }

        let validRows = true

        for (const [i, user] of rows.entries()) {
            let count = rows.slice(i+1, rows.length).reduce((acc, curr) => {
                if (curr.Email === user.Email) return acc + 1
                return acc
            }, 0)

            if(count > 0) {
                validRows = false
                break
            }
        }

        if (!validRows) {
            setError({duplicate: true, column: false})
            return false
        }

        return true
    }

    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const workBook = read(event.target.result);
                const sheets = workBook.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(workBook.Sheets[sheets[0]], {defval: ''});
                    if (validateFile(rows)) {
                        setError({column: false, duplicate: false})
                        setFileProps({ name: file.name, uploaded: true, rows: rows })
                    } else {
                        setFileProps({ name: file.name, uploaded: false, rows: [] })
                    }
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        updateUserCollection(fileProps.rows).then((res) => {
            window.location.reload()
        })
    }

    const handleExport = () => {
        const workSheet1 = utils.json_to_sheet([]);
        const workSheet2 = utils.json_to_sheet([]);

        const workBook = utils.book_new();
        const dataS1 = users.map((u) => {
            return {
                id: u.id,
                name: u.name || '',
                email: u.email || '',
                phone: u.phone || '',
                occupation: u.occupation || '',
                gender: u.gender || '',
                subscribed: u.subscribed ? 'Y' : 'N',
                action: ''
            }
        })

        utils.sheet_add_aoa(workSheet1, [FILE_HEADERS_S1]);
        utils.sheet_add_aoa(workSheet2, [FILE_HEADERS_S2]);
        utils.sheet_add_json(workSheet1, dataS1, { origin: 'A2', skipHeader: true });
        utils.sheet_add_json(workSheet2, DATA_S2, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(workBook, workSheet1, 'Feuil1');
        utils.book_append_sheet(workBook, workSheet2, 'Feuil2');

        writeFile(workBook, 'collectifpharma-member-load.xlsx');
    }

    return (
        <div className="row full-width">
            {loading &&
                <div className="col-md-12">
                    <Spinner animation="border" />
                </div>
            }
            {!loading &&
                <>
                    <div className="col-md-8">
                        <div className="input-group">
                            <div className="custom-file">
                                <label htmlFor="inputGroupFile" className="file-label">
                                    <AiFillFileExcel className="icon-default" /> {fileProps.name}
                                </label>
                                <input type="file" name="file" className="form-control" id="inputGroupFile" required onChange={handleImport}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                {fileProps.uploaded && !error.column && !error.duplicate &&
                                    <button className="file-button" onClick={handleSubmit}>
                                        <MdFileUpload className="icon-default" /> Soumettre
                                    </button>
                                }
                                {error.column &&
                                    <p className="file-error">Erreur : Les champs ne correspondent pas</p>
                                }
                                {error.duplicate &&
                                    <p className="file-error">Erreur : E-mail en double</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <button onClick={handleExport} className="file-button float-right">
                            <MdFileDownload className="icon-default" /> Exporter le fichier
                        </button>
                    </div>
                </>
            }
        </div>
    );
}

export default UserXlsxHandler;
