import React, { Component } from 'react'
import ViewDataNotification from './modals/ViewDataNotification';
import CreateDataNotification from './modals/CreateDataNotification';
import EditDataNotification from './modals/EditDataNotification';
import API from '../../axios';
import ReactPaginate from 'react-paginate'

export default class ItemsNotifications extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            id:null,
            correctMessage: false,
            errorMessage: false,
            offset: 0,
            perPage: 10,
            currentPage: 0  
        }
        this.replaceModalItem = this.replaceModalItem.bind(this);
        this.generateHeaders = this.generateHeaders.bind(this);
        this.generateRows = this.generateRows.bind(this);
    }

    generateHeaders() {
        let cols = this.props.columns;  
        return cols.map(function (colData) {
            return (
              <th className="column-datatable" key={colData.key}>
                {colData.name}
              </th>
            );
          });
        }

        generateRows(){
            let dataTemp = this.props.data.slice(this.state.offset, this.state.offset + this.state.perPage);
            let cols = this.props.columns;  // [{key, label}]
            return dataTemp.map((item, i) => {
                var cells = cols.map(function (colData) {
                  return (
                    <td key={colData.key}>
                      {Object.values(item)[colData.key]}
                    </td>);
                  });
                  return (
                    <tr key={i} className="container-row-table">
                      {cells}
                      <td>
                          <select onChange={e => {
                            API({
                                method: 'put', url:  "/notification/updateType", data: {
                                id:item.id,
                                type: e.target.value
                            }
                            }).then(res => {
                                if(res.status === 200){
                                    sessionStorage.setItem('updateRole',"Votre enregistrement a bien été effectué !")
                                    setTimeout(window.location.reload(),3000)
                                }else{
                                    sessionStorage.setItem('updateRole',"opps un problème est survenu, veuillez réessayer!")
                                    setTimeout(window.location.reload(),3000)
                                }                                
                            }).catch(error => {
                                this.setState({errorMessage:true, message:"opps un problème est survenu, veuillez réessayer!"})
                            })  
                          }} defaultValue={item.type}>
                              <option value="discover">Découvrir</option>
                              <option value="learnMore">En savoir plus</option>
                          </select>
                      </td>
                      <td>
                        <a href="#!" className="btn-view-database" data-toggle="modal" data-target="#viewModalNotification" onClick={() => this.setState({id:item.id})}><img src="../../icons/eye-3.png" alt="eye" width="40px"/></a>
                        <a href="#!" className="btn-edit-database" data-toggle="modal" data-target="#editModalNotification" onClick={() => this.setState({id:item.id})}><img src="../../icons/edit.png" alt="edit" width="24px"/></a>
                        <a href="#!" className="btn-edit-database" onClick={() => {
                          API({
                            method: 'delete', url:"/notification/delete", data: {
                                id:item.id
                            }
                            }).then(res => {
                                if(res.status === 200){
                                    sessionStorage.setItem('messageDelete',"Votre enregistrement a bien été effectué !")
                                    setTimeout(window.location.reload(),3000)
                                }else{
                                    sessionStorage.setItem('messageDelete',"opps un problème est survenu, veuillez réessayer")
                                    setTimeout(window.location.reload(),3000)
                                }                                
                            }).catch(error => {
                                this.setState({errorMessage:true, message:"Opps une erreur s'est produite, veuillez réessayer!"})
                        })
                        }}><img src="../../icons/trash.png" alt="trash" width="24px"/></a>
                      </td>
                      <td>
                      <label className="switch">  
                            <input type="checkbox" defaultChecked={item.publishing !== 0?"checked":""} onChange={e =>{
                            API({
                                    method: 'put', url:  "/notification/updateState", data: {
                                    id:item.id,
                                    publishing: e.target.checked
                                }
                                }).then(res => {
                                    if(res.status === 200){
                                        sessionStorage.setItem('updateRole',"Votre enregistrement a bien été effectué !")
                                        setTimeout(window.location.reload(),3000)
                                    }else{
                                        sessionStorage.setItem('updateRole',"opps un problème est survenu, veuillez réessayer!")
                                        setTimeout(window.location.reload(),3000)
                                    }                                
                                }).catch(error => {
                                    this.setState({errorMessage:true, message:"opps un problème est survenu, veuillez réessayer!"})
                                })
                            }} defaultValue={item.post} />
                            <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                  )
                
        });
    }
    
    replaceModalItem() {
        let data = this.props.data;
        let itemTemp = "";
        
        data.map( item => {
            if(item.id === this.state.id){
                itemTemp = item;
            }
        })  
        return itemTemp;
      }
    
      handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
    
        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
      };
    
    render() {
        let data = this.generateRows();
        let item = this.replaceModalItem();
        let header = this.generateHeaders();
        return (
            <div className="row-table row-table-services">
                <table className="table-users table-notification">
                    <thead>
                        <tr>
                        {header}
                        <th className="column-datatable">TYPE</th>                    
                        <th className="column-datatable">ACTIONS</th>
                        <th className="column-datatable">PUBLIÉ</th>
                        </tr>
                    </thead>     
                    <tbody>
                        {data}
                    </tbody>
                </table>
                <div className="container-pagination">
                    <a href="#!" data-toggle="modal" data-target="#addModalNotification"><img src="../../icons/MORE.png" alt="trash" width="32px"/></a>
                    <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(this.props.data.length / this.state.perPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick.bind(this)}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                </div>
                <ViewDataNotification
                    presentation={item.presentation}
                    type={item.type}
                />
                <CreateDataNotification />
                <EditDataNotification
                    id={item.id}
                    type={item.page}
                    dateDebut={item.dateDebut}
                    dateFin={item.dateFin}
                    presentation={item.presentation}
                    id_service={item.id_service}
                    service={item.service}
                />         
  
            </div>
        )
    }
}