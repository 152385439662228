import React from 'react'
import {Route, Redirect} from 'react-router-dom'

if(localStorage.getItem("user") !== null){
    localStorage.removeItem("role")
    
}else{
    localStorage.setItem("role", JSON.stringify({role:3}))
}

let user = JSON.parse(localStorage.getItem("user")) !== null? JSON.parse(localStorage.getItem("user")): JSON.parse(localStorage.getItem("role"));

export const PrivateRouteMember = ({component: Component, ...rest}) => (
    
    <Route 
        {...rest}
        render={props => user.role !== 3? (
            <Component {...props} />    
        ):(
            <Redirect to={{
                pathname: "/",
                state: {from: props.location}
            }}
            />
            )    
        }
    />
);