import React, { Component } from 'react';

export default class Footer extends Component {
    render() {
        return (
            <div className="container-footer">
                <div className="container-main-footer">
                    <div className="col-footer">
                        <div className="container-logo-kairos">
                            <div className="container-kairos">
                                <span>©Powered by </span>
                                <a href="https://www.kairos-id.com" target="_blank"  rel="noopener noreferrer"><img src="../../icons/logo-kairos.png" alt="kairos" width="170" /> </a>                                    
                            </div>
                        </div>                        
                    </div>
                    <div className="col-footer" style={{width:"20%"}}>
                        <div className="line-vertical"></div>
                    </div>
                    <div className="col-footer">
                        <div className="container-meet-us">
                            <div className="title-footer">
                                <span>NOUS RENCONTRER</span>
                                <span className="line-horizontal" ></span>
                            </div>
                            <div className="container-info-us info-meet-us">
                                <div className="info-us">
                                    <div className="row-info-us">
                                        <img src="../../icons/mail.png" alt="mail" width="40"/>
                                        <a href="mailto:contact@collectifpharma.fr" style={{color:"white"}}><span className="padding-left">contact@collectifpharma.fr</span></a>
                                    </div>
                                    <div className="row-info-us">
                                        <img src="../../icons/pointer.png" alt="location" width="40"/>
                                        <div className="container-address">
                                            <span >39 rue Esquirol 75013 Paris France</span>
                                        </div>    
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-footer" style={{width:"20%"}}>
                        <div className="line-vertical"></div>
                    </div>
                    <div className="col-footer">
                        <div className="container-meet-us">
                            <div className="title-footer">                            
                                <span>INFORMATIONS</span>
                                <span className="line-horizontal"></span>
                            </div>
                            <div className="container-info-us" style={{marginTop:"12px"}}>
                                <div className="info-us">
                                    <div className="row-info-us">
                                        <ul className="list-legales">
                                            <li><a href="/cgu">CGU & Mentions légales</a></li>
                                            <li><a href="/policy">Politique de confidentialité</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}
