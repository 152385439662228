import React from 'react'
import { updateUserRole } from '../userApi';


const EditUserRole = (props) => {

    const handleUserRoleEdition = () => {
        updateUserRole(props.id, props.role).then(res => {
          if (res.status === 200) {
            sessionStorage.setItem('updateRole', "Votre enregistrement a bien été effectué !")
            setTimeout(window.location.reload(), 2000)
          } else {
            sessionStorage.setItem('updateRole', "opps un problème est survenu, veuillez réessayer!")
            setTimeout(window.location.reload(), 2000)
          }
        }).catch(error => {
          this.setState({ errorMessage: true, message: "opps un problème est survenu, veuillez réessayer!" })
        })
    }

    return (
        <div className="modal fade" id="editUserRole" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Voulez-vous vraiment changer le rôle de l'utilisateur ?</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className='row lateral-medium-padding justify-content-end'>
                            <button className="btn btn-primary" onClick={handleUserRoleEdition}>Confirmer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditUserRole;