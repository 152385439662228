import React, { Component } from 'react'
import API from '../../../axios';

export default class EditDataNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            services: [],
            pages: [],
            service:"",
            type:"",
            dateDebut:"",
            dateFin:"",
            presentation: '',
            messageService: "",
            messageType:"",
            messageDateDebut:"",
            messageDateFin:"",
            messagePresentation:null,
            classButton:"btn-color-gray", 
        }
        this.getSelectServices = this.getSelectServices.bind(this);
    }

    getSelectServices(){
        let arrayUpdatesAndServices = []
        API({
            method: 'get', url:  "/service/list"
        }).then(res => {
            let data = res.data.data;
            this.setState({services:data});
            for(let d in data){
                arrayUpdatesAndServices.push({page:"/service/"+data[d].id, title:data[d].title}) 
            }                 
        }).catch(error => {
            this.setState({errorMessage:true, message:"opps un problème est survenu, veuillez réessayer!"})
        })
        API({
            method: 'get', url:  "/update/list"
        }).then(res => {
            let data = res.data.data;
            for(let d in data){
                arrayUpdatesAndServices.push({page:"/update/"+data[d].id, title:data[d].title}) 
            }                
        }).catch(error => {
            this.setState({errorMessage:true, message:"opps un problème est survenu, veuillez réessayer!"})
        })
        this.setState({pages:arrayUpdatesAndServices})
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.id !== undefined){
            this.setState({
                id: nextProps.id,
                type: nextProps.type,
                dateDebut: nextProps.dateDebut,
                dateFin: nextProps.dateFin,
                presentation: nextProps.presentation,
                service: nextProps.id_service,
            });
        } 
    }

    componentDidMount(){
        this.getSelectServices(); 
    }

    render() {
        return (
            <div className="modal fade" id="editModalNotification" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content adjust-size-modal">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Notifications</h5>                            
                        </div>
                        <div className="modal-body">
                            <div className="row-add-user">
                                <div className="container-select-add-notification">
                                    <select className="select-add-notification" onChange={e => {
                                        if(e.target.value !== ""){
                                            this.setState({service: e.target.value, messageService: "Service correct!", disableButton:false, classButton:this.state.type !== "" | this.state.service !== "" | this.state.dateDebut !== "" | this.state.dateFin !== "" | this.state.presentation !== ""?"btn-color-blue save":"btn-color-gray"});
                                        }else{
                                            this.setState({messageService: "Service incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                        } 
                                    }} value={this.state.service}>
                                        {
                                            this.state.services !== null ? this.state.services.map((service, i) => {
                                                return (
                                                    <option key={i} value={service.id}>{service.title}</option>
                                                )
                                            }) : <option value="">Type de publication</option>
                                        }
                                    </select>
                                    <div className="container-validation-lg">
                                        <div className={this.state.messageService !== "Service incorrect!"?"correct":"error"}>{this.state.messageService}</div>
                                    </div>
                                </div>
                                <div className="container-select-add-notification">   
                                    <select className="select-add-notification" onChange={e => {
                                        if(e.target.value !== ""){
                                            this.setState({type: e.target.value, messageType: "Page correct!", disableButton:false, classButton:this.state.type !== "" | this.state.service !== "" | this.state.dateDebut !== "" | this.state.dateFin !== "" | this.state.presentation !== ""?"btn-color-blue save":"btn-color-gray"});
                                        }else{
                                            this.setState({messageType: "Page incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                        } 
                                    }} value={this.state.type}>
                                        <option value="/">ACCUEIL</option>
                                        <option value="/services">NOS OFFRES ET SERVICES</option>
                                        <option value="/contact">CONTACTEZ-NOUS</option>
                                        {
                                            this.state.pages !== null ? this.state.pages.map((page, i) => {
                                                return (
                                                    <option key={i} value={page.page}>{page.title}</option>
                                                )
                                            }) : <option value="">Type de publication</option>
                                        }
                                    </select>
                                    <div className="container-validation-lg">
                                        <div className={this.state.messageType !== "Type incorrect!"?"correct":"error"}>{this.state.messageType}</div>
                                    </div>
                                </div>    
                            </div>
                            <div className="container-validation-sm">
                                <div className={this.state.messageService !== "Service incorrect!"?"correct":"error"}>{this.state.messageService}</div>
                                <div className={this.state.messageType !== "Type incorrect!"?"correct":"error"}>{this.state.messageType}</div>
                            </div>
                            <div className="row-add-user">
                                <div className="input-date-notification">
                                    <div className="container-input-date-notification">
                                        <span>Date Début* </span><input type="date" value={this.state.dateDebut} style={{border:"none"}} onChange={e => {
                                            let re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;
                                            let valueDate = e.target.value;
                                            if(valueDate.match(re) !== null){
                                                if(valueDate > this.state.dateFin){
                                                    this.setState({dateDebut: e.target.value, messageDateDebut: "La date de début doit être inférieure à la date de fin!", disableButton:true, classButton:"btn-color-gray"});
                                                }else{
                                                    this.setState({dateDebut: e.target.value, messageDateDebut: "Date correcte!", disableButton:false, classButton:this.state.type !== "" | this.state.service !== "" | this.state.dateDebut !== "" | this.state.dateFin !== "" | this.state.presentation !== ""?"btn-color-blue save":"btn-color-gray"});        
                                                }
                                            }else{
                                                this.setState({messageDateDebut: "Date incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                            }
                                        }}/>
                                    </div>
                                    <div className="container-validation-lg">
                                        <div className={this.state.messageDateDebut !== "Date incorrect!" && this.state.messageDateDebut !== "La date de début doit être inférieure à la date de fin!"?"correct":"error"}>{this.state.messageDateDebut}</div>
                                    </div>    
                                </div>
                                
                                <div className="input-date-notification">
                                    <div className="container-input-date-notification">
                                        <span>Date Fin* </span><input value={this.state.dateFin} disabled={this.state.dateDebut !== ""?false:true} type="date" style={{border:"none"}} onChange={e => {
                                            let re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;
                                            let valueDate = e.target.value;
                                            if(valueDate.match(re) !== null){
                                                if(valueDate < this.state.dateDebut){
                                                    this.setState({messageDateFin: "La date de fin doit être supérieur à la date de début!", disableButton:true, classButton:"btn-color-gray"});
                                                }else{
                                                    this.setState({dateFin: e.target.value, messageDateFin: "Date correcte!", disableButton:false, classButton:this.state.type !== "" | this.state.service !== "" | this.state.dateDebut !== "" | this.state.dateFin !== "" | this.state.presentation !== ""?"btn-color-blue save":"btn-color-gray"});
                                                }
                                            }else{
                                                this.setState({messageDateFin: "Date incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                            }   
                                        }}/>
                                    </div>
                                    <div className="container-validation-lg">
                                        <div className={this.state.messageDateFin !== "Date incorrect!" && this.state.messageDateFin !== "La date de fin doit être supérieur à la date de début!"?"correct":"error"}>{this.state.messageDateFin}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-validation-sm">
                                <div className={this.state.messageDateDebut !== "Date incorrect!" && this.state.messageDateDebut !== "La date de début doit être inférieure à la date de fin!"?"correct":"error"}>{this.state.messageDateDebut}</div>
                                <div className={this.state.messageDateFin !== "Date incorrect!" && this.state.messageDateFin !== "La date de fin doit être supérieur à la date de début!"?"correct":"error"}>{this.state.messageDateFin}</div>
                            </div>
                            <div  className="row-add-user">
                                <div className="input-add-user" style={{marginBottom:"15px"}}><textarea defaultValue={this.state.presentation} style={{border:"none"}} rows="3" cols="100" placeholder="Présentation (50 signes max.)" onChange={e => {
                                    let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                    let valuePresentation = e.target.value;
                                    if(valuePresentation.match(re) !== null){
                                        if(valuePresentation.length > 50){
                                            this.setState({messagePresentation: "Présentation incorrect maximum 50 caractères!", disableButton:true, classButton:"btn-color-gray"});
                                        }else{
                                            this.setState({presentation: e.target.value, messagePresentation: "Présentation correct!", disableButton:false, classButton:this.state.type !== "" | this.state.service !== "" | this.state.dateDebut !== "" | this.state.dateFin !== "" | this.state.presentation !== ""?"btn-color-blue save":"btn-color-gray"});    
                                        }
                                    }else{
                                        this.setState({messagePresentation: "Présentation incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                    }
                                }}></textarea></div>
                            </div>
                            <div className={this.state.messagePresentation !== "Présentation incorrect!" && this.state.messagePresentation !== "Présentation incorrect maximum 50 caractères!"?"correct":"error"} style={{marginTop:"-20px"}}>{this.state.messagePresentation}</div> 
                            
                            <div className="container-buttons">
                                <button type="button" className="close close-modal-add-user" data-dismiss="modal" aria-label="Close">
                                    <img src="../../icons/close.png" alt="ANNULER"/>
                                </button>
                                <button type="button" className={this.state.classButton} disabled={this.state.disableButton} onClick={e =>{
                                    
                                    API({
                                        method: 'put', url:  "/notification/update", data: {
                                            id: this.state.id,
                                            landingPage: this.state.type,
                                            dateDebut: this.state.dateDebut,
                                            dateEnd: this.state.dateFin,
                                            presentation: this.state.presentation,
                                            id_service: this.state.service,
                                        }
                                        }).then(res => {
                                            if(res.status === 200){
                                                sessionStorage.setItem('messageCreation',"Votre enregistrement a bien été effectué !")
                                                setTimeout(window.location.reload(),3000)
                                            }else{
                                                sessionStorage.setItem('messageCreation',"opps un problème est survenu, veuillez réessayer")
                                                setTimeout(window.location.reload(),3000)
                                            }  
                                            Array.from(document.querySelectorAll("input")).forEach(
                                                input => (input.value = "")
                                            );
                                            Array.from(document.querySelectorAll("select")).forEach(
                                                select => (select.value = "")
                                            );
                                            this.setState({
                                                messageType: "", 
                                                messageService: "",
                                                messageDateDebut: "",
                                                messageDateFin: "",
                                                messagePresentation: ""
                                            })                              
                                        }).catch(error => {
                                            this.setState({errorMessage:true, message:"Opps une erreur s'est produite, veuillez réessayer!"})
                                    })
                                }}>
                                    <img src="../../icons/save.png" alt="SAUVEGARDER" width="32px"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
