import React, { Component } from 'react';
import ViewDataService from './modals/ViewDataService';
import CreateDataService from './modals/CreateDataService';
import EditDataService from './modals/EditDataService';
import PdfDataService from './modals/PdfDataService';
import EditPdfService from './modals/EditPdfService';
import DeletePdfService from './modals/DeletePdfService';
import API from '../../axios';
import ReactPaginate from 'react-paginate';

export default class Items extends Component {
    constructor(props) {
        super(props)

        this.replaceModalItem = this.replaceModalItem.bind(this);

        this.state = {
            id:null,
            correctMessage: false,
            errorMessage: false,
            offset: 0,
            perPage: 10,
            currentPage: 0
        }

        this.generateHeaders = this.generateHeaders.bind(this);
        this.generateRows = this.generateRows.bind(this);
    }

    generateHeaders() {
        let cols = this.props.columns;  
        return cols.map(function (colData) {
            return (
              <th className="column-datatable" key={colData.key}>
                {colData.name}
              </th>
            );
          });
        }

        generateRows(){
            let dataTemp = this.props.data.slice(this.state.offset, this.state.offset + this.state.perPage);
            let cols = this.props.columns;  // [{key, label}]
            let packsData = this.props.dataPacks;
            return dataTemp.map((item, i) => {
                var packs = packsData.map(function(pack){
                    let itemPack = [];
                    if(item.id === pack.id_service){
                        if(pack.id_pack === 1){
                            itemPack.push("Negociations & Accords")
                        }else if(pack.id_pack === 2){
                            itemPack.push("Services & Prestataires")
                        }else{
                            itemPack.push("Logistique & Approvisionnement")
                        }
                    }
                    
                    return (
                        itemPack     
                    )
                })
                var cells = cols.map(function (colData) {
                  return (
                    <td key={colData.key}>
                      {Object.values(item)[colData.key]}
                    </td>);
                  });
                  return (
                    <tr key={i} className="container-row-table">
                      {cells}
                      <td>
                          {packs}
                      </td>
                      <td>
                        <a href="#!" className="btn-view-database" data-toggle="modal" data-target="#addPdfModalService" onClick={() => this.setState({id:item.id})}><img src="../../icons/MORE.png" alt="add" width="24px"/></a>        
                        <a href={"/pdfServiceAdminDashboard/"+item.id} className="btn-view-database"><img src="../../icons/eye-3.png" alt="eye" width="32px"/></a>        
                        <a href="#!" className="btn-view-database" data-toggle="modal" data-target="#editPdfModalService" onClick={() => this.setState({id:item.id})}><img src="../../icons/edit.png" alt="edit" width="24px"/></a>        
                        <a href="#!" className="btn-view-database" data-toggle="modal" data-target="#deletePdfModalService" onClick={() => this.setState({id:item.id})}><img src="../../icons/trash.png" alt="delete" width="24px"/></a>        
                      </td>
                      <td>
                        <label className="switch">  
                            <input type="checkbox" defaultChecked={item.post !== 0?"checked":""} onChange={e =>{
                                
                            API({
                                    method: 'put', url:  "/service/post", data: {
                                    id:item.id,
                                    post: e.target.checked
                                }
                                }).then(res => {
                                    if(res.status === 200){
                                        sessionStorage.setItem('updateRole',"Votre enregistrement a bien été effectué !")
                                        setTimeout(window.location.reload(),3000)
                                    }else{
                                        sessionStorage.setItem('updateRole',"opps un problème est survenu, veuillez réessayer!")
                                        setTimeout(window.location.reload(),3000)
                                    }                                
                                }).catch(error => {
                                    this.setState({errorMessage:true, message:"opps un problème est survenu, veuillez réessayer!"})
                                })
                            }} defaultValue={item.post} />
                            <span className="slider round"></span>
                        </label>    
                      </td>
                      <td>
                        <a href="#!" className="btn-view-database" data-toggle="modal" data-target="#viewModalService" onClick={() => this.setState({id:item.id})}><img src="../../icons/eye-3.png" alt="eye" width="40px"/></a>
                        <a href="#!" className="btn-edit-database" data-toggle="modal" data-target="#editModalService" onClick={() => this.setState({id:item.id})}><img src="../../icons/edit.png" alt="edit" width="24px"/></a>
                        <a href="#!" className="btn-edit-database" onClick={() => {
                          API({
                              method: 'delete', url:  "/service/delete", data: {
                                  id:item.id
                              }
                              }).then(res => {
                                  if(res.status === 200){
                                      sessionStorage.setItem('messageDelete',"Votre enregistrement a bien été effectué !")
                                      setTimeout(window.location.reload(),3000)
                                  }else{
                                      sessionStorage.setItem('messageDelete',"opps un problème est survenu, veuillez réessayer")
                                      setTimeout(window.location.reload(),3000)
                                  }                                
                              }).catch(error => {
                                  this.setState({errorMessage:true, message:"Opps une erreur s'est produite, veuillez réessayer!"})
                          })
                        }}><img src="../../icons/trash.png" alt="trash" width="24px"/></a>
                      </td>
                    </tr>
                  )
                
            });
          }  

    replaceModalItem() {
        let data = this.props.data;
        let dataPack = this.props.dataPacks;
        let itemTemp = [];
        let itemFinal = [];
        
        data.map( item => {
            if(item.id === this.state.id){
                itemTemp.push(item);
            }
        })
        
        dataPack.map( item => {
            if(item.id_service === this.state.id){
                itemTemp.push(item)
            }
        }) 
        
        itemTemp.map(e => {
            itemFinal.push(e.id, e.date, e.title, e.presentation, e.view, e.likes, e.post, e.id_pack, e.structureHtml, e.route)
        })

        return itemFinal;
    }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    });
  };

    render() {
        let data = this.generateRows();
        let item = this.replaceModalItem();
        let header = this.generateHeaders();
        return (
            <div className="row-table row-table-services">
                <table className="table-users table-services">
                <thead>
                    <tr>
                    {header}
                    <th className="column-datatable">PACK</th>
                    <th className="column-datatable">PDF</th>
                    <th className="column-datatable">PUBLIÉ</th>
                    <th className="column-datatable">ACTIONS</th>
                    </tr>
                </thead>  
                <tbody>
                    {data}
                </tbody>
                </table>
                <div className="container-pagination">
                    <a href="#!" data-toggle="modal" data-target="#addModalService"><img src="../../icons/MORE.png" alt="trash" width="32px"/></a>
                    <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(this.props.data.length / this.state.perPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick.bind(this)}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                </div>
                <ViewDataService
                    id={item[0]}
                    image={item[9]}
                    structureHtml={item[8]}
                />
                <CreateDataService />
                <EditDataService
                    id={item[0]}
                    image={item[9]}
                    structureHtml={item[8]}
                    packs={[item[15], item[23], item[31]]}
                />
                <PdfDataService 
                    id={item[0]}
                /> 
                <EditPdfService 
                    id={item[0]}
                />
                <DeletePdfService 
                    id={item[0]}
                />
            </div>
        )
    }
}
