import React, { Component } from 'react'
import ModalCreateMember from './ModalCreateMember'
import ModalResetPassword from './ModalResetPassword'
import API from '../axios';
import sha256 from 'sha256';
import { Redirect } from 'react-router-dom'


export default class ModalLogin extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            urlImgEyePassword: "../../icons/eye-2.png",
            typeInput: "password",
            showModalMember: false,
            showModalPassword: false,
            email: "",
            password: "",
            messageEmail: null,
            messagePassword: null,
            disableButton:true,
            classButton:"btn-color-gray",
            redirect: false,
            errorLogin:false,
            reset: false
        }
    }

    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    showModalMember = e =>{
        this.setState({
            showModalMember: !this.state.showModalMember
        });
    }

    showModalPassword = e =>{
        this.setState({
            showModalPassword: !this.state.showModalPassword
        });
    }

    connection = e => {
        API({
            method: 'post', url: '/user/login', data: {
                email: this.state.email,
                password: sha256(this.state.password),
            },
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            localStorage.setItem("user", JSON.stringify(res.data.data));
            this.responseForRedirect(res)
            this.onClose(e);
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            this.setState({messageEmail:"", messagePassword:""})
        }).catch((error) => {
            this.setState({errorLogin:true});
        });
    }

    responseForRedirect(res) {
        if (res.data.status === 200) {
            this.setState({ redirect: true })
        }
    }

    redirectDashboard() {
        if (this.state.redirect) {
            setTimeout(window.location.reload(),3000)
            return <Redirect to='/home' />
        }
    }

    render() {

        if(!this.props.show){
            return null;
        }

        return (
            <div className="container-modal">
                {this.redirectDashboard()}
                <div className="flex-container-modal">
                    <div className="close-modal">
                        <img src="../../icons/close.png" className="close-icon" onClick={e => {this.onClose(e)}} alt="close" width="32"/>
                    </div>
                    <div className="container-form-login">
                        <div className="form-login">
                            <div className="container-title-services">
                                <h3>ACCÈS MEMBRE</h3>
                                <span className="line-horizontal-center"></span> 
                            </div>
                            <div style={this.state.errorLogin?{color:"red", display:"block", margin:"5px"}:{display:"none"}}>L'adresse e-mail ou le mot de passe n'est pas valide ou la date d'activation a expiré!</div>
                            <div className="container-input">
                                <img src="../../icons/mail2.png" alt="email" width="48"/>
                                <input type="text" placeholder="Email" defaultValue={this.state.reset?"":this.state.email} onChange={e => {
                                    let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                                    let valueEmail = e.target.value;
                                    if(valueEmail.match(re) !== null){
                                        this.setState({email: e.target.value, messageEmail: "Email correct!", disableButton:false, classButton:this.state.password !== ""?"btn-color-blue":"btn-color-gray"});
                                    }else{
                                        this.setState({messageEmail: "Email incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                    }
                                }}/>
                            </div>
                            <div className={this.state.messageEmail !== "Email incorrect!"?"correct":"error"}>{this.state.messageEmail}</div>
                            <div className="container-input" style={{marginBottom:"15px"}}>
                                <img src="../../icons/secure.png" alt="password" width="32" style={{marginLeft:"5px"}}/>
                                <input type={this.state.typeInput} placeholder="Mot de passe" defaultValue={this.state.reset?"":this.state.password} style={{marginLeft:"7px"}} onChange={e => {
                                    if(e.target.value !== ""){
                                        this.setState({password: e.target.value, messagePassword:"Mot de passe correct!", disableButton:false, classButton:this.state.email !== ""?"btn-color-blue":"btn-color-gray"});
                                    }else{
                                        this.setState({messagePassword:"Mot de passe incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                    }
                                    
                                }}/>
                                <img className="eye-password" src={this.state.urlImgEyePassword} alt="eye" width="48" style={{marginLeft:"5px"}} onClick={e => {
                                    this.state.urlImgEyePassword === "../../icons/eye-2.png" && this.state.typeInput === "password"?this.setState({urlImgEyePassword: "../../icons/eye-1.png", typeInput:"text"}):this.setState({urlImgEyePassword: "../../icons/eye-2.png", typeInput:"password"})
                                }}/>
                                
                            </div> 
                            <div className={this.state.messagePassword !== "Mot de passe incorrect!"?"correct":"error"} style={{marginTop:"1px"}}>{this.state.messagePassword}</div>   
                            <a href="#!" onClick={e => {this.showModalPassword(e)}}>Mot de passe oublié?</a>
                            <ModalResetPassword onClose={this.showModalPassword} show={this.state.showModalPassword}/>    
                            <button className={this.state.classButton} disabled={this.state.disableButton} onClick={(e) => {
                                this.connection(e);
                            }}>SE CONNECTER</button>
                        </div>
                        <div className="container-register-member">
                            <h4>Vous n’avez pas de compte?</h4>
                            <button className="btn-color-blue"  style={{marginBottom:"15px"}} onClick={e => {this.showModalMember(e)}}>DEVENIR MEMBRE?</button>
                            <ModalCreateMember onClose={this.showModalMember} show={this.state.showModalMember}/> 
                        </div>
                    </div>    
                </div>
            </div>
        );
        
    }
}
