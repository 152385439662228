import React, { Component } from 'react';
import API from '../../../axios';

export default class PdfDataUpdate extends Component {
    constructor(props) {
        super(props);
        this.state ={
          file:null,
          id:"",
          classButton:"btn-color-gray",
          nameFile: ""
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
    }
    async onSubmit(e){
        e.preventDefault() 
        await this.uploadFile(this.state.file);
        
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            id: nextProps.id
        });
    }

    onChange(e) {
        this.setState({file:e.target.files[0],nameFile:e.target.files[0].name, disableButton:false, classButton:"btn-color-blue save"})
    }

    async uploadFile(file){
        const formData = new FormData();
        formData.append('id', this.state.id)
        formData.append('nameFile',this.state.nameFile)
        formData.append('file',file)
        
        return  await API.post("/update/pdf", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if(res.status === 200){
                sessionStorage.setItem('messageCreation',"Votre enregistrement a bien été effectué !")
                setTimeout(window.location.reload(),3000)
            }else{
                sessionStorage.setItem('messageCreation',"opps un problème est survenu, veuillez réessayer")
                setTimeout(window.location.reload(),3000)
            }
        });
      }
    
      render() {
        return (
            <div className="modal fade" id="addPdfModalUpdate" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Créer un pdf</h5>
                        </div>
                        <div className="modal-body container-body-modal">
                            <form onSubmit={this.onSubmit} style={{width:"100%"}}>
                                <div className="row-add-user">
                                   <input type="file" onChange={ this.onChange } />
                                </div>
                                <div className="container-buttons">
                                    <button type="button" className="close close-modal-add-user" data-dismiss="modal" aria-label="Close">
                                        <img src="../../icons/close.png" alt="ANNULER"/>
                                    </button>
                                    <button type="submit" className={this.state.classButton} disabled={this.state.disableButton} >
                                        <img src="../../icons/save.png" alt="SAUVEGARDER" width="32px"/>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
       )
      }
        

}
