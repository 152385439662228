import React, { Component } from 'react'
import API from '../../../axios';
import ChangePdf from './ChangePdf';

export default class EditPdfUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pdfs: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== undefined) {
            API({
                method: 'get', url: '/update/getPdfById?id=' + nextProps.id
            }).then(res => {
                this.setState({ pdfs: res.data.data })
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    render() {
        return (
            <div className="modal fade" id="editPdfModalUpdate" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit PDFs Actualité</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-pdf">
                                {
                                    this.state.pdfs !== [] ? this.state.pdfs.map((pdf) => {
                                        return (
                                            <div className="container-list-pdf" key={pdf.id}>
                                                <div>{pdf.name}</div>
                                                <ChangePdf idPdf={pdf.id} />
                                            </div>
                                        );
                                    })
                                        : <div></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
