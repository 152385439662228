import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import {withRouter} from "react-router-dom";
import API from './axios'

class Post extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            id:"",
            data: [],
            structure: "",
            pdf:false
        }
    }
    
    componentDidMount() {
        const id = this.props.match.params.id;
        API({
            method: 'get', url: '/update/getbyId?id='+id
        }).then(res => {
            let data = res.data.data;
            let structure = res.data.data.structureHtml;
            let replaceH2Structure = structure.substring(structure.indexOf("</h2>")+5, structure.length)
            let image = replaceH2Structure.replace(/(<figure class="image">)/gi, "<figure style='display:none;'>")
            /*let videosTagFigure = image.replace(/(figure)/ig,'div')
            let videos = videosTagFigure.replace(/(oembed)/ig,'iframe')
            let url = videos.replace(/(url)/ig,'src')*/
            this.setState({data:data, structure: image});
        }).catch((error) => {
            console.log(error)
        }); 
        API({
            method: 'get', url: '/update/getPdfById?id='+id
        }).then(res => {
            let dataPdf = res.data.data
            if(dataPdf.length > 0){
                this.setState({pdf:true, id:dataPdf[0].id})
            }
        }).catch((error) => {
            console.log(error)
        });
        
    }

    render() {
        let date = new Date(this.state.data.date);
        let formatYear = date.toLocaleDateString('fr-FR', {year:'numeric'});
        let formatMonth = date.toLocaleDateString('fr-FR', {month: '2-digit'});
        let formatDay =  date.toLocaleDateString('fr-FR', {day: '2-digit'});
        let datePublication = formatDay+"/"+formatMonth+"/"+formatYear;
        if(localStorage.getItem("user") !== null){
            localStorage.removeItem("role")
            
        }else{
            localStorage.setItem("role", JSON.stringify({role:3}))
        }
        let user = JSON.parse(localStorage.getItem("user")) !== null?JSON.parse(localStorage.getItem("user")):JSON.parse(localStorage.getItem("role"));
        return (
            <div>
                <Header/>
                    <div className="container-main-post">
                        <div className="container-title-post"><h1>ACTUALITÉS</h1><div className="line-horizontal-post"></div></div>
                        <div className="container-post">
                            <h1>{this.state.data.title}</h1>
                            <div className="container-date-view-likes">
                                <div className="container-view-likes">
                                            <div className="container-view">
                                                <img src="../icons/eye-3.png" alt="view" width="36"/>
                                                <span>{this.state.data.view}</span>
                                            </div>
                                            <div className="container-likes">
                                                <img src="../icons/like.png" alt="like" onClick={e => {
                                                    API({
                                                        method: 'put', url: '/update/likes', data:{
                                                            id: this.state.data.id
                                                        }
                                                    }).then(res => {
                                                        if(res.status === 200){
                                                            sessionStorage.setItem('updateRole',"Votre enregistrement a bien été effectué !")
                                                            setTimeout(window.location.reload(),3000)
                                                        }else{
                                                            sessionStorage.setItem('updateRole',"opps un problème est survenu, veuillez réessayer!")
                                                            setTimeout(window.location.reload(),3000)
                                                        }  
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    });
                                                }}/>
                                                <span>{this.state.data.likes}</span>
                                            </div>
                                            <div className="icon-pdf">
                                                {this.state.pdf && user.role === 2?<a className="pdf-image" href={"/pdf/"+this.state.id}><img src="../icons/pdf.png" alt="pdf" width="40px"/><p>Voir le document</p></a>:this.state.pdf && user.role === 1?<a className="pdf-image" href={"/pdfAdmin/"+this.state.id}><img src="../icons/pdf.png" alt="pdf" width="40px"/><p>Voir le document</p></a>:<div></div>}
                                            </div>
                                </div>
                            </div>
                            <img className="img-post width-img" src={process.env.REACT_APP_S3_HOST+this.state.data.image} alt="post"/>
                            <div className="container-post-description" style={{textAlign:"justify"}} dangerouslySetInnerHTML={{ __html: this.state.structure }}/>
                            <div className="date-publishing">
                                Date de publication: {datePublication}
                            </div>
                        </div>
                    </div>    
                <Footer/>
            </div>
        )
    }
}

export default withRouter(Post);
