import React, { Component } from 'react'
import API from '../../axios';
import Header from '../../Header';
import Footer from '../../Footer';
import MenuDashboard from '../MenuDashboard';
import Items from './Items';

const columns = [
    {
        "key": 1,
        "name": "DATE"
    },
    {
        "key": 2,
        "name": "LOGO"
    },
    {
        "key": 3,
        "name": "TITRE"
    },
    {
        "key": 4,
        "name": "DESCRIPTION"
    },
    {
        "key": 5,
        "name": "VUES"
    },
    {
        "key": 6,
        "name": "LIKES"
    }
];


export default class Updates extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            correctMessageDelete: false,
            errorMessageDelete: false,
            correctMessageUpdateRole: false,
            errorMessageUpdateRole: false,
            correctMessageCreateUser:false,
            errorMessageCreateUser:false,
            correctMessageUpdateUser:false,
            errorMessageUpdateUser:false,
        }
    }

    componentDidMount(){
        API({
            method: 'get', url: '/update/list',
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            let data = res.data.data;
            let dataUpdate = [];
            data.map((register) => {
                if(register.enabled !== 0){
                    let date = new Date(register.date);
                    let formatMonth = date.toLocaleDateString('fr-FR', { month: '2-digit' });
                    let formatDay = date.toLocaleDateString('fr-FR', { day: '2-digit' });
                    let formatYear = date.toLocaleDateString('fr-FR', { year: 'numeric' });
                    let formatted_date = formatYear + "-" + formatMonth + "-" + formatDay;
                    
                    return dataUpdate.push({
                        id: register.id,
                        date: formatted_date,                        
                        image: register.image !== "" && register.image !== null?<img className="image-update" src={process.env.REACT_APP_S3_HOST+register.image} alt="post"/>:<img className="image-update" src="../../icons/default.png" alt="post" style={{width:"24px !important"}}/>,
                        type: register.title,
                        description: register.description,
                        view: register.view,
                        likes: register.likes,
                        structureHtml: register.structureHtml,
                        route: register.image,
                        state: register.state,
                        notified: register.notified
                    })
                    
                }  
                               
            })
            this.setState({data: dataUpdate}) 
        }).catch((error) => {
            console.log(error)
        });

        if(sessionStorage.getItem("messageDelete") !== null){
            if(sessionStorage.getItem("messageDelete") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageDelete:true})
                 this.setState({errorMessageDelete:false})
            }else{
                 this.setState({correctMessageDelete:false})
                 this.setState({errorMessageDelete:true})
            } 
         }
         if(sessionStorage.getItem("updateRole") !== null){
            if(sessionStorage.getItem("updateRole") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageUpdateRole:true})
                 this.setState({errorMessageUpdateRole:false})
            }else{
                 this.setState({correctMessageUpdateRole:false})
                 this.setState({errorMessageUpdateRole:true})
            } 
         }
         if(sessionStorage.getItem("messageCreation") !== null){
            if(sessionStorage.getItem("messageCreation") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageCreateUser:true})
                 this.setState({errorMessageCreateUser:false})
            }else{
                 this.setState({correctMessageCreateUser:false})
                 this.setState({errorMessageCreateUser:true})
            } 
         }
         if(sessionStorage.getItem("messageUpdateUser") !== null){
            if(sessionStorage.getItem("messageUpdateUser") === "Votre enregistrement a bien été effectué !"){
                 this.setState({correctMessageUpdateUser:true})
                 this.setState({errorMessageUpdateUser:false})
            }else{
                 this.setState({correctMessageUpdateUser:false})
                 this.setState({errorMessageUpdateUser:true})
            } 
         }
         setTimeout(function () { sessionStorage.clear() }, 5000);
    }

    render() {
        return (
            <div className="container-main-dashboard">
                 <Header/>
                        <div style={this.state.correctMessageDelete?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageDelete")}</div>
                        <div style={this.state.errorMessageDelete?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageDelete")}</div>
                        <div style={this.state.correctMessageUpdateRole?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("updateRole")}</div>
                        <div style={this.state.errorMessageUpdateRole?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("updateRole")}</div>
                        <div style={this.state.correctMessageCreateUser?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageCreation")}</div>
                        <div style={this.state.errorMessageCreateUser?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageCreation")}</div>
                        <div style={this.state.correctMessageUpdateUser?{color:"green", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageUpdateUser")}</div>
                        <div style={this.state.errorMessageUpdateUser?{color:"red", display:"block"}:{display:"none"}}>{sessionStorage.getItem("messageUpdateUser")}</div>
                        <div className="container-console">
                            <div className="container-title-dashboard">
                                <h1>Console de gestion</h1>
                            </div>
                            <div className="container-table-menu-dashboard">
                                <div className="container-menu">
                                    <MenuDashboard />
                                </div>
                                <div className="line-vertical-dashboar"></div>
                                <div className="container-table container-table-udpates">
                                    <Items columns={columns} data={this.state.data}/>
                                </div>
                            </div>
                        </div>  
                 <Footer/> 
            </div>
        )
    }
}
