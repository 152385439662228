import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'



export default class MenuDashboard extends Component {
    
    
    render() {
        return (
            <div className="menu-dashboard">
                <NavLink to="/dashboard" className="item-menu-dashboard" exact activeClassName="currentDashboard">OFFRES ET SERVICES</NavLink>
                <NavLink to="/dashboard/newsletter" className="item-menu-dashboard" activeClassName="currentDashboard">ACTUALITÉS</NavLink>
                <NavLink to="/dashboard/users" className="item-menu-dashboard" activeClassName="currentDashboard">ABONNÉS</NavLink>
                <NavLink to="/dashboard/notifications" className="item-menu-dashboard" activeClassName="currentDashboard">NOTIFICATIONS</NavLink>
            </div>
        )
    }
}
