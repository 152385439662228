import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import API from '../../../axios';

export default class CreateDataService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packs: [],
            title: '',
            structureHtml: '',
            accessPack: false,
            easyPack: false,
            premiumPack: false,
            presentation: '',
            messageTitle: null,
            messagePresentation: null,
            classButton: "btn-color-gray",
            route: ""
        }
    }


    render() {
        let arrayUser = JSON.parse(localStorage.getItem("user"));
        let idUser = arrayUser.id;
        return (
            <div className="modal fade" id="addModalService" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content adjust-size-modal modal-dialog-centered">
                        <div className="modal-header" style={{ width: "100%" }}>
                            <h5 className="modal-title" id="exampleModalLabel">Ajouster offre ou service</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row-add-user" style={{ marginBottom: "20px" }}>
                                <div className="input-add-user container-radio-services">
                                    <span className="modal-lable">Pack </span>
                                    <div><input type="checkbox" value="1" onChange={e => {
                                        this.setState({ accessPack: e.target.value })
                                    }} />
                                        <span>Negociations & Accords</span>
                                    </div>
                                    <div><input type="checkbox" value="2" onChange={e => {
                                        this.setState({ easyPack: e.target.value })
                                    }} />
                                        <span>Services & Prestataires</span></div>
                                    <div><input type="checkbox" value="3" onChange={e => {
                                            this.setState({ premiumPack: e.target.value })
                                        }} /><span>Logistique & Approvisionnement</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row-add-user">
                                <CKEditor
                                    editor={
                                        ClassicEditor
                                    }
                                    data={this.state.dataInfo}
                                    config={{
                                        ckfinder: {
                                            // Upload the images to the server using the CKFinder QuickUpload command.
                                            uploadUrl: process.env.REACT_APP_API_ENPOINT_MAIN + '/uploader',
                                            filebrowserUploadMethod: "form"
                                        }
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        if (data !== null) {
                                            this.setState({ structureHtml: data });

                                            this.setState({ data: data, disableButton: false });
                                            let presentation = "";
                                            if (data.indexOf("<h2>") !== -1) {
                                                this.setState({ title: data.substring(data.indexOf("<h2>") + 4, data.indexOf("</h2>")).replace(/&nbsp;/gi, '') });
                                                presentation = data.substring(data.indexOf('</h2>'), data.length);
                                            } else {
                                                if (data.indexOf("<h3>") !== -1) {
                                                    this.setState({ title: data.substring(data.indexOf('<h3>') + 4, data.indexOf('</h3>')) }).trim();
                                                    presentation = data.substring(data.indexOf('</h3>'), data.length);
                                                } else {
                                                    if (data.indexOf("<h4>") !== -1) {
                                                        this.setState({ title: data.substring(data.indexOf("<h4>") + 4, data.indexOf("</h4>")) }).trim();
                                                        presentation = data.substring(data.indexOf('</h4>'), data.length);
                                                    } else {
                                                        this.setState({ title: "" });
                                                        presentation = data;
                                                    }
                                                }
                                            }
                                            let dataCorrection = presentation.replace(/&nbsp;/gi, '');
                                            let arrayP = dataCorrection.replace(/<(.|\n)*?>/gi, '');
                                            if (this.state.url !== null) {
                                                this.setState({ presentation: arrayP.substring(0, 50), classButton: this.state.data !== "" && this.state.url !== "" ? "btn-color-blue save" : "btn-color-gray" });
                                            }

                                        }
                                    }}
                                />
                            </div>
                            <div className="container-buttons">
                                <button type="button" className="close close-modal-add-user" data-dismiss="modal" aria-label="Close">
                                    <img src="../../icons/close.png" alt="ANNULER" />
                                </button>
                                <button type="button" className={this.state.classButton} disabled={this.state.disableButton} onClick={e => {
                                    let array = [];
                                    let title = this.state.title;
                                    if (parseInt(this.state.accessPack) === 1) {
                                        array.push(1)
                                    } else {
                                        array.slice(array.indexOf(1), 1)
                                    }
                                    if (parseInt(this.state.easyPack) === 2) {
                                        array.push(2)
                                    } else {
                                        array.slice(array.indexOf(2), 2)
                                    }
                                    if (parseInt(this.state.premiumPack) === 3) {
                                        array.push(3)
                                    } else {
                                        array.slice(array.indexOf(3), 3)
                                    }
                                    API({
                                        method: 'get', url: "/routeImage"
                                    }).then(res => {
                                        if (res.status === 200) {
                                            API({
                                                method: 'post', url: "/service/save", data: {
                                                    title: title.trim(),
                                                    image: res.data,
                                                    presentation: this.state.presentation,
                                                    id_user: idUser,
                                                    packs: array.length > 0 ? array : [],
                                                    structureHtml: this.state.structureHtml
                                                }
                                            }).then(res => {
                                                if (res.status === 200) {
                                                    sessionStorage.setItem('messageCreation', "Votre enregistrement a bien été effectué !")
                                                    setTimeout(window.location.reload(), 3000)
                                                } else {
                                                    sessionStorage.setItem('messageCreation', "opps un problème est survenu, veuillez réessayer")
                                                    setTimeout(window.location.reload(), 3000)
                                                }
                                                Array.from(document.querySelectorAll("input")).forEach(
                                                    input => (input.checked = "")
                                                );
                                            }).catch(error => {
                                                this.setState({ errorMessage: true, message: "Opps une erreur s'est produite, veuillez réessayer!" })
                                            })
                                        }
                                    })
                                    API({
                                        method: 'get', url: "/clearImage"
                                    }).then(res => {
                                        console.log(res);
                                    })
                                }}>
                                    <img src="../../icons/save.png" alt="SAUVEGARDER" width="32px" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
