import React, { Component } from 'react'
import API from '../axios';
import sha256 from 'sha256';
import { Redirect } from 'react-router-dom';

export default class ModalPassword extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            urlImgEyePassword: "../../icons/eye-2.png",
            typeInput: "password",
            showModalMember: false,
            password: "",
            passwordConfirmation: "",
            messagePassword: null,
            messagePasswordConfirmation: null,
            disableButton:true,
            classButton:"btn-color-gray btn-reset-password",
            errorPassword:false,
            correctPassword: false,
            redirect:false
        }
    }

    responseForRedirect(res) {
        if (res.data.status === 200) {
            this.setState({ redirect: true })

        }
    }

    redirectDashboard() {
        if (this.state.redirect) {
            return <Redirect to='/' />
        }
    }

    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    connection = e => {
        API({
            method: 'post', url: '/user/password', data: {
                email: this.props.email,
                password: sha256(this.state.password),
                passwordChange: this.state.password
            },
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            this.setState({correctPassword:true});
            this.setState({errorPassword:false});
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            this.responseForRedirect(res)
            this.setState({messageEmail:"", messagePassword:"", messagePasswordConfirmation:""})
        }).catch((error) => {
            this.setState({correctPassword:false});
            this.setState({errorPassword:true});
        });
    }

    render() {

        if(!this.props.show){
            return null;
        }

        return (
            <div className="container-modal">
                {this.redirectDashboard()}
                <div className="flex-container-modal">
                    <div className="close-modal">
                        <img src="../../icons/close.png" className="close-icon" onClick={e => {this.onClose(e)}} alt="close" width="32"/>
                    </div>
                    <div className="container-form-login">
                        <div className="form-login">
                            <div className="container-title-services">
                                <h3 style={{marginTop:"10px", padding:"0px 10px"}}>RÉINITIALISATION DU MOT DE PASSE</h3>
                                <span className="line-horizontal-center"></span> 
                            </div>
                            <div style={this.state.correctPassword?{color:"green", display:"block"}:{display:"none"}}>Votre nouveau mot de passe est bien enregistré.</div>
                            <div style={this.state.errorPassword?{color:"red", display:"block"}:{display:"none"}}>Mauvaise adresse mail</div>
                            <div className="container-input">
                                <img src="../../icons/secure.png" alt="password" width="32" style={{marginLeft:"5px"}}/>
                                <input type={this.state.typeInput} placeholder="Entrer un nouveau mot de passe" style={{marginLeft:"7px"}} onChange={e => {
                                    if(e.target.value !== ""){
                                        if(e.target.value !== this.state.passwordConfirmation){
                                            this.setState({password: e.target.value, messagePasswordConfirmation:"Mauvaise confirmation de mot de passe!", disableButton:true, classButton:"btn-color-gray btn-reset-password"});
                                        }else{
                                            this.setState({password: e.target.value, messagePassword:"Mot de passe correct!", disableButton:false, classButton:this.state.passwordConfirmation !== "" ?"btn-color-blue btn-reset-password":"btn-color-gray btn-reset-password"});
                                            this.setState({passwordConfirmation: e.target.value, messagePasswordConfirmation:"Mot de passe correct!", disableButton:false, classButton:this.state.passwordConfirmation !== "" ?"btn-color-blue btn-reset-password":"btn-color-gray btn-reset-password"});
                                        }
                                        
                                    }else{
                                        this.setState({messagePassword:"Mot de passe incorrect!", disableButton:true, classButton:"btn-color-gray btn-reset-password"});
                                    }
                                    
                                }}/>
                                <img className="eye-password" src={this.state.urlImgEyePassword} alt="eye" width="48" style={{marginLeft:"5px"}} onClick={e => {
                                    this.state.urlImgEyePassword === "../../icons/eye-2.png" && this.state.typeInput === "password"?this.setState({urlImgEyePassword: "../../icons/eye-1.png", typeInput:"text"}):this.setState({urlImgEyePassword: "../../icons/eye-2.png", typeInput:"password"})
                                }}/>
                                
                            </div>
                            <div className={this.state.messagePassword !== "Mot de passe incorrect!" && this.state.messagePassword !== "Mauvaise confirmation de mot de passe!"?"correct":"error"} >{this.state.messagePassword}</div>   
                            <div className="container-input">
                                <img src="../../icons/secure.png" alt="password" width="32" style={{marginLeft:"5px"}}/>
                                <input type={this.state.typeInput} placeholder="Confirmer votre mot de passe" style={{marginLeft:"7px"}} onChange={e => {
                                    if(e.target.value !== ""){
                                        if(e.target.value !== this.state.password){
                                            this.setState({passwordConfirmation: e.target.value, messagePasswordConfirmation:"Mauvaise confirmation de mot de passe!", disableButton:true, classButton:"btn-color-gray btn-reset-password"});                                                
                                        }else{
                                            this.setState({passwordConfirmation: e.target.value, messagePasswordConfirmation:"Mot de passe correct!", disableButton:false, classButton:this.state.password !== "" ?"btn-color-blue btn-reset-password":"btn-color-gray btn-reset-password"});
                                        }                                                
                                    }else{
                                        this.setState({passwordConfirmation: e.target.value, messagePasswordConfirmation:"Mot de passe incorrect!", disableButton:true, classButton:"btn-color-gray btn-reset-password"});
                                    }
                                }}/>
                                <img className="eye-password" src={this.state.urlImgEyePassword} alt="eye" width="48" style={{marginLeft:"5px"}} onClick={e => {
                                    this.state.urlImgEyePassword === "../../icons/eye-2.png" && this.state.typeInput === "password"?this.setState({urlImgEyePassword: "../../icons/eye-1.png", typeInput:"text"}):this.setState({urlImgEyePassword: "../../icons/eye-2.png", typeInput:"password"})
                                }}/>
                                
                            </div> 
                            <div className={this.state.messagePasswordConfirmation !== "Mot de passe incorrect!" && this.state.messagePasswordConfirmation !== "Mauvaise confirmation de mot de passe!" ?"correct":"error"} >{this.state.messagePasswordConfirmation}</div>   
                            <button className={this.state.classButton} disabled={this.state.disableButton} onClick={(e) => {this.connection(e);}} >ENREGISTRER</button>
                        </div>
                    </div>    
                </div>
            </div>
        )
    }
}
