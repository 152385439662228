import API from '../../axios';

export const getUserList = async () => {
    try {
        let {data} = await API({
            method: 'get',
            url: '/user/list',
            headers: { 'Content-Type': 'application/json' }
        })

        return data.data.map((row) => {
            let date = new Date(row.date);
            let formatMonth = date.toLocaleDateString('fr-FR', { month: '2-digit' });
            let formatDay = date.toLocaleDateString('fr-FR', { day: '2-digit' });
            let formatYear = date.toLocaleDateString('fr-FR', { year: 'numeric' });
            let formatted_date = formatYear + "-" + formatMonth + "-" + formatDay;

            return {
                id: row.id,
                date: formatted_date,
                name: row.name,
                email: row.email,
                phone: row.phone,
                occupation: row.occupation,
                role: row.id_role,
                dateActivation: row.dateActivation,
                dateInactivation: row.dateInactivation,
                gender: row.gender,
                subscribed: row.newsletter === 1? true : false
            }
        })
    } catch (error) {
        console.log(error)
    };
}

export const deleteUser = async (userId) => {
    return await API({
        method: 'delete',
        url:  "/user/delete",
        data: {
            id:userId
        }
    })
}

export const updateUserRole = async (id, role) => {
    return await API({
        method: 'put',
        url: "/user/updateRole",
        data: {
          id: id,
          id_role: role
        }
    })
}

export const updateUser = async (user) => {
    return await API({
        method: 'put',
        url: "/user/update",
        data: {
            id: user.id,
            name: user.name,
            email: user.email,
            occupation: user.occupation,
            phone: user.phone,
            state: 1,
            newsletter: user.subscribed? 1 : 0,
            gender: parseInt(user.gender) !== 0 ? "Madame" : "Monsieur",
            dateActivation: user.dateDebut,
            dateInactivation: user.dateFin
        }
    })
}

export const updateUserCollection = async (users) => {
    return await API({
        method: 'post',
        url: "/user/process/collection",
        data: { users: users.map((u) => {
            return {
                id: u.Id,
                name: u.Nom,
                email: u.Email,
                occupation: u.Poste,
                phone: u.Telephone,
                gender: u.Genre,
                newsletters: u.Newsletters === 'Y' ? 1 : 0,
                action: u.Action
            }
        })}
    })
}