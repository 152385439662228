import React, { Component } from 'react'
import Header from './Header';
import Footer from './Footer';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export default class Policy extends Component {
    
    render() {
        return (
            <div>
                <Header/>
                <div className="container-mentions-cgu-policy">
                    <div className="row-mentions-cgu-policy">
                        <h3>POLITIQUE DE CONFIDENTIALITÉ</h3>
                        <div className="containerline-horizontal"><div className="line-horizontal"></div></div>
                        <Accordion allowMultipleExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        1. Méthodes de collecte
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Au cours de votre navigation sur le site <a href="http://kmrc.info">www.collectifpharma.fr</a>, des données personnelles vous concernant pourront seront recueillies et traitées de la façon suivante : 
                                        </p>
                                        <ul>
                                            <li>L'envoi d'un formulaire ;</li>
                                            <li>L'envoi d'un cookie.</li>
                                        </ul>
                                        <ol>
                                            <li>
                                                <h5>L'envoi d'un formulaire</h5>
                                                <p>En cas, notamment, de demande de contact, vous serez invité(e) à remplir un formulaire dont certains champs (« champs obligatoires ») sont nécessaires pour pouvoir offrir le service demandé, et dont d’autres sont optionnels (« champs facultatifs »). Ces derniers sont uniquement destinés à nous permettre de vous contacter rapidement si cela s’avérait nécessaire.</p>
                                            </li>
                                            <li>
                                                <h5>Cookies</h5>
                                                <p>La navigation sur le site <a href="http://kmrc.info">www.collectifpharma.fr</a> est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation. L'utilisateur du site reconnaît avoir été informé de cette pratique et autorise Kairos ID à l’employer en tant que responsable du traitement des données personnelles. Il pourra désactiver ce cookie par l’intermédiaire des paramètres figurant au sein de son navigateur. Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services.</p>
                                            </li>
                                        </ol>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        2. Informations collectées
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Kairos ID limite la collecte des données personnelles au strict nécessaire (minimisation des données) pour un contact commercial. Les informations collectées sont les suivantes : 
                                        </p>
                                        <ul>
                                            <li>Données générales et techniques propres à votre visite sur le site (source de trafic, système d’exploitation, navigateur et type d’équipement utilisé, pays et ville de connexion via votre adresse IP, institution via laquelle vous accédez à nos services, etc.) ; </li>
                                            <li>Votre adresse de courrier électronique si vous nous l'avez indiquée, par exemple en remplissant le formulaire de contact, en envoyant des messages ou questions sur ce site, en communiquant avec nous par courrier électronique, etc. ; </li>
                                            <li>L'ensemble de l'information concernant les outils, éléments de navigation et pages de contenu que vous avez consultées sur notre site ; </li>
                                            <li>Toute information que vous nous avez donnée volontairement. Dans le cas d’une demande de contact, le formulaire requiert la saisie des civilité, nom, prénom, fonction, organisation, email, téléphone, commentaires s’il y a lieu.</li>
                                        </ul>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        3. Consentement
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            En utilisant notre site, vous déclarez explicitement accepter la politique de confidentialité de Kairos ID, et vous consentez à ce que nous recueillions et traitions les données vous concernant selon les modalités et principes décrits ici. 
                                        </p>
                                        <p>
                                            Par ailleurs, les courriels promotionnels ou d’actualités qui vous seront éventuellement transmis feront toujours apparaître un lien qu’il vous sera possible de cliquer pour accéder à votre profil ou une page spécifique pour ne plus recevoir, de notre part, d’informations promotionnelles. 
                                        </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        4. Transmission aux tiers
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Vos données ne seront transmises à aucun tiers sans votre consentement, à l’exception de la société d’hébergement et des agents techniques s’il y a lieu qui nous aident à maintenir le site en activité.
                                        </p>
                                        <p>
                                            La société d’hébergement est OVH et vos données sont stockées sur un serveur localisé en France. 
                                        </p>
                                        <p>
                                            Kairos ID SAS a demandé à OVH de prendre toutes les dispositions nécessaires pour stocker et traiter ces données avec la plus grande prudence, de façon à ce qu’elles ne puissent être portées de façon illicite à la connaissance de tiers. 
                                        </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        5. Droit d’accès, de rectification, de modification et de suppression de vos données
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            En application de la loi Informatique et Libertés du 6 janvier 1978, vous disposez d’un droit d’accès, de rectification, de modification et de suppression des données qui vous concernent. Vous pouvez exercer ce droit de plusieurs façons:
                                        </p>
                                        <ul>
                                            <li>Par email à : contact@kairos-id.com</li>
                                            <li>Par voie postale à : Kairos ID SAS, 39 rue Esquirol, 75013 Paris</li>
                                        </ul>
                                        <p>
                                            Nous nous engageons à le faire dans les meilleurs délais, sauf pour les informations nécessaires au respect de nos obligations comptables. 
                                        </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        6. Réseaux sociaux
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            L'Utilisateur des services a la possibilité de cliquer sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Youtube, Linkedin, Google Plus et tout autre lien d'un réseau social figurant sur le site
                                        </p>
                                        <p>
                                            Les réseaux sociaux permettent d'améliorer la convivialité du Site et aident à sa promotion via les partages. Les services de partage de contenus permettent d'enrichir le Site et augmentent sa visibilité. 
                                        </p>
                                        <p>
                                            Lorsque l'Utilisateur clique sur ces boutons, le site pourra avoir accès aux informations personnelles que l'Utilisateur aura indiquées comme publiques et accessibles depuis ses profils Twitter, Facebook, YouTube Linkedin, Google plus et tout autre lien d'un réseau social. Cependant, le site ne crée ni n'utilise aucune base de données indépendante de Twitter, Facebook, YouTube, Linkedin Google Plus et tout autre lien d'un réseau social à partir des informations personnelles que l'Utilisateur peut y publier et ne traitera aucune donnée relevant de sa vie privée par ce biais. 
                                        </p>
                                        <p>
                                            Si l'Utilisateur ne souhaite pas que le site ait accès aux informations personnelles publiées sur l'espace public de ses profils ou de ses comptes sociaux, l'Utilisateur devra alors utiliser les moyens mis à sa disposition par Twitter, Facebook, YouTube, Linkedin, Google Plus et tout autre lien d'un réseau social afin de limiter l'accès à ses données. 
                                        </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        7. Sécurité
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Kairos ID s'engage à faire ses meilleurs efforts pour protéger vos données à caractère personnel, afin notamment d'empêcher qu'elles soient déformées, endommagées ou communiquées à des tiers non autorisés. 
                                        </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="container-title-information">
                                        7. RGPD
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="container-item-acordeon">
                                        <p>
                                            Kairos ID s’engage à ce que la collecte et le traitement des données soient conformes au règlement général sur la protection des données (RGPD) et à la loi Informatique et Libertés.
                                        </p>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}
