import React, { Component } from 'react'
import Header from './Header';
import Footer from './Footer';
import API from './axios';
import ModalLogin from './modal/ModalLogin'
import CarouseUpdate from './CarouselUpdate';


let colorBlue = {
    background: "#213564"
}

let colorWhite = {
    background: "white"
}

export default class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            urlIMagePackAccess: "../../icons/service-1b.png",
            urlIMagePackEasy: "../../icons/service-2b.png",
            urlIMagePackPremium: "../../icons/service-3.png",
            backgroundColorAccess: colorWhite,
            backgroundColorEasy: colorWhite,
            backgroundColorPremium: colorWhite,
            email: "",
            messageEmail: null,
            redirect: false,
            errorMessage: false,
            correctMessage: false,
            showModalLogin: false,
            dataUpdate: []
        }
        this.connection = this.connection.bind(this);
    }

    connection() {
        API({
            method: 'post', url: '/user/inscription', data: {
                email: this.state.email,
            },
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            this.setState({ correctMessage: true });
            this.setState({ errorMessage: false });
        }).catch((error) => {
            this.setState({ errorMessage: true });
            this.setState({ correctMessage: false });
        });
    }

    showModalLogin = e => {
        this.setState({
            showModalLogin: !this.state.showModalLogin
        });
    }

    componentDidMount() {
        API({
            method: 'get', url: '/update/list'
        }).then(res => {
            this.setState({ dataUpdate: res.data.data })
        }).catch((error) => {
            console.log(error)
        });

    }

    render() {
        return (
            <div className="container-home">
                <Header />
                <div className="container-banner-main" style={{ backgroundImage: `url(../icons/banner_princ.png)`, backgroundPosition: 'center' }}>
                    <div className="row-banner-main">
                        <h1>NOTRE EXPERIENCE EN COMMUN</h1>
                        <p>Au sein du Collectif Pharma, nous proposons une vaste gamme de services et d’outils accessibles pour permettre à chaque pharmacien d’officine de se recentrer sur ce qui constitue le cœur de son métier.</p>
                        <p style={{ marginTop: "0px" }}>Penser la singularité de la pharmacie française, c’est incarner l’exercice indépendant de la profession, guidé par les opportunités qu’offre un collectif que nous souhaitons plus que jamais combatif.</p>
                        <a href="#!" onClick={e => { this.showModalLogin(e) }}>EN SAVOIR PLUS</a>
                    </div>
                </div>
                <div className="container-services">
                    <div className="container-title-services">
                        <h3>NOS OFFRES ET SERVICES</h3>
                        <span className="line-horizontal-center"></span>
                    </div>
                    <div className="container-item-services">
                        <div className="card-service" onMouseLeave={e => {
                            this.setState({
                                urlIMagePackEasy: "../../icons/service-2b.png",
                                backgroundColorEasy: colorWhite
                            })
                        }} onMouseEnter={e => {
                            this.setState({
                                urlIMagePackEasy: "../../icons/service-2b-bn.png",
                                backgroundColorEasy: colorBlue
                            })
                        }} onClick={e => { localStorage.setItem("pack", "easy") }}
                        >
                            {localStorage.getItem("user") !== null ? <a href="/services"><div className="container-img-services" style={this.state.backgroundColorEasy}><img src={this.state.urlIMagePackEasy} alt="pack-access" width="50" /></div></a> : <a href="#!" onClick={e => { this.showModalLogin(e) }}><div className="container-img-services" style={this.state.backgroundColorEasy}><img src={this.state.urlIMagePackEasy} alt="pack-access" width="40" /></div></a>}
                            <div className="container-title-info-services">
                                <h6>SERVICES & PRESTATAIRES</h6>
                                <p>Nous sélectionnons les services opérationnels les plus pertinents pour développer sereinement votre activité.</p>
                            </div>
                        </div>
                        <div className="card-service" onMouseLeave={e => {
                            this.setState({
                                urlIMagePackAccess: "../../icons/service-1b.png",
                                backgroundColorAccess: colorWhite
                            })
                        }} onMouseEnter={e => {
                            this.setState({
                                urlIMagePackAccess: "../../icons/service-1b-bn.png",
                                backgroundColorAccess: colorBlue
                            })
                        }} onClick={e => { localStorage.setItem("pack", "access") }}
                        >
                            {localStorage.getItem("user") !== null ?
                                <a href="/services">
                                    <div className="container-img-services" style={this.state.backgroundColorAccess}>
                                        <img src={this.state.urlIMagePackAccess} alt="pack-access" width="50" />
                                    </div>
                                </a> : <a href="#!" onClick={e => { this.showModalLogin(e) }}>
                                    <div className="container-img-services" style={this.state.backgroundColorAccess}>
                                        <img src={this.state.urlIMagePackAccess} alt="pack-access" width="40" />
                                    </div>
                                </a>
                            }
                            <div className="container-title-info-services">
                                <h6>NEGOCIATIONS & ACCORDS</h6>
                                <p>En misant sur le collectif, vous bénéficiez de l'ensemble des accords négociés et des outils de communication les plus innovants au service de la santé et du bien-être des patients.</p>
                            </div>
                        </div>
                        <div className="card-service" onMouseLeave={e => {
                            this.setState({
                                urlIMagePackPremium: "../../icons/service-3.png",
                                backgroundColorPremium: colorWhite
                            })
                        }} onMouseEnter={e => {
                            this.setState({
                                urlIMagePackPremium: "../../icons/service-3-bn.png",
                                backgroundColorPremium: colorBlue
                            })
                        }} onClick={e => { localStorage.setItem("pack", "premium") }}
                        >
                            {localStorage.getItem("user") !== null ? <a href="/services"><div className="container-img-services" style={this.state.backgroundColorPremium}><img src={this.state.urlIMagePackPremium} alt="pack-access" width="50" /></div></a> : <a href="#!" onClick={e => { this.showModalLogin(e) }}><div className="container-img-services" style={this.state.backgroundColorPremium}><img src={this.state.urlIMagePackPremium} alt="pack-access" width="40" /></div></a>}
                            <div className="container-title-info-services">
                                <h6>LOGISTIQUE & APPROVISIONNEMENT</h6>
                                <p>La garantie d’une solution logistique efficace pour apporter une dynamique commerciale à votre officine.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container-collectif" style={{ backgroundImage: `url(../icons/banner-sec.jpg)`, backgroundPosition: 'center' }}>
                        <div className="container-text-banner-sec">
                            <h3 className="banner-sec-home">Assurer la Cohésion, c'est Garantir l'Indépendance</h3>
                        </div>
                    </div>
                </div>
                {localStorage.getItem("user") ?
                    <div className="container-updates" id="actualites">
                        <div className="container-title-services">
                            <h3>ACTUALITÉS</h3>
                            <span className="line-horizontal-center"></span>
                        </div>
                        <div className="container-carousel">
                            <CarouseUpdate data={this.state.dataUpdate} className="carousel" />
                        </div>
                    </div>
                    : null
                }
                <div className="container-subscription">
                    <div className="container-title-services" style={{ marginBottom: "100px" }}>
                        <h3>ABONNEZ-VOUS À NOTRE NEWSLETTER</h3>
                        <span className="line-horizontal-center"></span>
                    </div>
                    <div className="container-form-subscription">
                        <h5 style={{ color: "rgba(191, 183, 178, 1)" }}>Veuillez saisir votre adresse email ici</h5>
                        <div style={this.state.errorMessage ? { color: "red", display: "block" } : { display: "none" }}>Email avec abonnement dans nos archives</div>
                        <div style={this.state.correctMessage ? { color: "green", display: "block" } : { display: "none" }}>Votre enregistrement a bien été effectué !</div>
                        <div className="form-subscription">
                            <div className="input-subcription">
                                <img src="../../icons/mail2.png" alt="email subcription" width="50" />
                                <input type="email" placeholder="Votre email" onChange={e => {
                                    let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                                    let valueEmail = e.target.value;
                                    if (valueEmail.match(re) !== null) {
                                        this.setState({ email: e.target.value, messageEmail: "Email correct!", disableButton: false, classButton: this.state.password !== "" ? "btn-color-blue" : "btn-color-gray" });
                                    } else {
                                        this.setState({ messageEmail: "Email incorrect!", disableButton: true, classButton: "btn-color-gray" });
                                    }
                                }} />
                            </div>
                            <button onClick={() => { this.connection() }}>VALIDER</button>
                        </div>
                        <div className={this.state.messageEmail !== "Email incorrect!" ? "correct container-message" : "error container-message"}>{this.state.messageEmail}</div>
                    </div>
                </div>
                <ModalLogin onClose={this.showModalLogin} show={this.state.showModalLogin} />
                <Footer />
            </div>
        )
    }
}
