import React, { useEffect, useState } from 'react';
import { updateUser } from '../userApi';

function EditDataUser(props) {
    const [state, setState] = useState({
        id: '',
        name: '',
        email: '',
        phone: '',
        occupation: '',
        dateDebut: '',
        dateFin: '',
        time: '',
        gender: '',
        subscribed: false,
        messageEmail: null,
        messageName: null,
        messageOccupation: null,
        messagePhone: null,
        messageDateDebut: null,
        messageDateFin: null,
        messageGenre: null,
        disableButton: true,
        classButton: "btn-color-gray",
    })


    useEffect(() => {
        let dateActivation = new Date(props.dateDebut);
        let dateInactivation = new Date(props.dateFin);
        let formatYear = dateActivation.toLocaleDateString('fr-FR', { year: 'numeric' });
        let formatMonth = dateActivation.toLocaleDateString('fr-FR', { month: '2-digit' });
        let formatDay = dateActivation.toLocaleDateString('fr-FR', { day: '2-digit' });
        let formatYearInactivation = dateInactivation.toLocaleDateString('fr-FR', { year: 'numeric' });
        let formatMonthInactivation = dateInactivation.toLocaleDateString('fr-FR', { month: '2-digit' });
        let formatDayInactivation = dateInactivation.toLocaleDateString('fr-FR', { day: '2-digit' });
        let dateDebut = formatYear + "-" + formatMonth + "-" + formatDay;
        let dateFin = formatYearInactivation + "-" + formatMonthInactivation + "-" + formatDayInactivation;
        setState((state) => {
            return {
                ...state,
                id: props.id,
                name: props.name,
                dateDebut: dateDebut,
                dateFin: dateFin,
                email: props.email,
                phone: props.phone,
                gender: props.gender === "Madame" ? 1 : 0,
                subscribed: props.subscribed,
                occupation: props.occupation,
                disableButton: false,
                classButton: props.name !== "" && props.occupation !== "" && props.email !== "" && props.date !== "" && props.phone !== "" ? "btn-color-blue save" : "btn-color-gray"
            }
        });
    }, [props]);

    const handleInitDateValidation = (valueDate) => {
        let re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;
        if (valueDate.match(re) !== null) {
            if (valueDate > state.dateFin) {
                setState({
                    ...state,
                    dateDebut: valueDate,
                    messageDateDebut: "La date de début doit être inférieure à la date de fin!",
                    disableButton: true,
                    classButton: "btn-color-gray"
                });
            } else {
                setState({
                    ...state,
                    dateDebut: valueDate,
                    messageDateDebut: "Date correcte!",
                    disableButton: false,
                    classButton: state.name !== "" | state.email !== "" | state.dateDebut !== "" | state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray"
                });
            }
        } else {
            setState({ ...state, messageDateDebut: "Date incorrect!", disableButton: true, classButton: "btn-color-gray" });
        }
    }

    const handleEndDateValidation = (valueDate) => {
        let re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;
        if (valueDate.match(re) !== null) {
            if (valueDate < state.dateDebut) {
                setState({ ...state, messageDateFin: "La date de fin doit être supérieur à la date de début!", disableButton: true, classButton: "btn-color-gray" });
            } else {
                setState({ ...state, dateFin: valueDate, messageDateFin: "Date correcte!", messageDateDebut: "Date correcte!", disableButton: false, classButton: state.name !== "" | state.email !== "" | state.dateDebut !== "" | state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray" });
            }
        } else {
            setState({ ...state, messageDateFin: "Date incorrect!", disableButton: true, classButton: "btn-color-gray" });
        }
    }

    const handleNameValidation = (valueNom) => {
        let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
        if (valueNom.match(re) !== null) {
            setState({ ...state, name: valueNom, messageName: "Nom correct!", disableButton: false, classButton: state.name !== "" | state.email !== "" | state.dateDebut !== "" | state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray" });
        } else {
            setState({ ...state, messageName: "Nom incorrect!", disableButton: true, classButton: "btn-color-gray" });
        }
    }

    const handleEmailValidation = (valueEmail) => {
        let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (valueEmail.match(re) !== null) {
            setState({ ...state, email: valueEmail, messageEmail: "Email correct!", disableButton: false, classButton: state.name !== "" | state.email !== "" | state.dateDebut !== "" | state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray" });
        } else {
            setState({ ...state, messageEmail: "Email incorrect!", disableButton: true, classButton: "btn-color-gray" });
        }
    }

    const handlePhoneValidation = (valuePhone) => {
        let re = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/u;
        if (valuePhone.match(re) !== null) {
            setState({ ...state, phone: valuePhone, messagePhone: "Téléphone correct!", disableButton: false, classButton: state.name !== "" | state.email !== "" | state.dateDebut !== "" | state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray" });
        } else {
            setState({ ...state, messagePhone: "Téléphone incorrect!", disableButton: true, classButton: "btn-color-gray" });
        }
    }

    const handleOccupationValidation = (valueOccupation) => {
        let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
        if (valueOccupation !== "") {
            if (valueOccupation.match(re) !== null) {
                setState({ ...state, occupation: valueOccupation, messageOccupation: "Fonction correcte!", disableButton: false, classButton: state.name !== "" && state.email !== "" && state.dateDebut !== "" && state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray" });
            } else {
                setState({ ...state, messageOccupation: "Fonction incorrect!", disableButton: true, classButton: "btn-color-gray" });
            }
        } else {
            setState({ ...state, occupation: valueOccupation, messageOccupation: "Fonction correcte!", disableButton: false, cassButton: state.name !== "" && state.email !== "" && state.dateDebut !== "" && state.dateFin !== "" ? "btn-color-blue" : "btn-color-gray" });
        }
    }

    const handleSubmitUserUpdate = () => {
        updateUser(state)
            .then(res => {
                if (res.status === 200) {
                    sessionStorage.setItem('messageUpdateUser', "Votre enregistrement a bien été effectué !")
                    setTimeout(window.location.reload(), 3000)
                } else {
                    sessionStorage.setItem('messageUpdateUser', "N'oubliez pas que le rôle de l'utilisateur doit être membre ou administrateur pour sa mise à jour")
                    setTimeout(window.location.reload(), 3000)
                }
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                );
                setState({
                    ...state,
                    messageEmail: "",
                    messageName: "",
                    messageOccupation: "",
                    messagePhone: "",
                    messageDateDebut: "",
                    messageDateFin: "",
                    messageGenre: ""
                })
            }).catch(error => {
                setState({ ...state, errorMessage: true, message: "Opps une erreur s'est produite, veuillez réessayer!" })
            })
    }


    return (
        <>
            {state == null && state === {} && <div></div>}
            {state && state != null && state !== {} &&
                <div className="modal fade" id="editModalUser" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content adjust-size-modal">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Modifier l'utilisateur</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row-add-user">
                                            <div className="input-add-user radio-button-user width-user">
                                                <span className="modal-lable">Civilité </span>
                                                <input type="radio"
                                                    checked={state.gender === 0 ? true : false}
                                                    value="0"
                                                    onChange={e => {
                                                        setState({ ...state, gender: 0 })
                                                    }} />Monsieur
                                                <input type="radio"
                                                    checked={state.gender === 1 ? true : false}
                                                    value="1"
                                                    onChange={e => {
                                                        setState({ ...state, gender: 1 })
                                                    }} />Madame
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" id="switchSubscription"
                                                checked={state?.subscribed || false}
                                                onChange={() => {
                                                    setState({ ...state, subscribed: !state.subscribed })
                                                }} />
                                            <label className="form-check-label" htmlFor="switchSubscription">Default switch checkbox input</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-add-user">
                                    <div className="input-add-user">
                                        <span className="input-add-user-date">Date Début* </span>
                                        <input value={state.dateDebut} type="date" style={{ border: "none" }}
                                            onChange={e => handleInitDateValidation(e.target.value)} />
                                    </div>
                                    <div className="container-validation-sm" style={{ width: "100%" }}>
                                        <div className={state.messageDateDebut !== "Date incorrect!" && state.messageDateDebut !== "La date de début doit être inférieure à la date de fin!" ? "correct" : "error"}>{state.messageDateDebut}</div>
                                    </div>
                                    <div className="input-add-user">
                                        <span className="input-add-user-date">Date Fin* </span><input value={state.dateFin} disabled={state.dateDebut !== "" ? false : true} type="date" style={{ border: "none" }}
                                            onChange={e => handleEndDateValidation(e.target.value)} />
                                    </div>
                                    <div className="container-validation-sm" style={{ width: "100%" }}>
                                        <div className={state.messageDateFin !== "Date incorrect!" && state.messageDateFin !== "La date de fin doit être supérieur à la date de début!" ? "correct" : "error"}>{state.messageDateFin}</div>
                                    </div>
                                </div>
                                <div className="container-validation-lg">
                                    <div className={state.messageDateDebut !== "Date incorrect!" && state.messageDateDebut !== "La date de début doit être inférieure à la date de fin!" ? "correct" : "error"}>{state.messageDateDebut}</div>
                                    <div className={state.messageDateFin !== "Date incorrect!" && state.messageDateFin !== "La date de fin doit être supérieur à la date de début!" ? "correct" : "error"}>{state.messageDateFin}</div>
                                </div>
                                <div className="row-add-user">
                                    <div className="input-add-user nom-prenom"><span className="modal-lable">Nom Prénom* </span><input type="text" defaultValue={state.name} style={{ border: "none" }}
                                        onChange={e => handleNameValidation(e.target.value)} />
                                    </div>
                                    <div className="container-validation-sm">
                                        <div className={state.messageName !== "Nom incorrect!" ? "correct" : "error"}>{state.messageName}</div>
                                    </div>
                                    <div className="input-add-user"><span className="modal-lable">Email* </span><input type="email" defaultValue={state.email} style={{ border: "none" }}
                                        onChange={e => handleEmailValidation(e.target.value)} /></div>
                                    <div className="container-validation-sm">
                                        <div className={state.messageEmail !== "Email incorrect!" ? "correct" : "error"}>{state.messageEmail}</div>
                                    </div>
                                </div>
                                <div className="container-validation-lg">
                                    <div className={state.messageName !== "Nom incorrect!" ? "correct" : "error"}>{state.messageName}</div>
                                    <div className={state.messageEmail !== "Email incorrect!" ? "correct" : "error"}>{state.messageEmail}</div>
                                </div>
                                <div className="row-add-user">
                                    <div className="input-add-user"><span className="modal-lable">Téléphone* </span><input type="text" defaultValue={state.phone} style={{ border: "none" }}
                                        onChange={e => handlePhoneValidation(e.target.value)} /></div>
                                    <div className="container-validation-sm">
                                        <div className={state.messagePhone !== "Téléphone incorrect!" ? "correct" : "error"}>{state.messagePhone}</div>
                                    </div>
                                    <div className="input-add-user input-occupation-create-user"><span className="modal-lable">Fonction </span><input type="text" defaultValue={state.occupation} style={{ border: "none" }}
                                        onChange={e => handleOccupationValidation(e.target.value)} /></div>
                                    <div className="container-validation-sm">
                                        <div className={state.messageOccupation !== "Fonction incorrect!" ? "correct" : "error"}>{state.messageOccupation}</div>
                                    </div>
                                </div>
                                <div className="container-validation-lg">
                                    <div className={state.messagePhone !== "Téléphone incorrect!" ? "correct" : "error"}>{state.messagePhone}</div>
                                    <div className={state.messageOccupation !== "Fonction incorrect!" ? "correct" : "error"}>{state.messageOccupation}</div>
                                </div>
                                <div className="container-buttons">
                                    <button type="button" className="close close-modal-add-user" data-dismiss="modal" aria-label="Close">
                                        <img src="../../icons/close.png" alt="ANNULER" />
                                    </button>
                                    <button type="button"
                                        className={state.classButton !== "btn-color-gray" | state.phone !== "" ? "btn-color-blue" : "btn-color-gray"}
                                        disabled={state.disableButton}
                                        onClick={() => handleSubmitUserUpdate()}>
                                        <img src="../../icons/save.png" alt="SAUVEGARDER" width="32px" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default EditDataUser