import React, { Component } from 'react';
import API from '../../axios';
import ReactPaginate from 'react-paginate';
import ViewDataUpdates from './modals/ViewDataUpdates';
import CreateDataUpdates from './modals/CreateDataUpdates';
import EditDataUpdates from './modals/EditDataUpdates';
import PdfDataUpdates from './modals/PdfDataUpdates';
import EditPdfUpdate from './modals/EditPdfUpdate';
import DeletePdfUpdate from './modals/DeletePdfUpdate';
import { MdMarkEmailRead, MdEmail } from 'react-icons/md';
import NotifyPost from './modals/NotifyPost';

export default class Items extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: null,
            correctMessage: false,
            errorMessage: false,
            offset: 0,
            perPage: 10,
            currentPage: 0
        }
        this.replaceModalItem = this.replaceModalItem.bind(this);
        this.generateHeaders = this.generateHeaders.bind(this);
        this.generateRows = this.generateRows.bind(this);
    }

    generateHeaders() {
        let cols = this.props.columns;
        return cols.map(function (colData) {
            return (
                <th className="column-datatable" key={colData.key}>
                    {colData.name}
                </th>
            );
        });
    }

    updateState(item, e) {
        API({
            method: 'put', url: "/update/updateState", data: {
                id: item.id,
                state: e.target.checked ? 1 : 0
            }
        }).then(res => {
            if (res.status === 200) {
                sessionStorage.setItem('updateRole', "Votre enregistrement a bien été effectué !")
                setTimeout(window.location.reload(), 3000)
            } else {
                sessionStorage.setItem('updateRole', "opps un problème est survenu, veuillez réessayer!")
                setTimeout(window.location.reload(), 3000)
            }
        }).catch(error => {
            this.setState({ errorMessage: true, message: "opps un problème est survenu, veuillez réessayer!" })
        })
    }

    deletePost(item) {
        API({
            method: 'delete', url: "/update/delete", data: {
                id: item.id
            }
        }).then(res => {
            if (res.status === 200) {
                sessionStorage.setItem('messageDelete', "Votre enregistrement a bien été effectué !")
                setTimeout(window.location.reload(), 3000)
            } else {
                sessionStorage.setItem('messageDelete', "opps un problème est survenu, veuillez réessayer")
                setTimeout(window.location.reload(), 3000)
            }
        }).catch(error => {
            this.setState({ errorMessage: true, message: "Opps une erreur s'est produite, veuillez réessayer!" })
        })
    }

    generateRows() {
        let dataTemp = this.props.data.slice(this.state.offset, this.state.offset + this.state.perPage);
        let cols = this.props.columns;  // [{key, label}]
        return dataTemp.map((item, i) => {
            var cells = cols.map(function (colData) {
                return (
                    <td key={colData.key}>
                        {Object.values(item)[colData.key]}
                    </td>);
            });
            return (
                <tr key={i} className="container-row-table">
                    {cells}
                    <td>
                        <a href="#!" className="btn-view-database" data-toggle="modal" data-target="#addPdfModalUpdate" onClick={() => this.setState({ id: item.id })}><img src="../../icons/MORE.png" alt="eye" width="24px" /></a>
                        <a href={"/pdfUpdateAdminDashboard/" + item.id} className="btn-view-database"><img src="../../icons/eye-3.png" alt="eye" width="32px" /></a>
                        <a href="#!" className="btn-view-database" data-toggle="modal" data-target="#editPdfModalUpdate" onClick={() => this.setState({ id: item.id })}><img src="../../icons/edit.png" alt="edit" width="24px" /></a>
                        <a href="#!" className="btn-view-database" data-toggle="modal" data-target="#deletePdfModalUpdate" onClick={() => this.setState({ id: item.id })}><img src="../../icons/trash.png" alt="delete" width="24px" /></a>
                    </td>
                    <td>
                        <label className="switch">
                            <input type="checkbox" defaultChecked={item.state !== 0 ? "checked" : ""} onChange={e => this.updateState(item, e)} defaultValue={item.state} />
                            <span className="slider round"></span>
                        </label>
                    </td>
                    <td className="container-updates-actions">
                        <a href="#!" data-toggle="modal" data-target="#viewModalUpdate" onClick={() => this.setState({ id: item.id })}><img src="../../icons/eye-3.png" alt="eye" width="40px" /></a>
                        <a href="#!" data-toggle="modal" data-target="#editModalUpdate" onClick={() => this.setState({ id: item.id })}><img src="../../icons/edit.png" alt="edit" width="24px" /></a>
                        {item.state === 0 &&
                            <a href="#!" className="little-padding"><MdEmail className='def-icon-gray' /></a>
                        }
                        {item.state === 1 && item.notified === 'Y' &&
                            <a href="#!" className="little-padding"><MdMarkEmailRead className='def-icon-gray' /></a>
                        }
                        {item.state === 1 && item.notified === 'N' &&
                            <a href="#!" className="little-padding" data-toggle="modal" data-target="#notifyPostModal" onClick={() => this.setState({ id: item.id })}>
                                <MdEmail className='def-icon-blue' />
                            </a>
                        }
                        <a href="#!" onClick={() => this.deletePost(item)}><img src="../../icons/trash.png" alt="trash" width="24px" /></a>
                    </td>
                </tr>
            )

        });
    }

    replaceModalItem() {
        let data = this.props.data;
        let itemTemp = "";

        data.forEach((item) => {
            if (item.id === this.state.id) {
                itemTemp = item;
            }
        })
        return itemTemp;
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
    };

    render() {
        let data = this.generateRows();
        let item = this.replaceModalItem();
        let header = this.generateHeaders();
        return (
            <div className="row-table row-table-updates">
                <table className="table-users table-updates">
                    <thead>
                        <tr>
                            {header}
                            <th className="column-datatable">PDF</th>
                            <th className="column-datatable">PUBLIÉ</th>
                            <th className="column-datatable">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data}
                    </tbody>
                </table>
                <div className="container-pagination">
                    <a href="#!" data-toggle="modal" data-target="#addModalUpdate"><img src="../../icons/MORE.png" alt="trash" width="32px" /></a>
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(this.props.data.length / this.state.perPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick.bind(this)}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div>
                <ViewDataUpdates
                    id={item.id}
                    image={item.route}
                    structureHtml={item.structureHtml}
                />
                <CreateDataUpdates />
                <EditDataUpdates
                    id={item.id}
                    image={item.route}
                    structureHtml={item.structureHtml}
                />
                <PdfDataUpdates
                    id={item.id}
                />
                <EditPdfUpdate
                    id={item.id}
                />
                <DeletePdfUpdate
                    id={item.id}
                />
                <NotifyPost
                    id={item.id}
                />
            </div>
        )
    }
}
