import React, { Component } from 'react'

export default class ViewDataNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            presentation: '',
            type:''
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            type: nextProps.type,
            presentation: nextProps.presentation
        });
    }
    render() {
        return (
            <div className="modal fade" id="viewModalNotification" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-dialog-centered">
                        <div className="modal-header" style={{width: "100%"}}>
                            <h5 className="modal-title" id="exampleModalLabel">Prévisualiser la notification</h5>
                            
                        </div>
                        <div className="modal-body container-body-modal">
                            <div className="container-notification">
                                <div className="container-presentation-notification">{this.state.presentation}</div>
                                <div className="container-button-img-notification"><img src="../../icons/service-3b-bn.png" alt="notification"/><button disabled>{this.state.type === "discover"?"Découvrir":"En savoir plus"}</button></div>
                            </div>
                            <div className="view-notification">
                                <button type="button" className="close close-modal-add-user" data-dismiss="modal" aria-label="Close">
                                    <img src="../../icons/close.png" alt="ANNULER"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
