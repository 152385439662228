import React, { Component } from 'react'
import API from '../axios';
import sha256 from 'sha256';

export default class ModalResetPassword extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            urlImgEyePassword: "../../icons/eye-2.png",
            typeInput: "password",
            showModalMember: false,
            email: "",
            password: "",
            passwordConfirmation: "",
            messageEmail: null,
            messagePassword: null,
            messagePasswordConfirmation: null,
            disableButton:true,
            classButton:"btn-color-gray btn-reset-password",
            errorPassword:false,
            correctPassword: false,
            sentence: "sentence-pass"
        }
    }

    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    connection = e => {
        API({
            method: 'post', url: '/user/requestEmailPassword?email='+this.state.email,
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            this.setState({correctPassword:true});
            this.setState({errorPassword:false});
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            this.setState({messageEmail:"", sentence:"sentence-pass-hidden"})
        }).catch((error) => {
            this.setState({correctPassword:false});
            this.setState({errorPassword:true});
        });
    }

    render() {

        if(!this.props.show){
            return null;
        }

        return (
            <div className="container-modal">
                <div className="flex-container-modal">
                    <div className="close-modal">
                        <img src="../../icons/close.png" className="close-icon" onClick={e => {this.onClose(e)}} alt="close" width="32"/>
                    </div>
                    <div className="container-form-login">
                        <div className="form-login">
                            <div className="container-title-services">
                                <h3>MOT DE PASSE OUBLIÉ</h3>
                                <span className="line-horizontal-center"></span> 
                            </div>
                            <div className={this.state.sentence}>
                                Pour modifier votre mot de passe, merci de renseigner votre email.
                            </div>
                            <div style={this.state.correctPassword?{color:"green", display:"block", padding:"5px 15px", textAlign:"left"}:{display:"none"}}>Un email vous a bien été transmis. Merci de vérifier votre messagerie pour suivre la procédure de changement de mot de passe.</div>
                            <div style={this.state.errorPassword?{color:"red", display:"block"}:{display:"none"}}>L'e-mail n'existe pas dans nos archives</div>
                            <div className="container-input">
                                <img src="../../icons/mail2.png" alt="email" width="48"/>
                                <input type="text" placeholder="Email" onChange={e => {
                                    let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                                    let valueEmail = e.target.value;
                                    if(valueEmail.match(re) !== null){
                                        this.setState({email: e.target.value, messageEmail: "Email correct!", disableButton:false, classButton:this.state.email !== ""?"btn-color-blue btn-reset-password":"btn-color-gray btn-reset-password"});
                                    }else{
                                        this.setState({messageEmail: "Email incorrect!", disableButton:true, classButton:"btn-color-gray btn-reset-password"});
                                    }
                                }}/>
                            </div>
                            <div className={this.state.messageEmail !== "Email incorrect!"?"correct":"error"}>{this.state.messageEmail}</div>
                            <button className={this.state.classButton} disabled={this.state.disableButton} onClick={(e) => {this.connection(e);}} >ENVOYER</button>
                        </div>
                    </div>    
                </div>
            </div>
        )
    }
}
