import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import ModalLogin from './modal/ModalLogin'
import ModalCreateMember from './modal/ModalCreateMember'
import ModalPassword from './modal/ModalPassword'

export default class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: "english",
            hideMenu: true,
            email:"",
            showModalLogin: false,
            showModalMember: false,
            showModalPassword: false,
            search: false,
            redirect:false,
            nameButtonDashboard: "",
            urlButtonDashboard: ""
                
        }
        
    }

    componentDidMount() {
        if(localStorage.getItem("user")){
            this.setState({search:true})
        }else{
            this.setState({search:false})
        }
        let urlLocation = window.location.href;
        if(urlLocation.indexOf("dashboard") !== -1){
            this.setState({nameButtonDashboard:"Retour à l’accueil", urlButtonDashboard: "/"})
        }else{
            this.setState({nameButtonDashboard:"Tableau de bord", urlButtonDashboard: "/dashboard"})
        }
        if(urlLocation.indexOf("#resetPassword") !== -1){
            const email = urlLocation.substring(urlLocation.indexOf("=")+1,urlLocation.length);
            this.setState({showModalPassword:true, email:email});
        }
    }

    clickButtonMenu() {
        this.setState({
            hideMenu: !this.state.hideMenu           
        })
    }

    showModalLogin = e =>{
        this.setState({
            showModalLogin: !this.state.showModalLogin
        });
    }

    showModalMember = e =>{
        this.setState({
            showModalMember: !this.state.showModalMember
        });
    }

    showModalPassword = e =>{
        this.setState({
            showModalPassword: !this.state.showModalPassword
        });
    }

    logoutUser(){
        localStorage.removeItem("user");
    }
    
    render() {
        let btn_class_img = this.state.hideMenu ? "container-sm-menu" : "visible-container-sm-menu";
        let icon_search = this.state.search ? "material-icons icon-search-2": "material-icons icon-search-1"
        let arrayUser = JSON.parse(localStorage.getItem("user"));
        return (
            <div className="container-header">
                {/* desktop view */}
                <div className="container-login">
                    <div className="container-flex-login">
                        <div className="container-logo">
                            <NavLink to="/"><img className="logo-main" src="../../icons/logo-bn.png" alt="Collectif Pharma" width="280"/></NavLink> 
                        </div>
                        <div className="container-acount">
                            <div className="create-count">
                                <img src="../../icons/user.png" alt="create user" width="30" style={{marginRight:"7px"}}/>
                                {!localStorage.getItem("user")?<a href="#!" onClick={e => {this.showModalMember(e)}}><span>DEVENIR MEMBRE?</span></a>:arrayUser.name}
                            </div>
                            <div className="login">
                                <img src="../../icons/key.png" alt="login" width="30"/>
                                {!localStorage.getItem("user")?<a href="#!" onClick={e => {this.showModalLogin(e)}}><span>ACCÈS MEMBRE</span></a>:<a href="/" onClick={() => this.logoutUser()}><span>DÉCONNECTER</span></a>}
                                {localStorage.getItem("user")?arrayUser.role !== 1?<div></div>:<NavLink to={this.state.urlButtonDashboard} ><span>{this.state.nameButtonDashboard}</span></NavLink>:<div></div>}
                            </div>
                        </div>
                    </div>    
                </div>
                <div className="container-main-manu">
                    <div className="container-items-menu">
                        <NavLink className="item-menu" activeClassName="current" exact to="/">ACCUEIL</NavLink>
                        {localStorage.getItem("user")?<NavLink className="item-menu" activeClassName="current" to="/services">NOS OFFRES ET SERVICES</NavLink>:null}
                        {localStorage.getItem("user")?<a className="item-menu" href={"/#actualites"}>ACTUALITÉS </a> : null}
                        <NavLink className="item-menu" activeClassName="current" to="/contact">CONTACTEZ-NOUS</NavLink>
                        <div className="container-search-bar"><input type="text"  placeholder="Rechercher..." onChange={this.props.clickHandler}/><span className="material-icons">search</span></div>
                    </div>   
                </div>
                {/* mobile view */}
                <div className="sm-menu-main">
                    <div className="container-search-icon-menu">
                        <div className="material-icons icon-menu-sm" onClick={this.clickButtonMenu.bind(this)}>dehaze</div>
                        <div className="container-search-sm "><input type="text" className="search-sm" placeholder="Rechercher..." onChange={this.props.clickHandler}/><span className={icon_search}>search</span></div>
                    </div>    
                </div>
                <div id='test-id' className={btn_class_img}>
                    <div className="container-items-menu">
                        <NavLink className="item-menu" activeClassName="current" exact to="/">ACCUEIL</NavLink>
                        {localStorage.getItem("user")?<NavLink className="item-menu" activeClassName="current" to="/services">NOS OFFRES ET SERVICES</NavLink>:null}
                        {localStorage.getItem("user")?<a className="item-menu" href={"/#actualites"}>ACTUALITÉS </a> : null}
                        <NavLink className="item-menu" activeClassName="current" to="/contact">CONTACTEZ-NOUS</NavLink>
                    </div>  
                </div>
                <ModalLogin onClose={this.showModalLogin} show={this.state.showModalLogin}/>
                <ModalCreateMember onClose={this.showModalMember} show={this.state.showModalMember}/>
                <ModalPassword onClose={this.showModalPassword} show={this.state.showModalPassword} email={this.state.email}/>
            </div>
        )
    }
}
