import React from 'react'
import API from '../../../axios';


const NotifyPost = (props) => {

    const notifyPost = () => {
        API({
            method: 'post',
            url: "/update/notify",
            data: {
                id: props.id
            }
        }).then(res => {
            window.location.reload()
        }).catch(error => {
            console.log(error.message)
        })
    }

    return (
        <div className="modal fade" id="notifyPostModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Voulez-vous vraiment notifier cette newsletter ?</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className='row lateral-medium-padding justify-content-end'>
                            <button className="btn btn-primary" onClick={notifyPost}>Confirmer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotifyPost;