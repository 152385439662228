import React, { Component } from 'react'
import API from '../../../axios';

export default class ChangePdf extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: 0,
            file: null,
            nameFile: "",
            pdfs: [],
            classButton:"btn-color-gray"
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
    }

    async onSubmit(e) {
        e.preventDefault()
        await this.uploadFile(this.state.file);

    }

    onChange(e) {
        this.setState({ file: e.target.files[0], nameFile: e.target.files[0].name, disableButton: false, classButton: "btn-color-blue save" })
    }

    async uploadFile(file) {
        const formData = new FormData();
        formData.append('id', this.props.idPdf)
        formData.append('nameFile', this.state.nameFile)
        formData.append('file', file)

        return await API.post("/service/editPdf", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.status === 200) {
                sessionStorage.setItem('messageCreation', "Votre enregistrement a bien été effectué !")
                setTimeout(window.location.reload(), 3000)
            } else {
                sessionStorage.setItem('messageCreation', "opps un problème est survenu, veuillez réessayer")
                setTimeout(window.location.reload(), 3000)
            }
        });
    }
    render() {
        return (
            <form onSubmit={this.onSubmit} style={{ width: "100%" }} className="container-form-update-pdf">
                <div className="row-add-user">
                    <input type="file" onChange={this.onChange} />
                </div>
                <div className="container-buttons">
                    <button type="submit" className={this.state.classButton} disabled={this.state.disableButton} >
                        <img src="../../icons/save.png" alt="SAUVEGARDER" width="32px" />
                    </button>
                </div>
            </form>
        )
    }
}
