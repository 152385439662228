import React, { Component } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import API from '../../../axios';

export default class EditDataUpdates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            data: [],
            dataInfo:"<p></p>",
            title: "",
            description:"",
            structureHtml: "",
            classButton:"btn-color-gray",
            route:"",
            image:""
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.structureHtml !== undefined){
            let structure = nextProps.structureHtml;
            let finalStructure = nextProps.image !== ""?structure.replace('<figure class="image"><img>', "<figure class='image'><img class='container-image-update' src="+process.env.REACT_APP_S3_HOST+nextProps.image+">"):structure;
            this.setState({
                id: nextProps.id,
                structureHtml: finalStructure,
                image: nextProps.image !== null?nextProps.image:""
            });
        }
    }

    componentDidMount(){
        this.setState({classButton:"btn-color-gray", disableButton:true})
    }

    render() {
        return (
            <div className="modal fade" id="editModalUpdate" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content adjust-size-modal">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifier l’actualité</h5>                            
                        </div>
                        <div className="modal-body">
                            <div className="row-add-user">
                                <CKEditor
                                    editor={ 
                                        ClassicEditor
                                    }
                                    data={this.state.structureHtml} 
                                    config={{
                                        ckfinder: {
                                            // Upload the images to the server using the CKFinder QuickUpload command.
                                            uploadUrl: process.env.REACT_APP_API_ENPOINT_MAIN+'/uploader'
                                        }
                                    }} 
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        if(data !== null){
                                            this.setState({structureHtml:data});
                                            this.setState({data:data, disableButton:false});
                                             
                                                let presentation = "";
                                                if(data.indexOf("<h2>") !== -1){
                                                    this.setState({title: data.substring(data.indexOf("<h2>")+4, data.indexOf("</h2>"))});
                                                    presentation = data.substring(data.indexOf('</h2>'), data.length);
                                                }else{
                                                    if(data.indexOf("<h3>") !== -1){
                                                        this.setState({title: data.substring(data.indexOf('<h3>')+4, data.indexOf('</h3>'))});
                                                        presentation = data.substring(data.indexOf('</h3>'), data.length);
                                                    }else{
                                                        if(data.indexOf("<h4>") !== -1){
                                                            this.setState({title: data.substring(data.indexOf("<h4>")+4,data.indexOf("</h4>"))});
                                                            presentation = data.substring(data.indexOf('</h4>'), data.length);  
                                                        }else{
                                                            this.setState({title: ""});
                                                            presentation = data;  
                                                        }
                                                    }
                                                }
                                            let dataCorrection = presentation.replace(/&nbsp;/gi, '');
                                            let arrayP = dataCorrection.replace(/<(.|\n)*?>/gi,'');
                                            this.setState({description: arrayP.substring(0,150), classButton:this.state.data !== "" && this.state.url !== "" ?"btn-color-blue save":"btn-color-gray"});
                                        }
                                    } }
                                />
                            </div>
                            <div className="container-buttons">
                                <button type="button" className="close close-modal-add-user" data-dismiss="modal" aria-label="Close">
                                    <img src="../../icons/close.png" alt="ANNULER"/>
                                </button>
                                <button type="button" className={this.state.classButton} disabled={this.state.disableButton} onClick={e =>{
                                    let title = this.state.title;
                                    API({
                                        method: 'get', url:  "/routeImage"
                                    }).then(res => {
                                        if(res.status === 200 && res.data !== ""){
                                            API({
                                                method: 'put', url:  "/update/update", data: {
                                                    id: this.state.id,
                                                    title: title.replace(/&nbsp;/gi,''),
                                                    description: this.state.description,
                                                    url: this.state.url,
                                                    image: res.data,
                                                    structureHtml: this.state.structureHtml
                                                }
                                                }).then(res => {
                                                    if(res.status === 200){
                                                        sessionStorage.setItem('messageCreation',"Votre enregistrement a bien été effectué !")
                                                        setTimeout(window.location.reload(),3000)
                                                    }else{
                                                        sessionStorage.setItem('messageCreation',"opps un problème est survenu, veuillez réessayer")
                                                        setTimeout(window.location.reload(),3000)
                                                    } 
                                                    this.setState({dataInfo:""});
                                                    this.setState({messageUrl:""})
                                                }).catch(error => {
                                                    this.setState({errorMessage:true, message:"Opps une erreur s'est produite, veuillez réessayer!"})
                                            })
                                            API({
                                                method: 'get', url:  "/clearImage"
                                            }).then(res => {
                                                console.log(res);
                                            })
                                        }else{
                                            API({
                                                method: 'put', url:  "/update/update", data: {
                                                    id: this.state.id,
                                                    title: title.replace(/&nbsp;/gi,''),
                                                    description: this.state.description,
                                                    url: this.state.url,
                                                    image: this.state.route,
                                                    structureHtml: this.state.structureHtml
                                                }
                                                }).then(res => {
                                                    if(res.status === 200){
                                                        sessionStorage.setItem('messageCreation',"Votre enregistrement a bien été effectué !")
                                                        setTimeout(window.location.reload(),3000)
                                                    }else{
                                                        sessionStorage.setItem('messageCreation',"opps un problème est survenu, veuillez réessayer")
                                                        setTimeout(window.location.reload(),3000)
                                                    } 
                                                    this.setState({dataInfo:""});
                                                    this.setState({messageUrl:""})
                                                }).catch(error => {
                                                    this.setState({errorMessage:true, message:"Opps une erreur s'est produite, veuillez réessayer!"})
                                            })
                                            API({
                                                method: 'get', url:  "/clearImage"
                                            }).then(res => {
                                                console.log(res);
                                            })
                                        }
                                    })
                                }}>
                                    <img src="../../icons/save.png" alt="SAUVEGARDER" width="32px"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
