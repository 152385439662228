import React, { Component } from 'react'
import Carousel from 'react-elastic-carousel';
import { NavLink } from 'react-router-dom';
import API from './axios';

export default class CarouselUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        let arrayItems = [];
        let items = this.props.data;
        for (let d in items) {
            if (items[d].state === 1) {
                arrayItems.push(items[d]);
            }
        }
        return (
            <div className="carousel">
                {arrayItems.length > 0 ?
                    <Carousel pagination={false} className="carousel-items">
                        {arrayItems.map((item, i) => {
                            let urlVideo = "";
                            let description = item.structureHtml;
                            if (description.indexOf("oembed") !== -1) {
                                urlVideo = description.substring(description.indexOf("<oembed url=") + 13, description.indexOf("></oembed>") - 1)
                            }

                            let structure = item.structureHtml;
                            let replaceH2Structure = "";
                            let h3Structure = ""
                            let title = "";
                            if (structure.indexOf("<h2>") !== -1) {
                                replaceH2Structure = structure.substring(structure.indexOf("<h2>") + 4, structure.indexOf("</h2>"));
                                title = replaceH2Structure;
                            } else {
                                if (structure.indexOf("<h3>") !== -1) {
                                    h3Structure = structure.substring(structure.indexOf('<h3>')+4, structure.indexOf('</h3>'))
                                    title = h3Structure;
                                } else {
                                    title = structure.substring(structure.indexOf("<h4>")+4, structure.indexOf("</h4>") + 5);
                                }
                            }
                            let date = new Date(item.date);
                            let formatYear = date.toLocaleDateString('fr-FR', { year: 'numeric' });
                            let formatMonth = date.toLocaleDateString('fr-FR', { month: 'long' });
                            let formatDay = date.toLocaleDateString('fr-FR', { day: '2-digit' });

                            return (
                                <div className="container-item-newsletter test" key={item.id}>
                                    <div className="col-carousel">
                                        <div>
                                        <button style={{border:"none", background:"transparent"}} onClick={e => {
                                                console.log("ingreso")
                                                API({
                                                    method: 'put', url: '/update/view', data: {
                                                        id: item.id
                                                    }
                                                }).then(res => {
                                                    if (res.status === 200) {
                                                        sessionStorage.setItem('updateRole', "Votre enregistrement a bien été effectué !")

                                                    } else {
                                                        sessionStorage.setItem('updateRole', "opps un problème est survenu, veuillez réessayer!")
                                                    }
                                                }).catch((error) => {
                                                    console.log(error)
                                                });
                                            }}><NavLink to={"/update/" + item.id} style={{ textDecoration: "none" }}><img className="image-post" src={process.env.REACT_APP_S3_HOST + item.image} alt="post" /></NavLink></button>
                                        </div>
                                        <div className="container-date-publication">
                                            <div className="row-date-publication">
                                                <span>{formatDay}</span>
                                                <span>{formatMonth}</span>
                                                <span>{formatYear}</span>
                                                <div className="container-view-likes">
                                                    <div className="container-view">
                                                        <img src="../icons/eye-3.png" alt="view" width="36" />
                                                        <span>{item.view}</span>
                                                    </div>
                                                    <div className="container-likes">
                                                        <img src="../icons/like.png" alt="like" onClick={e => {
                                                            API({
                                                                method: 'put', url: '/update/likes', data: {
                                                                    id: item.id
                                                                }
                                                            }).then(res => {
                                                                if (res.status === 200) {
                                                                    sessionStorage.setItem('updateRole', "Votre enregistrement a bien été effectué !")
                                                                    setTimeout(window.location.reload(), 3000)
                                                                } else {
                                                                    sessionStorage.setItem('updateRole', "opps un problème est survenu, veuillez réessayer!")
                                                                    setTimeout(window.location.reload(), 3000)
                                                                }
                                                            }).catch((error) => {
                                                                console.log(error)
                                                            });
                                                        }} />
                                                        <span>{item.likes}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="title-carousel-update">
                                            <button onClick={e => {
                                                console.log("ingreso")
                                                API({
                                                    method: 'put', url: '/update/view', data: {
                                                        id: item.id
                                                    }
                                                }).then(res => {
                                                    if (res.status === 200) {
                                                        sessionStorage.setItem('updateRole', "Votre enregistrement a bien été effectué !")

                                                    } else {
                                                        sessionStorage.setItem('updateRole', "opps un problème est survenu, veuillez réessayer!")
                                                    }
                                                }).catch((error) => {
                                                    console.log(error)
                                                });
                                            }}><NavLink to={"/update/" + item.id} style={{ textDecoration: "none" }}>{title}</NavLink></button>
                                        </div>
                                        <div className="button-video-view">
                                           {urlVideo !== ""?<a href={urlVideo !== ""?urlVideo:"#"} style={{ textDecoration: "none" }} rel="noopener noreferrer" target="_blank">Voir la vidéo</a>:<div></div>} 
                                        </div>
                                    </div>
                                </div>
                            )

                        }
                        )}
                    </Carousel>
                    : <div>Aucun scoop pour le moment mais cela ne devrait pas tarder !</div>}
            </div>
        )
    }
}
