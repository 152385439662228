import React, { Component } from 'react'
import Header from './Header';
import Footer from './Footer';
import API from './axios';

export default class Contact extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            name: "",
            email: "",
            phone: "",
            occupation: "",
            message: "",
            newsletter: false,
            policy: false,
            messageName: null,
            messageEmail: null,
            messageOccupation:null,
            messagePhone:null,
            messageComentary: null,
            messagePolicy: null,
            disableButton:true,
            classButton:"btn-color-gray",
            errorContact:false,
            correctContact:false
        }
    }

    connection = e => {
        let newletter = this.state.newsletter;
        API({
            method: 'post', url: '/user/contact', data: {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                occupation: this.state.occupation,
                message: this.state.message,
                newsletter: newletter !== false? 1:0
            },
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            this.setState({correctContact:true});
            this.setState({errorContact:false});
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            Array.from(document.querySelectorAll("textarea")).forEach(
                textarea => (textarea.value = "")
            );
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.checked = "")
            );
            this.setState({
             messageEmail:"",
             messageName:"",
             messageOccupation:"",
             messagePhone:"",
             messageComentary:"",
             messagePolicy:""
            })
        }).catch((error) => {
            this.setState({correctContact:false});
            this.setState({errorContact:true});
        });
    }
    
    render() {
        return (
            <div>
                <Header/>
                <div className="container-contact">
                    <div className="row-contact">
                        <div className="container-form">
                            <div className="container-title-services">
                                <h3>CONTACTEZ - NOUS</h3>       
                                <span className="line-horizontal-center"></span>         
                            </div>
                            <p>Si vous souhaitez obtenir plus d’informations sur Collectif Pharma, laissez-nous votre message !</p> 
                            <div style={this.state.correctContact?{color:"green", display:"block", marginBottom:"30px"}:{display:"none"}}>Votre message a bien été envoyé !</div>
                            <div style={this.state.errorContact?{color:"red", display:"block", marginBottom:"30px"}:{display:"none"}}>Oups une erreur s'est produite, veuillez réessayer!</div>
                            <div className="container-input">
                                <img src="../../icons/user.png" alt="name" width="48"/>
                                <input type="text" placeholder="Nom Prénom*" onChange={e =>{
                                    let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                    let valueName = e.target.value;
                                    if(valueName.match(re) !== null){
                                        this.setState({name: e.target.value, messageName: "Nom correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== ""?"btn-color-blue":"btn-color-gray" && this.state.policy?"btn-color-blue":"btn-color-gray"});
                                        if(!this.state.policy){
                                            this.setState({messagePolicy: "Vous devez sélectionner les politiques de confidentialité!", disableButton:true, classButton:"btn-color-gray"});
                                        }else{
                                            this.setState({messagePolicy: "Correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== ""?"btn-color-blue":"btn-color-gray"});
                                        }
                                    }else{
                                        this.setState({messageName: "Nom incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                    }
                                }}/>
                            </div>
                            <div className={this.state.messageName !== "Nom incorrect!"?"correct":"error"}>{this.state.messageName}</div> 
                            <div className="container-input">
                                <img src="../../icons/mail2.png" alt="email" width="48"/>
                                <input type="text" placeholder="Email*" onChange={e => {
                                    let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                                    let valueEmail = e.target.value;
                                    if(valueEmail.match(re) !== null){
                                        this.setState({email: e.target.value, messageEmail: "Email correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== "" ?"btn-color-blue":"btn-color-gray" && this.state.policy?"btn-color-blue":"btn-color-gray"});
                                        if(!this.state.policy){
                                            this.setState({messagePolicy: "Vous devez sélectionner les politiques de confidentialité!", disableButton:true, classButton:"btn-color-gray"});
                                        }else{
                                            this.setState({messagePolicy: "Correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== ""?"btn-color-blue":"btn-color-gray"});
                                        }
                                    }else{
                                        this.setState({messageEmail: "Email incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                    }
                                }}/>
                            </div>
                            <div className={this.state.messageEmail !== "Email incorrect!"?"correct":"error"}>{this.state.messageEmail}</div>
                            <div className="container-phone-function">
                                <div className="container-input">
                                    <img src="../../icons/phone.png" alt="telephone" width="48"/>
                                    <input type="text" placeholder="Téléphone*" style={{marginRight:"5px"}} onChange={e => {
                                        let re = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/u;
                                        let valuePhone = e.target.value;
                                        if(valuePhone.match(re) !== null){
                                            this.setState({phone: e.target.value, messagePhone: "Téléphone correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== ""?"btn-color-blue":"btn-color-gray" && this.state.policy?"btn-color-blue":"btn-color-gray"});
                                            if(!this.state.policy){
                                                this.setState({messagePolicy: "Vous devez sélectionner les politiques de confidentialité!", disableButton:true, classButton:"btn-color-gray"});
                                            }else{
                                                this.setState({messagePolicy: "Correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== ""?"btn-color-blue":"btn-color-gray"});
                                            }
                                        }else{
                                            this.setState({messagePhone: "Téléphone incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                        }
                                    }}/>
                                </div>
                                <div className="container-validation-sm">
                                    <div className={this.state.messagePhone !== "Téléphone incorrect!"?"correct":"error"}>{this.state.messagePhone}</div>     
                                </div>
                                <div className="container-input">
                                    <img src="../../icons/fonction@2x.png" alt="trash" width="48px"/>
                                    <input type="text" placeholder="Fonction*" onChange={e => {
                                        let re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
                                        let valueOccupation = e.target.value;
                                        if(valueOccupation.match(re) !== null){
                                            this.setState({occupation: e.target.value, messageOccupation: "Fonction correcte!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== "" ?"btn-color-blue":"btn-color-gray" && this.state.policy?"btn-color-blue":"btn-color-gray"});
                                            if(!this.state.policy){
                                                this.setState({messagePolicy: "Vous devez sélectionner les politiques de confidentialité!", disableButton:true, classButton:"btn-color-gray"});
                                            }else{
                                                this.setState({messagePolicy: "Correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== ""?"btn-color-blue":"btn-color-gray"});
                                            }
                                        }else{
                                            this.setState({messageOccupation: "Fonction incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                        }
                                    }}/>
                                </div>
                                <div className="container-validation-sm">
                                    <div className={this.state.messageOccupation !== "Fonction incorrect!"?"correct":"error"}>{this.state.messageOccupation}</div> 
                                </div>
                            </div>
                            <div className="container-validation-lg">
                                <div className={this.state.messagePhone !== "Téléphone incorrect!"?"correct":"error"}>{this.state.messagePhone}</div>     
                                <div className={this.state.messageOccupation !== "Fonction incorrect!"?"correct":"error"}>{this.state.messageOccupation}</div> 
                            </div>
                            <div className="container-input">
                                <textarea placeholder="Comment pouvons-nous vous être utile?" rows="6" onChange={e => {
                                    let valueMessage = e.target.value;
                                    if(valueMessage !== ""){
                                        if(valueMessage.length > 500){
                                            this.setState({messageComentary: "Le message ne peut pas contenir plus de 500 caractères!", disableButton:true, classButton:"btn-color-gray"});
                                        }else{
                                            this.setState({message: valueMessage, messageComentary: "Correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== ""?"btn-color-blue":"btn-color-gray" && this.state.policy?"btn-color-blue":"btn-color-gray"});
                                            if(!this.state.policy){
                                                this.setState({messagePolicy: "Vous devez sélectionner les politiques de confidentialité!", disableButton:true, classButton:"btn-color-gray"});
                                            }else{
                                                this.setState({messagePolicy: "Correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== ""?"btn-color-blue":"btn-color-gray"});
                                            }
                                        }
                                    }else{
                                        this.setState({messageComentary: "Message incorrect!", disableButton:true, classButton:"btn-color-gray"});
                                    }
                                }}></textarea>
                            </div>
                            <div className={this.state.messageComentary !== "Message incorrect!" && this.state.messageComentary !== "Le message ne peut pas contenir plus de 500 caractères!"?"correct":"error"}>{this.state.messageComentary}</div>     
                            <div className="container-input-checkbox">
                                <input type="checkbox" id="newsletter" onChange={e => {
                                    this.setState({newsletter: e.target.checked});
                                }}/> 
                                <label htmlFor="newsletter"><span style={{marginLeft:"5px", fontWeight:"500"}}>Je souhaite m’inscrire à la Newsletter.</span></label>
                            </div>
                            <div className="container-input-checkbox">
                                <input type="checkbox" id="politique" onChange={e => {
                                    if(e.target.checked){
                                        this.setState({policy: e.target.checked, messagePolicy: "Correct!", disableButton:false, classButton:this.state.name !== "" && this.state.email !== "" &&  this.state.occupation !== "" &&  this.state.phone !== "" &&  this.state.message !== "" ?"btn-color-blue":"btn-color-gray" && this.state.policy?"btn-color-blue":"btn-color-gray"});
                                    }else{
                                        this.setState({messagePolicy: "Vous devez sélectionner les politiques de confidentialité!", disableButton:true, classButton:"btn-color-gray"});
                                    }
                                }}/> 
                                <label htmlFor="politique"> <span style={{marginLeft:"5px", fontWeight:"500"}}>J'accepte la <a href="/policy" style={{color:"#213564", textDecoration:"none", fontWeight:600}}>Politique de confidentialité</a> du site. * </span></label>
                            </div>
                            <div className={this.state.messagePolicy !== "Vous devez sélectionner les politiques de confidentialité!" ?"correct":"error"}>{this.state.messagePolicy}</div>     
                            <button className={this.state.classButton} disabled={this.state.disableButton} onClick={(e) => {this.connection(e);}} style={{marginBottom:"30px", marginTop:"30px"}}>ENVOYER</button>
                        </div>
                    </div>    
                </div>
                <Footer/>
            </div>
        )
    }
}
