import React, { Component } from 'react'
import API from '../../../axios';

export default class DeletePdfService extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: '',
            pdfs: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.id !== undefined){
            API({
                method: 'get', url: '/service/getPdfById?id=' + nextProps.id
            }).then(res => {
                this.setState({ pdfs: res.data.data })
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    render() {
        return (
            <div className="modal fade" id="deletePdfModalService" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete PDFs Service</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-pdf">
                                {
                                    this.state.pdfs !== []?this.state.pdfs.map((pdf) => {
                                        if(pdf.id !== undefined){
                                            return (
                                                <div className="container-list-pdf" key={pdf.id}>
                                                    <div>{pdf.name}</div>
                                                    <div><a href="#!" className="btn-view-database" onClick={() => {
                                                            API({
                                                                method: 'put', url:  "/service/updatePdf", data: {
                                                                id:pdf.id
                                                            }
                                                            }).then(res => {
                                                                if(res.status === 200){
                                                                    sessionStorage.setItem('updatePdf',"Votre enregistrement a bien été effectué !")
                                                                    setTimeout(window.location.reload(),3000)
                                                                }else{
                                                                    sessionStorage.setItem('updatePdf',"opps un problème est survenu, veuillez réessayer!")
                                                                    setTimeout(window.location.reload(),3000)
                                                                }                                
                                                            }).catch(error => {
                                                                this.setState({errorMessage:true, message:"opps un problème est survenu, veuillez réessayer!"})
                                                            })
                                                        }}><img src="../../icons/trash.png" alt="eye" width="32px"/></a>
                                                    </div>
                                                </div>      
                                            );
                                        }
                                        
                                    })
                                    :<div></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
