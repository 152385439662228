import React, { Component } from 'react';
import API from '../../../axios';

export default class ViewDataService extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            image: '',
            structureHtml: '',
            pdfs: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.structureHtml !== undefined){
            let structure = nextProps.structureHtml;
            let finalStructure = structure.replace('<figure class="image"><img>', "<figure class='image'><img src="+process.env.REACT_APP_S3_HOST+nextProps.image+">")
            this.setState({
                structureHtml: finalStructure
            });
            if(nextProps.id !== undefined){
                API({
                    method: 'get', url: '/service/getPdfById?id='+nextProps.id
                }).then(res => {
                    this.setState({pdfs:res.data.data})
                }).catch((error) => {
                    console.log(error)
                });
            }
            
        }
        
        
    }

    render() {
        return (
            <div className="modal fade" id="viewModalService" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-dialog-centered">
                        <div className="modal-header" style={{width: "100%"}}>
                            <h5 className="modal-title" id="exampleModalLabel">View Service</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-update-dashboard" dangerouslySetInnerHTML={{ __html: this.state.structureHtml }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
